<template>
  <div class="send">
    <template v-if="dataReady">
      <div v-if="needsTemplate" class="lang-toggle-container">
        <LangToggle v-model="lang" />
      </div>
      <div v-if="data.body" class="text">
        {{ $t(data.body) }}
      </div>
      <div class="form">
        <div v-if="data.id === 'sendWelcome'" class="box input-box">
          <InputWithLabel
            v-model="targetEmail"
            component="InputComp"
            :label="$t('client_mail_field')+'*'"
          />
        </div>
        <div class="box input-box">
          <InputWithLabel
            v-model="subject"
            component="InputComp"
            :label="$t('subject')+'*'"
          />
        </div>
        <div class="box input-box quill">
          <div class="label">{{ $t('body')+'*' }}</div>
          <vue-editor v-model="body" :editor-toolbar="customToolbar" />
        </div>
      </div>
    </template>
    <template v-else>
      <Loader />
    </template>
  </div>
</template>

<script>
import Loader from '@/components/Loader.vue'
import LangToggle from '@/components/LangToggle.vue'
import modalContentMixin from '@/components/modals/modalContentMixin'
import InputWithLabel from '@/components/InputWithLabel.vue'

const fullToolbar = [
  // [{ 'font': [] }],
  // [{ 'header': [false, 1, 2, 3, 4, 5, 6, ] }],
  // [{ 'size': ['small', false, 'large', 'huge'] }],
  ['bold', 'italic', 'underline', 'strike'],
  // [{'align': ''}, {'align': 'center'}, {'align': 'right'}, {'align': 'justify'}],
  // [{ 'header': 1 }, { 'header': 2 }],
  // ['blockquote', 'code-block'],
  [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
  // [{ 'script': 'sub'}, { 'script': 'super' }],
  [{ indent: '-1' }, { indent: '+1' }],
  [{ color: [] }, { background: [] }],
  // ['link', 'image', 'video', 'formula'],
  // [{ 'direction': 'rtl' }],
  ['clean'],
]

export default {
  name: 'SendArrivalNotification',
  components: {
    Loader,
    InputWithLabel,
    LangToggle,
  },
  mixins: [modalContentMixin],
  props: ['data'],
  data() {
    return {
      targetEmail: null,
      lang: this.data.customerLang
        ? this.data.customerLang
        : this.$store.state.user.userData.lanCode,
      body: '',
      subject: '',
      customToolbar: fullToolbar,
      rules: {
        targetEmail: [
          val =>
            this.isTargetEmailValid(val) || 'This field is required and format must be correct',
        ],
        subject: [val => this.isTextThere(val) || 'This field is required'],
        body: [val => this.isTextThere(val) || 'This field is required'],
      },
    }
  },
  computed: {
    needsTemplate() {
      return !['sendMkNotification', 'sendMassiveNotification'].includes(this.data.id)
    },
    contentValue() {
      return {
        imageName: this.data.imageName || null,
        targetEmail: this.targetEmail,
        messageLan: this.lang,
        messageModel: {
          ...this.langNotification,
          subject: this.subject,
          body: this.body,
        },
      }
    },
    showTargetEmailCheckIcon() {
      return this.isTargetEmailValid(this.targetEmail)
    },
    dataReady() {
      return this.needsTemplate ? this.$store.state.notification.loaded : true
    },
    langNotification() {
      return this.$store.getters['notification/getByLang'](this.lang)
    },
  },
  watch: {
    langNotification: {
      deep: true,
      handler() {
        this.subject =
          this.langNotification && this.langNotification.subject
            ? this.langNotification.subject
            : ''
        this.body =
          this.langNotification && this.langNotification.body ? this.langNotification.body : ''
      },
    },
  },
  methods: {
    isTextThere(val) {
      return (val || '').length > 0
    },
    isTargetEmailValid(val) {
      return (
        (val || '').length > 0 && val.indexOf('@') > 1 && val.indexOf('@') < val.lastIndexOf('.')
      )
    },
    validate(data) {
      if (
        data &&
        this.isTextThere(data.messageModel.subject) &&
        this.isTextThere(data.messageModel.body)
      ) {
        if (this.data.id !== 'sendWelcome') return true
        if (this.data.id === 'sendWelcome' && this.isTargetEmailValid(data.targetEmail)) return true
      }
      return false
    },
  },
}
</script>

<style scoped lang="stylus">
.text
  margin-bottom: 20px

.form
  display: flex
  flex-direction: column
  height: 100%

  .box
    position: relative

    &.quill
      display: flex
      flex: 1 1 0%
      flex-direction: column
      overflow: hidden

      .label
        font-weight: $fw-medium
        m-font-size(10, 16)
        margin-bottom: 10px

    &:not(:last-child)
      margin-bottom: 40px

    .label
      m-font-size(14, 20)
      margin-bottom: 5px
      font-weight: $fw-light

>>> .quillWrapper
  display: flex
  flex: 1 1 0%
  flex-direction: column
  overflow: hidden

>>> .ql-container
  flex: 1 1 0%
  overflow: hidden
  font-family: $base-font-family

>>> .ql-editor
  m-font-size(14, 20)
  min-height: 300px
  max-height: 300px

  +m-breakpoint(sm md)
    min-height: 150px
    max-height: 150px

  a
    color: $paarl !important

  span
    &.is-block
      display: block !important

  .for-print
    display: none !important

  .private-area-link
    display: block !important

  .color-paarl
    color: $paarl !important

.lang-toggle-container
  position: absolute
  top: -45px
  right: 0
  z-index: 1
  width: 20px
  height: 20px

  .mobile &
    top: -15px

  .lang-toggle
    position: absolute
    top: 0
    right: 0
</style>