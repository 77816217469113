import deAT from './lang/de-AT.js'
import deCH from './lang/de-CH.js'
import deDE from './lang/de-DE.js'
import enGB from './lang/en-GB.js'
import esES from './lang/es-ES.js'
import frCH from './lang/fr-CH.js'
import frFR from './lang/fr-FR.js'
import itCH from './lang/it-CH.js'
import itIT from './lang/it-IT.js'
import nlNL from './lang/nl-NL.js'

//reporting
import RLdeAT from '../reporting_lite_subfolder/i18n/lang/de-AT.js'
import RLdeCH from '../reporting_lite_subfolder/i18n/lang/de-CH.js'
import RLdeDE from '../reporting_lite_subfolder/i18n/lang/de-DE.js'
import RLenGB from '../reporting_lite_subfolder/i18n/lang/en-GB.js'
import RLesES from '../reporting_lite_subfolder/i18n/lang/es-ES.js'
import RLfrCH from '../reporting_lite_subfolder/i18n/lang/fr-CH.js'
import RLfrFR from '../reporting_lite_subfolder/i18n/lang/fr-FR.js'
import RLitCH from '../reporting_lite_subfolder/i18n/lang/it-CH.js'
import RLitIT from '../reporting_lite_subfolder/i18n/lang/it-IT.js'
import RLnlNL from '../reporting_lite_subfolder/i18n/lang/nl-NL.js'

import store from '@/store'

let storeInstance = store
let allStrings = {
  'de-AT': { ...deAT, ...RLdeAT },
  'de-CH': { ...deCH, ...RLdeCH },
  'de-DE': { ...deDE, ...RLdeDE },
  'en-GB': { ...enGB, ...RLenGB },
  'es-ES': { ...esES, ...RLesES },
  'fr-CH': { ...frCH, ...RLfrCH },
  'fr-FR': { ...frFR, ...RLfrFR },
  'it-CH': { ...itCH, ...RLitCH },
  'it-IT': { ...itIT, ...RLitIT },
  'nl-NL': { ...nlNL, ...RLnlNL },
}
export function t(key, values) {
  let str = allStrings[storeInstance.state.i18n.lang][key]
  str = Object.keys(values || {}).reduce((str, key) => {
    return str.replace('%' + key + '%', values[key])
  }, str)
  if (str === '') {
    //ANNA if it doesn't exist translation in proper language, use enGB
    str = allStrings['en-GB'][key]
    str = Object.keys(values || {}).reduce((str, key) => {
      return str.replace('%' + key + '%', values[key])
    }, str)
  }
  return str === '-' ? '' : str || '[' + key + ']'
}
export default {
  data() {
    return {
      allAvailableLang: Object.keys(allStrings),
    }
  },
  methods: {
    $t: t,
  },
}
