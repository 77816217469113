<template>
  <div class="basic-card-content">
    <loader v-if="!dataReady" />
    <template v-else>
      <div class="basic-card-header">
        <div class="basic-card-header-title">{{ $t(meta.label) }}</div>
        <div v-if="flippable" class="flip-button" @click="$emit('flip')" />
      </div>
      <!-- {{ filters }} -->
      <div v-if="mode==='front'" class="basic-card-side front">
        <div class="top">
          <main-values :data="mainValuesData" />
          <div class="chart-wrapper">
            <TrendLineChart :data="cardEvolutive.evolList" :meta="metaEvolutive" />
          </div>
        </div>
        <div class="bottom">
          <row-values :data="rowValuesData" />
        </div>
      </div>
      <div v-if="mode==='back'" class="basic-card-side back">
        <CompleteChart :data="cardEvolutive.evolList" :meta="metaEvolutive" :filters="filters" />
      </div>
      <div v-if="mode==='full'" class="basic-card-side full">
        <div class="top">
          <main-values :data="mainValuesData" />
          <row-values :data="rowValuesData" />
        </div>
        <div class="bottom">
          <CompleteChart :data="cardEvolutive.evolList" :meta="metaEvolutive" :filters="filters" />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Loader from '../Loader.vue'
import { getCardData, getCardEvolutive } from '../../services/api'
import MainValues from './MainValues.vue'
import RowValues from './RowValues.vue'
import CompleteChart from './CompleteChart.vue'
import TrendLineChart from './TrendLineChart.vue'
export default {
  components: { MainValues, RowValues, Loader, CompleteChart, TrendLineChart },
  props: ['mode', 'meta', 'filters'],
  asyncComputed: {
    cardData() {
      return getCardData(this.meta.id, this.filters)
    },
    cardEvolutive() {
      let aux = getCardEvolutive(this.meta.id, this.filters)
      // console.log('EVOLUTIVE', aux)
      return aux
      // return getCardEvolutive(this.meta.id, this.filters)
    },
  },
  computed: {
    dataReady() {
      return this.$asyncComputed.cardData.success && this.$asyncComputed.cardEvolutive.success
    },
    pairedInfo() {
      if (!this.cardData) return null
      let pairedInfo = this.meta.indList?.map(metaInd => {
        let dataInd = this.cardData?.indList?.find(e => e.id === metaInd.id)
        return { meta: metaInd, data: dataInd }
      })
      return pairedInfo
    },
    mainValuesData() {
      return this.pairedInfo
        ?.filter(e => e.meta.indType === 'primary')
        .concat(this.pairedInfo.filter(e => e.meta.indType === 'secondary'))
    },
    rowValuesData() {
      let rows = this.pairedInfo?.filter(e => e.meta.indType === 'contextual')
      return rows?.sort((a, b) => a.order - b.order)
    },
    metaEvolutive() {
      if (!this.cardEvolutive) return null
      return this.meta.evolList
    },
    flippable() {
      return ['front', 'back'].includes(this.mode)
    },
  },
}
</script>

<style lang="stylus" scoped>
.basic-card-content
  position: relative
  display: flex
  flex-direction: column
  padding: vw(20px)
  width: 100%
  height: 100%
  background-color: #fff

  .mobile &
    padding: vw(10px)

.basic-card-header
  display: flex
  justify-content: space-between
  align-items: center
  margin-bottom: vw(20px)

  .mobile &
    margin-bottom: vw(10px)

.basic-card-header-title
  m-font-size(15, 25)
  font-weight: $fw-bold

.flip-button
  m-icon("flip-gray", 20)
  cursor: pointer

.basic-card-side
  display: flex
  flex: 1 1 0%
  flex-direction: column
  overflow: hidden

  &.full
    .top
      margin-bottom: vw(5px)

    .bottom
      flex: 1 1 0%
      overflow: hidden

      .line-chart
        width: 100%

    .row-values
      flex: 1 1 0%

  &.back
    .line-chart
      height: 100%

  .top
    display: flex
    flex: 1 1 0%
    align-items: flex-start

    .comparing &
      flex: 0

    .main-values
      flex: 1 1 0%
      margin-right: vw(20px)
      height: 100%

    .line-chart
      flex: 0 0 vw(100px)

  .bottom
    display: flex

  .chart-wrapper
    width: vw(100px)
    height: vw(40px)
</style>