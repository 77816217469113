<template>
  <div class="loader">
    <div class="loader-content" />
  </div>
</template>

<script>
export default {
  name: 'Loader',
}
</script>

<style scoped lang="stylus">
.loader
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)

.loader-content
  width: vw(60px)
  height: vw(60px)
  background-image: url("../../assets/loading.svg")
  background-position: center center
  background-size: contain
  background-repeat: no-repeat
  animation: rotation 2s linear 0s infinite

  @keyframes rotation
    from
      transform: rotate(0)

    to
      transform: rotate(360deg)
</style>