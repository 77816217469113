<template>
  <div v-if="filtersDefs" class="filters">
    <div class="filters-list">
      <div v-for="filter in visibleFiltersDefs" :key="filter.id" class="filter-container">
        <InputWithLabel
          v-if="filter.component !== 'ToggleSelect'"
          v-model="internalValue[filter.id]"
          :component="filter.component"
          :label="$t(filter.id)"
          :configs="{items: filter.options, multiple : true, itemText : filter.label || 'label', itemValue: 'id'}"
        />
        <ToggleSelect
          v-if="filter.component === 'ToggleSelect'"
          v-model="internalValue[filter.id]"
          :options="filter.options"
        />
      </div>
    </div>
    <div v-if="!isMobile" class="filters-header">
      <div class="filters-info">{{ $t('active_filters') }} ({{ countFilters }})</div>
      <div :class="['btn-clear', isResetDisabled ? 'disabled': 'available']" @click="resetFilters">{{ $t('reset_btn') }}</div>
    </div>
  </div>
</template>

<script>
import { getFilterDefs, validateValues, countFiltersUsed } from '../utils/filterUtils'
import InputWithLabel from '../../../../cupra-tracking-tool/src/components/InputWithLabel.vue'
import ToggleSelect from '../../../../cupra-tracking-tool/src/components/ToggleSelect.vue'
import { getDefaultFilterValues } from '../utils/filterUtils'
export default {
  components: {
    InputWithLabel,
    ToggleSelect,
    // MultiSelect
  },
  model: {
    props: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      internalValue: null,
    }
  },
  computed: {
    role() {
      //only using role when its embedded
      return this.$store.state.user?.userData?.role || 'HQ'
    },
    isResetDisabled() {
      return JSON.stringify(this.internalValue) === JSON.stringify(getDefaultFilterValues())
    },
    clonedValue() {
      return JSON.parse(JSON.stringify(this.value))
    },
    visibleFiltersDefs() {
      return this.isMobile || this.expanded ? this.filtersDefs : this.filtersDefs.slice(0, 3)
    },
    countFilters() {
      return countFiltersUsed(this.value)
    },
  },
  watch: {
    filtersDefs: {
      deep: true,
      handler() {
        let valid = validateValues(this.value, this.filtersDefs)
        if (valid) this.$emit('change', valid)
      },
    },
    value: {
      immediate: true,
      handler(value) {
        if (JSON.stringify(value) !== JSON.stringify(this.internalValue)) this.internalValue = value
      },
    },
    internalValue: {
      deep: true,
      handler(value) {
        Object.keys(value).forEach(el => {
          if (value[el] === null) delete value[el]
        })
        this.$emit('change', JSON.parse(JSON.stringify(value)))
      },
    },
  },
  methods: {
    resetFilters() {
      this.internalValue = getDefaultFilterValues()
    },
  },
  asyncComputed: {
    filtersDefs() {
      return getFilterDefs(this.clonedValue, this.role)
    },
  },
}
</script>

<style lang="stylus" scoped>
.filters
  display: flex
  flex-direction: column

  .mobile &
    flex: 1 1 0%
    overflow: hidden
    margin-bottom: vw(30px)

.filters-header
  m-font-size(12, 14)
  display: flex
  align-items: center
  margin-top: vw(10px)

  .filters-info
    color: $rolling-stone
    font-family: "Cupra Book"

  .btn-clear
    m-font-size(11, 14)
    margin-top: 0.15em
    margin-left: vw(5px)
    color: $paarl
    text-transform: uppercase
    font-weight: $fw-medium
    opacity: 0.5
    pointer-events: none

    &.available
      opacity: 1
      cursor: pointer
      pointer-events: all

.filters-list
  display: grid
  background-color: #fff
  grid-gap: vw(15px)
  // grid-template-columns: repeat(auto-fit, minmax(200px, 1fr))
  grid-template-columns: repeat(3, 1fr)

  .comparing &
    // grid-template-columns: repeat(auto-fit, minmax(160px, 1fr))
    grid-template-columns: repeat(3, 1fr)

.filter-btn
  m-font-size(16, 19)
  display: flex
  align-items: center
  font-weight: $fw-medium
  cursor: pointer

  .mobile &
    m-font-size(14, 20)
    justify-content: flex-end

  .icon
    m-icon("filter-mobile", 16)
    margin-right: vw(10px)

.filters-wrapper
  .mobile &
    $left = 20px
    position: fixed
    top: 0
    left: $left
    z-index: 100
    display: flex
    flex-direction: column
    padding: vw(20px)
    width: "calc(100% - %s)" % $left
    height: 100%
    background-color: $wild-sand

    &:before
      position: absolute
      top: 0
      left: -($left)
      display: block
      width: $left
      height: 100vh
      background-color: rgba(#000, 0.5)
      content: ""

    .header
      display: flex
      align-items: center
      margin-right: vw(40px)
      margin-bottom: vw(30px)

    .title
      m-font-size(14, 18)
      m-letter-spacing(25)
      flex: 1 1 0%
      color: rgba($dune, 0.5)
      text-transform: uppercase

      .active-orders &
        display: none

    .btn-clear
      m-font-size(14, 18)
      m-letter-spacing(25)
      align-self: flex-end
      margin-left: auto
      color: $paarl
      text-transform: uppercase
      font-weight: $fw-medium
      opacity: 0.5
      pointer-events: none

      &.available
        opacity: 1
        cursor: pointer
        pointer-events: all

    .icon-close
      m-icon("close", 20)
      position: absolute
      top: 18px
      right: 15px
      background-size: 85% 85% !important

  .filters-list
    display: grid
    background-color: #fff
    grid-gap: vw(15px)
    // grid-template-columns: repeat(auto-fit, minmax(180px, 1fr))
    grid-template-columns: repeat(3, 1fr)

    .mobile &
      flex: 1 1 0%
      overflow-y: auto
      max-height: 100%
      background-color: $wild-sand
      grid-template-columns: auto
      grid-gap: 0
</style>