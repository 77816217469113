export default {
flip_btn: "",
compare_btn: "",
show_all_filters_btn: "",
hide_all_filters_btn: "",
toggle_filters_btn: "",
country: "",
timePeriod: "",
model: "",
tmaimg: "",
phev: "",
pr: "",
color: "",
dealer: "",
cm: "",
active: "",
CM: "",
orders: "",
"%": "",
ab_card_title: "",
fu_card_title: "",
ae_card_title: "",
aak_card_title: "",
cm_with_orders_card_title: "",
tt_registered_orders_card_title: "",
welcome_email_sent_orders_card_title: "",
engaged_customer_orders_card_title: "",
ab_card_primary: "",
ab_card_all_cupra: "",
ab_card_share: "",
ab_card_vs_last_period: "",
ab_card_vs_last_year: "",
ab_card_primary_evol: "",
ab_card_all_cupra_evol: "",
fu_card_primary: "",
fu_card_all_cupra: "",
fu_card_share: "",
fu_card_vs_last_period: "",
fu_card_vs_last_year: "",
fu_card_primary_evol: "",
fu_card_all_cupra_evol: "",
ae_card_primary: "",
ae_card_all_cupra: "",
ae_card_share: "",
ae_card_vs_last_period: "",
ae_card_vs_last_year: "",
ae_card_primary_evol: "",
ae_card_all_cupra_evol: "",
aak_card_primary: "",
aak_card_all_cupra: "",
aak_card_share: "",
aak_card_vs_last_period: "",
aak_card_vs_last_year: "",
aak_card_primary_evol: "",
aak_card_all_cupra_evol: "",
cm_with_orders_card_primary: "",
cm_with_orders_card_vs_last_period: "",
cm_with_orders_card_vs_last_year: "",
cm_with_orders_card_primary_evol: "",
tt_registered_orders_card_primary: "",
tt_registered_orders_card_vs_last_period: "",
tt_registered_orders_card_vs_last_year: "",
tt_registered_orders_card_vs_ae: "",
tt_registered_orders_card_primary_evol: "",
welcome_email_sent_orders_card_primary: "",
welcome_email_sent_orders_card_vs_last_period: "",
welcome_email_sent_orders_card_vs_last_year: "",
welcome_email_sent_orders_card_vs_tt_orders: "",
welcome_email_sent_orders_card_primary_evol: "",
engaged_customer_orders_card_primary: "",
engaged_customer_orders_card_vs_last_period: "",
engaged_customer_orders_card_vs_last_year: "",
engaged_customer_orders_card_vs_welcom_email_sent: "",
engaged_customer_orders_card_primary_evol: "",
reporting_tab_global_info_title: "",
reporting_tab_global_info_body: "",
reporting_tab: "",
time_period: "",
time_week: "",
time_ytd: "",
time_month: "",
time_from: "",
time_until: "",
reset_btn: "",
active_filters: "",
motor: ""
}