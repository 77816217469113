export default {
  methods: {
    $prettyPrint(value, unitType = '') {
      if (['orders', 'CM'].includes(unitType)) return parseInt(value)
      return parseFloat(value).toFixed(2)
    },
    $prettyNumber(value) {
      if (value / 1000000 >= 1) {
        if (parseInt(value) % 1000000 === 0) return parseInt(value) / 1000000 + 'M'
        else return (parseFloat(value) / 1000000).toFixed(1) + 'M'
      } else if (value / 1000 >= 1) {
        if (parseInt(value) % 1000 === 0) return parseInt(value) / 1000 + 'K'
        else return (parseFloat(value) / 1000).toFixed(1) + 'K'
      } else {
        return value
      }
    },
  },
}
