<template>
  <div :class="['dashboard', {'comparing': comparing}]">
    <div v-if="comparing" class="dashboard-header">
      <actions-bar
        :show-flip-all="isMobile"
        :comparing="comparing"
        :filters-expanded="filtersExpanded"
        @toggle-filters="toggleFilters"
        @flip-all="flipAll"
        @toggle-compare="comparing=!comparing"
      />
    </div>
    <div class="dashboards-container" @scroll="handleScroll">
      <div class="dashboards-wrapper">
        <dashboard-component
          ref="mainDasbhoard"
          :class="{'comparing': comparing}"
          :filters-expanded="filtersExpanded"
          :comparing="comparing"
          :scroll="compareScrollPos"
          @scroll="compareScrollPos=$event"
        >
          <template v-if="!comparing" v-slot:actions>
            <actions-bar
              :comparing="comparing"
              :filters-expanded="filtersExpanded"
              @toggle-filters="toggleFilters"
              @flip-all="flipAll"
              @toggle-compare="toggleCompare"
            />
          </template>
        </dashboard-component>
        <dashboard-component
          v-if="comparing"
          ref="compareDasbhoard"
          :class="{'comparing': comparing}"
          :filters-expanded="filtersExpanded"
          :comparing="true"
          :scroll="compareScrollPos"
          @scroll="compareScrollPos=$event"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ActionsBar from '../components/ActionsBar.vue'
import DashboardComponent from '../components/DashboardComponent.vue'
export default {
  components: {
    DashboardComponent,
    ActionsBar,
  },
  data() {
    return {
      comparing: false,
      filtersExpanded: false,
      compareScrollPos: 0
    }
  },
  methods: {
    flipAll(side = null) {
      this.$refs.mainDasbhoard.flipAll(side)
      this.$refs.compareDasbhoard?.flipAll(side)
    },
    toggleFilters() {
      this.filtersExpanded = !this.filtersExpanded
    },
    toggleCompare() {
      this.flipAll('front')
      this.comparing = !this.comparing
    },
    handleScroll() {
      Array.from(document.getElementsByClassName('d3-tip')).forEach(el => (el.style.opacity = 0))
      Array.from(document.getElementsByClassName('line-focus')).forEach(
        el => (el.style.opacity = 0)
      )
    },
  },
}
</script>

<style lang="stylus" scoped>
.dashboard
  display: flex
  flex-direction: column
  overflow: hidden
  height: 100%
  background-color: #fff

  &.comparing
    .actions-list
      flex-wrap: nowrap
      justify-content: flex-end
      align-items: center
      padding-right: vw(20px)
      padding-left: vw(20px)
      width: 100%
      height: vw(40px)

      .mobile &
        justify-content: space-between
        margin: 0
        padding: 0
        height: auto
        border: none

      +m-breakpoint(lg)
        margin: 0 auto
        max-width: $breakpoints[lg]

    .dashboards-wrapper
      position: relative

      &:before
        position: absolute
        top: 0
        left: 50%
        z-index: 100
        display: block
        width: 1px
        height: 100%
        background-color: $alto
        content: ""

        .mobile &
          display: none

.dashboard-header
  display: flex
  align-items: center

  .comparing &
    .mobile &
      border-bottom: none

  .mobile &
    padding: vw(10px) vw(15px)
    border-top: 1px solid $sisal
    border-bottom: 1px solid $sisal
    background-color: #fff

    >>> .filters
      margin-left: auto

.dashboards-container
  overflow-x: hidden
  overflow-y: auto
  height: 100%
  background-color: $wild-sand

  .comparing &
    .mobile &
      // overflow-x: auto
      // overflow-y: hidden
      overflow: hidden

  .mobile &
    overflow-x: auto
    overflow-y: auto

.dashboards-wrapper
  display: flex
  min-height: 100%

  .mobile &
    flex-direction: column

.dashboard-component
  flex: 1 1 0%

  &.comparing
    flex: 0 0 50%
    width: 50%
    height: 100%

    .mobile &
      max-height: 50%
      width: 100%

    &:first-child
      // position: relative
      // z-index: unset
      >>> .header-inner,
      >>> .content
        margin-right: 0
        max-width: "calc(%s / 2)" % $breakpoints[lg]

    &:last-child
      >>> .header-inner,
      >>> .content
        margin-left: 0
        max-width: "calc(%s / 2)" % $breakpoints[lg]

    >>> .content
      .mobile &
        &:after
          flex: 0 0 1px
          content: ""

      .card
        $percentage = percentage((1 / 1))
        $width = "calc(%s - (%s * 2))" % ($percentage $grid-margin)
        flex: 0 0 $width
        margin: $grid-margin
        width: $width
</style>