<template>
  <div class="chart">
    <svg ref="svg" :width="size.width" :height="size.height" />
  </div>
</template>

<script>
import Size from '../../mixins/Size'
import { select as d3_select } from 'd3-selection'
import { scaleLinear as d3_scaleLinear, scaleTime as d3_scaleTime } from 'd3-scale'
import { axisLeft as d3_axisLeft } from 'd3-axis'
import { max as d3_max, extent as d3_extent } from 'd3-array'
import { line as d3_line, curveCardinal as d3_curveCardinal } from 'd3-shape'
// eslint-disable-next-line
import { transition } from 'd3-transition'

export default {
  mixins: [Size],
  props: {
    data: Array,
    meta: Array,
  },
  watch: {
    data() {
      this.draw()
    },
    size() {
      this.draw()
    },
  },
  methods: {
    draw() {
      let svg = d3_select(this.$refs.svg)
      let root = svg.selectAll('.root').data([null])
      let padding = { top: 5, right: 0, bottom: 5, left: 0 }

      let rootEnter = root
        .enter()
        .append('g')
        .attr('class', 'root')
        .attr('transform', 'translate(' + padding.left + ',' + padding.top + ')')

      rootEnter.append('g').attr('class', 'yAxisL')
      rootEnter.append('g').attr('class', 'content')

      rootEnter
        .select('.content')
        .append('g')
        .attr('class', 'lines')

      root = root.merge(rootEnter)

      let lineData = this.data[0]

      let chartArea = {
        width: parseInt(this.size.width) - padding.left - padding.right,
        height: parseInt(this.size.height) - padding.top - padding.bottom,
      }

      let yScaleL = d3_scaleLinear()
        .domain([0, d3_max(lineData.dateList, d => d.value)])
        .range([chartArea.height, 0])
        .nice()

      let xScale = d3_scaleTime()
        .domain(d3_extent(lineData.dateList, d => d.date))
        .range([0, chartArea.width])
        .nice()

      //yAxisL
      root
        .select('.yAxisL')
        .call(
          d3_axisLeft(yScaleL)
            .ticks(4)
            .tickSize(-chartArea.width)
        )
        .attr('color', 'white')
        .selectAll('path')
        .attr('stroke', 'white')
      root
        .select('.yAxisL')
        .selectAll('line')
        .attr('stroke', 'lightgray')

      //linechart logics
      const lineGenerator = d3_line()
        .x(d => xScale(d.date))
        .y(d => yScaleL(d.value))
        .curve(d3_curveCardinal)

      let lineItems = root
        .select('.content')
        .select('.lines')
        .selectAll('.line-item')
        .data([lineData])

      let lineItemsEnter = lineItems
        .enter()
        .append('g')
        .attr('class', 'line-item')
        .append('path')
        .attr('class', 'line-path')
        .attr('fill', 'none')
        .attr('stroke-width', 2)
        .attr('d', d => lineGenerator(d.dateList))

      let lineItemsExit = lineItems.exit()
      lineItems = lineItems.merge(lineItemsEnter)
      lineItemsExit.remove()

      lineItems
        .transition()
        .select('path')
        .attr('d', d => lineGenerator(d.dateList))
    },
  },
}
</script>

<style lang="stylus">
.chart
  height: 100%

.line-path
  stroke: $cyprus
</style>
