<template>
  <div :class="['summary-component', isTasks ? 'tasks':'funnel']">
    <template>
      <div class="header">
        <div class="toggle-open-btn" :class="['btn btn--secondary btn--underline', 'summary-btn']" @click="isOpen=!isOpen">
          {{ $t(btnLiteral) }}
        </div>
        <div v-if="isOpen" class="summary-component-title">{{ title }}</div>
      </div>
      <div v-if="isOpen" :class="['summary-component-body', isOpen ? 'opened':'closed']">
        <template v-if="!summaryReady">
          <Loader />
        </template>
        <template v-else>
          <div v-for="kpi in kpis" :key="kpi.id" :class="['box-container', kpi.id]">
            <div :class="['box', {'blue-bg': kpi.id === 'pendingWelcome'}, {selected: activeFilterShortcutId === kpi.id }]" @click="setShortcut(kpi)">
              <div v-if="isTasks" :class="['icon', kpi.icon]" />
              <div class="value-container">
                <div class="value">{{ kpi.value }}</div>
                <div v-if="['MK', 'HQ'].includes(userRole) && kpi.over" class="over"> / {{ kpis.find(k => k.id="activeOrders").value }}</div>
              </div>
              <template v-if="activeFilterShortcutId === kpi.id">
                <v-clamp class="name" autoresize :max-lines="1">{{ $t(kpi.label) }}</v-clamp>
                <v-clamp class="explanation" autoresize :max-lines="2">{{ $t(kpi.explanation[userRole]) }}</v-clamp>
              </template>
              <template v-else>
                <v-clamp class="name" autoresize :max-lines="2">{{ $t(kpi.label) }}</v-clamp>
              </template>
            </div>
          </div>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import VClamp from 'vue-clamp'
import Loader from './Loader'

import summaryMixin from '@/components/summaryMixin'

export default {
  name: 'SummaryComponent',
  components: {
    Loader,
    VClamp,
  },
  mixins: [summaryMixin],
  data() {
    return {
      isOpen: true,
    }
  },
  computed: {
    isTasks() {
      return this.data === 'tasks'
    },
    userInfo() {
      return this.$store.state.user.userData
    },
    btnLiteral() {
      if (this.isTasks) {
        if (this.isOpen) {
          return this.userInfo.role === 'HQ' ? 'hide_hq_summary_btn' : 'hide_summary_btn'
        } else {
          return this.userInfo.role === 'HQ' ? 'show_hq_summary_btn' : 'show_summary_btn'
        }
      } else {
        return this.isOpen ? 'hide_funnel_btn' : 'show_funnel_btn'
      }
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
.summary-component
  $margin = 10px
  position: relative
  display: flex
  flex-direction: column
  overflow: hidden
  margin-top: 20px
  margin-bottom: 20px
  transition: all 0.4s ease-in-out

  &.hide
    display: none

  .loader-container
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)

  .header
    height: vh(20px)

    .toggle-open-btn
      position: absolute
      right: 0

    .summary-component-title
      m-font-size(20)
      font-weight: $fw-light

  .summary-component-body
    display: flex
    align-items: center
    margin-top: 20px
    margin-right: -($margin)
    margin-left: -($margin)

    &.closed
      margin: 0
      height: 0

    .box-container
      flex: 1 1 0%
      margin-right: $margin
      margin-left: $margin
      min-width: 0 // for the text ellipsis

      &.activeCM
        pointer-events: none

      .box
        position: relative
        padding: 15px 20px
        width: calc(100% - 1px) // for the overflow hidden on the parent
        height: 119px // for the overflow hidden on the parent
        border: 1px solid #000
        cursor: pointer

        &.blue-bg
          border-color: transparent
          background-color: rgba($cyprus, 0.65)
          color: #fff

          &.selected
            background-color: $cyprus
            color: #fff

            .icon
              &.mail
                m-icon("mail-white", 25 15)

          .icon
            &.mail
              m-icon("mail-white", 25 15)

        &.selected
          outline: 1px solid $cyprus
          border-color: $cyprus
          color: $cyprus

          .value,
          .name,
          .explanation
            font-weight: $fw-regular

          .name
            m-font-size(16, 20)

          .explanation
            font-weight: $fw-light
            m-font-size(12, 16)

          .icon
            &.bell
              m-icon("bell-cyprus", 20)

            &.re-register
              m-icon("re-register-cyprus", 20)

            &.mail
              m-icon("mail-cyprus", 25 15)

            &.paper-plane
              m-icon("paper-plane-cyprus", 22)

            &.reminder
              m-icon("reminder-cyprus", 30 18)

            &.alarm
              m-icon("alarm-cyprus", 25 20)

            &.alarm-red
              m-icon("alarm-red", 25 20)

            &.car
              m-icon("bell-cyprus", 20)

      .value-container
        display: flex
        align-items: center

        .value
          font-weight: $fw-light
          m-font-size(36, 36)
          margin-bottom: 5px

        .over
          font-weight: $fw-light
          m-font-size(26, 30)
          margin-left: 4px
          color: $alto

      .name
        font-weight: $fw-light
        m-font-size(20)

      .icon
        position: absolute
        top: 15px
        right: 15px

        &.bell
          m-icon("bell-black", 20)

        &.re-register
          m-icon("re-register-black", 20)

        &.mail
          m-icon("mail-black", 25 15)

        &.paper-plane
          m-icon("paper-plane-black", 22)

        &.reminder
          m-icon("reminder-black", 30 18)

        &.alarm
          m-icon("alarm-black", 25 20)

        &.alarm-red
          m-icon("alarm-red", 25 20)

        &.car
          m-icon("bell-black", 20)
</style>
