import { getList, getOrder } from '@/api'
// import router from '@/router'
const pagSize = 7//100

function extendedOrder (ord, role) {
  let order = ord
  order.delayedIconXOrderHistory = order.delayed // això es per OrderHistory column
  order.anyDelay = { value: order.possibleDelay, icon: ['HQ', 'MK'].includes(role) && order.delayed ? 'alarm-red' : 'alarm'}
  if (['HQ', 'MK'].includes(role)) {
    order.pendingActions = [
      {
        id: 'unreadNotifications',
        tooltip: 'unread_notifications_mk_role_tooltip',
        actionAvailable: order.hasUnreadManualNotifications,
        icon: 'bell',
        action: function (order) {
          this.$router.push({ name: 'details', params: {id: order.trackingId}})
        }
      }
    ]
    if (role === 'MK') {
      order.pendingActions.push({
        id: 'sendMkNotification',
        tooltip: 'send_mk_notification_tooltip',
        actionAvailable: true,
        icon: 'paper-plane',
        action: function (order) {
          let notifConfig = order.sendableNotifications.find(sn => { return sn.type === 'market_message'})
          this.$modal.show('sendMkNotification', {externProps: {notificationConfigId: notifConfig.notificationConfigId, trackingId: order.trackingId}})
        }
      })
    }
    order.pendingActions = order.pendingActions.filter(d => d.actionAvailable)
  } else {
    order.pendingActions = [
      {
        id: 'unreadNotifications',
        tooltip: 'unread_notifications_tooltip',
        actionAvailable: order.hasUnreadManualNotifications,
        icon: 'bell',
        action: function (order) {
          this.$router.push({ name: 'details', params: {id: order.trackingId}})
        }
      }, {
        id: 'reregistration',
        tooltip: 'reregister_this_order_tooltip',
        actionAvailable: order.reRegistrationAvailable,
        icon: 're-register',
        action: function (order) {
          this.$modal.show('reRegister', {externProps: {trackingId: order.trackingId}})
        }
      }, {
        id: 'assignCM',
        tooltip: 'assign_cupra_master_tooltip',
        actionAvailable: order.unassignedSeller && role === 'SM', //ANNA es una altra key, no reregistrationAvailable.
        icon: 'assign-cm',
        action: function (order) {
          this.$modal.show('assignCupraMaster', {externProps: {trackingId: order.trackingId}})
        }
      }, {
        id: 'promoteTrackingStatus',
        tooltip: 'inform_car_arrival_tooltip',
        actionAvailable: order.promoteTrackingStatusAvailable,
        icon: 'car',
        action: function (order) {
          this.$modal.show('informCarArrival', {externProps: {trackingId: order.trackingId}})
        }
      }, {
        id: 'sendArrivalNotification',
        tooltip: 'send_push_notification_tooltip',
        actionAvailable: order.sendableNotifications &&  order.sendableNotifications.find((sn) => { return sn.type === 'cm_client_message'}) && (!order.sentNotifications || !(order.sentNotifications && order.sentNotifications.find((sn) => { return sn.type === 'cm_client_message'}) ))? true: false,
        icon: 'paper-plane',
        action: function (order) {
          let notifConfig = order.sendableNotifications.find(sn => { return sn.type === 'cm_client_message'})
          this.$store.dispatch('notification/load', { notificationConfigId: notifConfig.notificationConfigId, trackingId: order.trackingId})
          this.$modal.show('sendArrivalNotification', {externProps: {...notifConfig, trackingId: order.trackingId, customerLang: order.customerLan}})
        }
      }, {
        id: 'reminder',
        tooltip: 'send_reminder_tooltip',
        actionAvailable: order.sendableNotifications && order.sendableNotifications.find((sn) => { return sn.type === 'manual_reminder'}) ? true: false,
        icon: 'reminder',
        action: function (order) {
          let notifConfig = order.sendableNotifications.find(sn => { return sn.type === 'manual_reminder'})
          // if (notifConfig) {
            this.$store.dispatch('notification/load', { notificationConfigId: notifConfig.notificationConfigId, trackingId: order.trackingId})
            this.$modal.show('sendReminder', {externProps: {...notifConfig, trackingId: order.trackingId, customerLang: order.customerLan}})
          // }
          // else {
          //   console.log('reminder do nothing', order.sendableNotifications )
          // }
        }
      }, {
        id: 'sendWelcome',
        tooltip: 'send_welcome_email_tooltip',
        actionAvailable: order.sendableNotifications &&  order.sendableNotifications.find((sn) => { return sn.type === 'welcome_message'}) && (!order.sentNotifications || !(order.sentNotifications && order.sentNotifications.find((sn) => { return sn.type === 'welcome_message'}) ))? true: false,
        icon: 'mail',
        action: function (order) {
          let notifConfig = order.sendableNotifications.find(sn => { return sn.type === 'welcome_message'})
          this.$store.dispatch('notification/load', { notificationConfigId: notifConfig.notificationConfigId, trackingId: order.trackingId})
          this.$modal.show('sendWelcome', {externProps: {...notifConfig, trackingId: order.trackingId, customerLang: order.customerLan}})
        }
      }
    ].filter(d=>d.actionAvailable)
  }
  return order
}
function inject (data, role) { // inject PendingActions, inject: anyDelay: possibleDelay vs delayed
  data.forEach( (order) => { return extendedOrder (order, role)})
}
export default {
  namespaced: true,
  state: {
    total: null,
    list: [],
    filters: {},
    sort: {
      sortBy: 'purchaseDate',
      sortType: 'desc'
    },
    pagination: { pag: 1, pagSize: pagSize},
    searched: null,
    loaded: false
  },
  mutations: {
    setList (state, data) {
      state.loaded = true
      state.list = data.list
      state.total = data.total
    },
    setFilters (state, data) {
      state.filters = data
    },
    setPage (state, data) {
      state.pagination.pag = data
    },
    setSort (state, data) {
      state.sort = data
    },
    setSearched (state, data) {
      state.searched = data
    }
  },
  actions: {
    load ({state, getters, commit, rootState, rootGetters}, params) {
      state.loaded = false
      if (state.searched === null || state.searched === '') {
        return getList({ filters: state.filters, pagination: state.pagination, sort: state.sort}).then((response) => {
          var data = response.data
          // console.log('filters', state.filters)
          inject(data.list, rootState.user.userData.role)
          commit('setList', data)
          return data
        })
      } else {
        // console.log('search', state.searched)
        return getOrder({value: state.searched}).then((response) => {
          var data = [response.data]
          inject(data, rootState.user.userData.role)
          commit('setList', {list: data, total: 1})
          return data
          // if(response.data.active && router.current.name === 'history') router.push({name: 'active'})
          // else if(!response.data.active && router.current.name === 'active') router.push({name: 'history'})
        }).catch(e => { // ANNAAAAA CHECK!
          var data = []
          inject(data, rootState.user.userData.role)
          commit('setList', {list: data, total: 0})
          return data
        })
      }
      // console.log('page', state.pagination.pag)
    },
    change({state, dispatch, getters, commit, rootState, rootGetters}, params) {
      if ('page' in params) {
        commit('setPage', params.page)
      } else {
        if( 'filters' in params) {
          commit('setFilters', params.filters)
        }
        if( 'sort' in params) {
          commit('setSort', params.sort)
        }
        if ('searched' in params){
          commit('setSearched', params.searched)
        } else {
          commit('setSearched', null)
        }
        commit('setPage', 1)
      }
      return dispatch('load')
    },
    changeFilters: {
      // root: true,
      handler ({state, dispatch, getters, commit, rootState, rootGetters}, params) {
        return dispatch('change', {filters: params})
      }
    },
    changePage ({state, dispatch, getters, commit, rootState, rootGetters}, num) {
      dispatch('change', {page: num})
    },
    changeSort ({state, dispatch, getters, commit, rootState, rootGetters}, params) {
      return dispatch('change', {sort: params})

    },
    changeSearch ({state, dispatch, getters, commit, rootState, rootGetters}, params) {
      // commit('setPage', 1) idk if needed!
      // commit('setSearched', params)
      // dispatch('load')
      dispatch('summary/resetShortcut', {}, {root: true})
      dispatch('change', {searched: params})
    }
  },
  getters: {
    getById: (state) => (id) => {
      return state.list && state.list.find((el) => el.trackingId === id)
    }
  }
}