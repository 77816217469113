<template>
  <div class="order-history">
    <div class="main-wrapper">
      <div v-if="!isMobile" class="sidebar">
        <Filters tab="orderHistory" />
      </div>
      <OrderList tab="orderHistory" />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import Filters from '@/components/Filters.vue'
import OrderList from '@/components/OrderList.vue'

export default {
  name: 'OrderHistory',
  components: {
    Filters,
    OrderList,
  },
  // mounted () {
  //   this.$store.dispatch('orders/')
  // },
  methods: {},
}
</script>

<style scoped lang="stylus">
.order-history
  display: flex

  &.subroute
    flex: 1 1 0%
    overflow-y: auto

  .main-wrapper
    display: flex

  .order-list
    flex: 1 1 0%

    .mobile &
      overflow: hidden
      background-color: #fff

  .sidebar
    position: relative
    display: flex
    flex: 0 0 306px
    flex-direction: column
    padding: 25px
    width: 306px

    .btn-container
      margin: 20px
</style>