<template>
  <div :class="['my-tasks-view', isTasks ? 'tasks':'funnel']">
    <template v-if="!summaryReady">
      <Loader />
    </template>
    <template v-else>
      <div v-if="isTasks" class="total-orders">{{ $t('total_active_orders') }}: {{ totalActiveOrders }}</div>
      <div v-for="kpi in kpis" :key="kpi.id" :class="['box-container', kpi.id]">
        <div :class="['box', {selected: activeFilterShortcutId === kpi.id }, {disabled: kpi.value === 0}]" @click="showMobileCards(kpi)">
          <div :class="['icon', kpi.icon]" /> <!-- v-if="isTasks" -->
          <div class="name">{{ $t(kpi.label) }}</div>
          <div class="value-container">
            <div class="value">{{ kpi.value }}</div>
            <div v-if="['MK','HQ'].includes(userRole) && kpi.over" class="over"> / {{ kpis.find(k => k.id="activeOrders").value }}</div>
          </div>
          <div v-if="kpi.value > 0" :class="['caret-icon', activeFilterShortcutId === kpi.id ? 'up': 'down', showCaret(kpi.id) ? 'show':'hide' ]" />
        </div>
        <div v-if="isMobile && activeFilterShortcutId === kpi.id" :class="['list-cards']">
          <OrderList tab="tasks" />
        </div>
      </div>
      <div class="btn-wrapper">
        <div
          v-if="isRegistrationAvailable"
          class="btn btn--primary register-btn"
          @click="$modal.show('register')"
        >
          {{ $t("register_order") }}
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import OrderList from '@/components/OrderList.vue'
import Loader from '@/components/Loader'
import summaryMixin from '@/components/summaryMixin'

export default {
  name: 'MyTasksView',
  components: {
    Loader,
    OrderList,
  },
  mixins: [summaryMixin],
  data() {
    return {
      isTasks: this.$route.name === 'tasks',
    }
  },
  computed: {
    userInfo() {
      return this.$store.state.user.userData
    },
    isRegistrationAvailable() {
      return (
        this.$route.name === 'tracking' &&
        !['MK', 'HQ'].includes(this.userInfo.role) &&
        ['ES', 'DE', 'AT'].includes(this.userInfo.countryCode)
      )
    },
    totalActiveOrders() {
      return this.$store.state.summary.data.totalActiveOrders
    },
  },
  methods: {
    showCaret(kpiId) {
      // tots son desplegables (funcionen as filters) excep for activeCM
      return kpiId !== 'activeCM'
    },
    showMobileCards(kpi) {
      // if(this.isTasks)
      this.$store.dispatch('summary/activateShortcut', { task: kpi, filters: { active: true } })
    },
  },
}
</script>

<style scoped lang="stylus">
.my-tasks-view
  display: flex
  flex-direction: column
  padding: 15px 0

  .mobile &
    padding-bottom: 60px

  #app &
    overflow-x: hidden
    overflow-y: auto

  .total-orders
    align-self: flex-end
    color: $dune
    m-font-size(13, 16)
    margin-bottom: 12px
    padding-right: 15px

  .box-container
    &.activeCM
      pointer-events: none

  .box
    position: relative
    display: flex
    align-items: center
    padding: 0 15px
    height: 60px

    &:after
      position: absolute
      bottom: 0
      left: 15px
      width: calc(100% - 30px)
      height: 1px
      background-color: $alto
      content: ""

    &.disabled
      color: rgba(#000, 0.35)
      cursor: default
      pointer-events: none

      .icon
        opacity: 0.35

    // Only affects div with ONLY class "icon".
    > div
      &[class="icon"]
        display: none

  .icon
    margin-right: 15px

    &.bell
      m-icon("bell-black", 34 20)

    &.re-register
      m-icon("re-register-black", 34 18)

    &.mail
      border-radius: 50%
      background-color: $cyprus
      background-size: 18px 11px !important
      m-icon("mail-white", 34)

    &.paper-plane
      m-icon("paper-plane-black", 34 22)

    &.reminder
      m-icon("reminder-black", 34 18)

    &.alarm
      m-icon("alarm-black", 34 18)

    &.alarm-red
      m-icon("alarm-red", 34 18)

    &.car
      m-icon("bell-black", 34 18)

  .value-container
    display: flex
    align-items: center
    margin-left: 15px

    .value
      font-weight: $fw-light
      m-font-size(16, 20)
      margin-bottom: 5px

    .over
      font-weight: $fw-light
      m-font-size(16, 20)
      margin-left: 4px
      color: $alto

  .name
    font-weight: $fw-light
    m-font-size(16, 20)
    flex: 1 1 0%

  .caret-icon
    margin-left: 15px

    // transition: transform 0.25s ease-in-out
    &.down
      background-size: 50% 50% !important
      m-icon("caret-down", 20)

    &.up
      background-size: 50% 50% !important
      m-icon("caret-down", 20)
      // animation: rotation 0.25s linear 0s 1
      transform: rotate(180deg)

    &.hide
      visibility: hidden

.list-cards
  background-color: #fff

  &.hide
    display: none

.btn-wrapper
  position: absolute
  bottom: 0
  left: 0
  padding: 10px
  width: 100%
</style>
