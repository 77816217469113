<template>
  <div class="order-details-container">
    <template v-if="!dataReady">
      <Loader />
    </template>
    <template v-else-if="!(order && order.trackingId)">
      <Error />
    </template>
    <template v-else-if="!isMobile">
      <div class="main-wrapper">
        <div class="sidebar">
          <div class="back-button" @click="$router.back()">
            <div class="back-arrow" />
            <div class="back-text">{{ $t('back') }}</div>
          </div>
          <div class="order-dates-container">
            <div class="order-status-container">
              <div class="order-status"> {{ $t('purchaseDate') }}</div>
              <div :class="['order-date', (!order.trackingDays || !order.deliveryDate) && order.anyDelay ? 'delayed' : 'non-delayed', order.anyDelay.icon]">{{ moment(order.purchaseDate).format('ll') }}</div>
              <div v-if="!order.trackingDays || !order.deliveryDate" :class="[ 'icon', order.anyDelay.value ? order.anyDelay.icon : '']" />
            </div>
            <div v-if="order.deliveryDate" class="order-status-container">
              <div class="order-status"> {{ $t('deliveryDate') }}</div>
              <div class="order-date">{{ moment(order.deliveryDate).format('ll') }}</div>
            </div>
            <div v-if="order.trackingDays && order.deliveryDate" class="order-status-container">
              <div class="order-status"> {{ $t('trackingDays') }}</div>
              <div :class="['order-date', order.delayed ? 'delayed':'non-delayed']">{{ order.trackingDays }}</div>
              <div :class="[ order.delayed ? 'icon':'']" />
            </div>
          </div>
          <div class="order-status-container">
            <div class="order-status">{{ $t('order_status') }}</div>
          </div>
          <Trackline tab="orderDetails" :order="order" />
        </div>
        <div class="details">
          <div v-if="order.active" class="actions-header">
            <div
              v-for="action in headerActions"
              :key="action.id"
              :class="[ manyHeaderActions ? 'icon': 'btn', 'btn--primary', action.icon]"
              @click="doAction(action, order)"
            >
              <template v-if="manyHeaderActions" />
              <template v-else>{{ $t(action.tooltip) }}</template>
            </div>
          </div>
          <div class="order-details">
            <div class="order-block">
              <div v-if="['HQ'].includes(role)" class="detail">
                <div class="title">{{ $t('country') }}</div>
                <div v-if="order.country && order.country.id" class="datum">{{ order.country.id }}</div>
              </div>
              <div :class="['detail', {disabled: ['IT', 'ES'].includes(userCountry)}]">
                <div class="title">{{ $t('commission_nr') }}</div>
                <div class="datum">{{ order.commissionNumber }}</div>
              </div>
              <div class="detail">
                <div class="title">{{ $t('tracking_id') }}</div>
                <div class="datum">{{ order.trackingId }}</div>
              </div>
              <div v-if="order.vin" class="detail">
                <div class="title">{{ $t('vin') }}</div>
                <div class="datum">{{ order.vin }}</div>
              </div>
              <div v-if="['MK', 'HQ'].includes(role)" class="detail">
                <div class="title">{{ $t('dealerCode') }}</div>
                <div class="datum">{{ order.dealerCode }}</div>
              </div>
              <div v-if="['SM'].includes(role)" class="detail">
                <div class="title">{{ $t('cmName') }}</div>
                <div v-if="!order.unassignedSeller" class="datum">{{ order.cmName }}</div>
                <div v-else class="datum">{{ $t('unassignedSeller') }}</div>
              </div>
              <div class="detail">
                <div class="title">{{ $t('model') }}</div>
                <div class="datum">{{ order.model }}</div>
              </div>
              <div class="detail">
                <div class="title">{{ $t('engine') }}</div>
                <div class="datum">{{ order.engine }}</div>
              </div>
              <div class="detail">
                <div class="title">{{ $t('tmaimg') }}</div>
                <div class="datum">{{ order.tmaimg }}</div>
              </div>
              <div class="detail">
                <div class="title">{{ $t('trim') }}</div>
                <div class="datum">{{ order.trim }}</div>
              </div>
              <div class="detail">
                <div class="title">{{ $t('color') }}</div>
                <div class="datum">{{ order.colorCode }}<!--  <template v-if="order.color">({{order.color}})</template> --></div>
              </div>
              <div class="detail span-double">
                <div class="title">{{ $t('prs') }}</div>
                <div class="datum">{{ order.packageList.join(', ') }}</div>
              </div>
            </div>
          </div>
          <div class="client-status">
            <div class="title">{{ $t('client_tracking_view') }}</div>
            <div class="client-status-trackline">
              <div v-for="status in clientTrackline" :key="status.id" :class="['status', status.disabled ? 'disabled':'done']">
                <div class="status-title">{{ $t(status.id) }}</div>
                <div class="status-subtitle">{{ $t(status.process) }}</div>
              </div>
            </div>
          </div>
          <div v-if="order.sentNotifications && order.sentNotifications.length > 0" :class="['notifications-section', ['CM', 'SM'].includes(role) ? 'has-email-column': '']">
            <div class="notifications-header">
              <div class="header-col title"> {{ notificationsTitle ('sent_notifications_list') }}</div>
              <div v-if="['CM', 'SM'].includes(role)" class="header-col email"> {{ $t('email') }}</div>
              <div class="header-col status"> {{ $t('status') }}</div>
              <div class="header-col date"> {{ $t('date') }}</div>
            </div>
            <div class="notifications-body">
              <div
                v-for="sn in order.sentNotifications"
                :key="sn.notificationId"
                class="row"
                @click="$modal.show('readNotification', {externProps: { ...sn } })"
              >
                <div class="content">
                  <div class="subject">{{ sn.messageSubject }}</div>
                  <div class="body" v-html="sn.messageContent" />
                </div>
                <!-- <div class="status">{{$t(sn.status+'_short')}}</div> -->
                <div v-if="['CM', 'SM'].includes(role)" class="email"> {{ sn.receiverEmail }}</div>
                <div class="status">
                  {{ $t(sn.status+'_short') }}
                  <div :class="['icon', 'bell', !sn.viewed && ['MK', 'HQ'].includes(userRole) ? 'show': 'hide']" />
                </div>
                <div class="date">{{ notificationDateFormat(sn.dateTime) }}</div>
              </div>
            </div>
          </div>
          <div v-if="order.manualReceivedNotifications && order.manualReceivedNotifications.length > 0 && ['SM', 'CM'].includes(role)" class="notifications-section">
            <div class="notifications-header">
              <div class="header-col title"> {{ notificationsTitle('market_notifications_list') }}</div>
              <div class="header-col status"> {{ $t('status') }}</div>
              <div class="header-col date"> {{ $t('date') }}</div>
            </div>
            <div class="notifications-body">
              <div
                v-for="sn in order.manualReceivedNotifications"
                :key="sn.notificationId"
                class="row"
                @click="$modal.show('readNotification', {externProps: { ...sn } })"
              >
                <div class="content">
                  <div class="subject">{{ sn.messageSubject }}</div>
                  <div class="body" v-html="sn.messageContent" />
                </div>
                <div class="status">
                  {{ $t(sn.status+'_short') }}
                  <div :class="['icon', 'bell', !sn.viewed ? 'show': 'hide']" />
                </div>
                <div class="date">{{ notificationDateFormat(sn.dateTime) }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else-if="isMobile">
      <div class="header">
        <div class="back-button" @click="$router.back()">
          <div class="back-arrow" />
          <div class="back-text">{{ $t('back') }}</div>
        </div>
        <div class="actions-header-container">
          <div v-if="order.active" class="actions-header">
            <div
              v-for="action in headerActions"
              :key="action.id"
              :class="['icon', 'btn--primary', action.icon]"
              @click="doAction(action, order)"
            />
          </div>
        </div>
      </div>
      <div class="main-wrapper">
        <div class="order-block-wrapper order_status">
          <div class="order-block-header" @click="toggleAccordion(1)">
            <div class="title">{{ $t('order_status') }}</div>
            <div :class="['caret-icon', isOpenAccordionId === 1 ? 'up':'down']" />
          </div>
          <div :class="['order-block', isOpenAccordionId === 1 ? 'selected':'unselected']">
            <div class="order-dates-container">
              <div class="order-status-container">
                <div class="order-status"> {{ $t('purchaseDate') }}</div>
                <div :class="['order-date', (!order.trackingDays || !order.deliveryDate) && order.anyDelay ? 'delayed' : 'non-delayed', order.anyDelay.icon]">{{ moment(order.purchaseDate).format('ll') }}</div>
                <div v-if="!order.trackingDays || !order.deliveryDate" :class="[ 'icon', order.anyDelay.value ? order.anyDelay.icon : '']" />
              </div>
              <div v-if="order.deliveryDate" class="order-status-container">
                <div class="order-status"> {{ $t('deliveryDate') }}</div>
                <div class="order-date">{{ moment(order.deliveryDate).format('ll') }}</div>
              </div>
              <div v-if="order.trackingDays && order.deliveryDate" class="order-status-container">
                <div class="order-status"> {{ $t('trackingDays') }}</div>
                <div :class="['order-date', order.delayed ? 'delayed':'non-delayed']">{{ order.trackingDays }}</div>
                <div :class="[ order.delayed ? 'icon':'']" />
              </div>
            </div>
            <Trackline tab="orderDetails" :order="order" />
          </div>
        </div>
        <div class="order-block-wrapper order_details">
          <div class="order-block-header" @click="toggleAccordion(2)">
            <div class="title">{{ $t('order_details') }}</div>
            <div :class="['caret-icon', isOpenAccordionId === 2 ? 'up':'down']" />
          </div>
          <div :class="['order-block', isOpenAccordionId === 2 ? 'selected':'unselected']">
            <div v-if="['HQ'].includes(role)" class="detail">
              <div class="title">{{ $t('country') }}</div>
              <div v-if="order.country && order.country.id" class="datum">{{ order.country.id }}</div>
            </div>
            <div :class="['detail', {disabled: ['IT', 'ES'].includes(userCountry)}]">
              <div class="title">{{ $t('commission_nr') }}</div>
              <div class="datum">{{ order.commissionNumber }}</div>
            </div>
            <div class="detail">
              <div class="title">{{ $t('tracking_id') }}</div>
              <div class="datum">{{ order.trackingId }}</div>
            </div>
            <div v-if="order.vin" class="detail">
              <div class="title">{{ $t('vin') }}</div>
              <div class="datum">{{ order.vin }}</div>
            </div>
            <div v-if="['MK', 'HQ'].includes(role)" class="detail">
              <div class="title">{{ $t('dealerCode') }}</div>
              <div class="datum">{{ order.dealerCode }}</div>
            </div>
            <div v-if="['SM'].includes(role)" class="detail">
              <div class="title">{{ $t('cmName') }}</div>
              <div class="datum">{{ order.cmName }}</div>
            </div>
            <div class="detail">
              <div class="title">{{ $t('model') }}</div>
              <div class="datum">{{ order.model }}</div>
            </div>
            <div class="detail">
              <div class="title">{{ $t('engine') }}</div>
              <div class="datum">{{ order.engine }}</div>
            </div>
            <div class="detail">
              <div class="title">{{ $t('tmaimg') }}</div>
              <div class="datum">{{ order.tmaimg }}</div>
            </div>
            <div class="detail">
              <div class="title">{{ $t('trim') }}</div>
              <div class="datum">{{ order.trim }}</div>
            </div>
            <div class="detail">
              <div class="title">{{ $t('color') }}</div>
              <div class="datum">{{ order.colorCode }}<!--  <template v-if="order.color">({{order.color}})</template> --></div>
            </div>
            <div class="detail">
              <div class="title">{{ $t('prs') }}</div>
              <div class="datum">{{ order.packageList.join(', ') }}</div>
            </div>
          </div>
        </div>
        <div class="order-block-wrapper client_tracking_view">
          <div class="order-block-header" @click="toggleAccordion(3)">
            <div class="title">{{ $t('client_tracking_view') }}</div>
            <div :class="['caret-icon', isOpenAccordionId === 3 ? 'up':'down']" />
          </div>
          <div :class="['order-block', isOpenAccordionId === 3 ? 'selected':'unselected']">
            <div class="client-status-trackline">
              <div v-for="status in clientTrackline" :key="status.id" :class="['status', status.disabled ? 'disabled':'done']">
                <div class="status-title">{{ $t(status.id) }}</div>
                <div class="status-subtitle">{{ $t(status.process) }}</div>
              </div>
            </div>
          </div>
        </div>
        <div :class="['order-block-wrapper sent_notifications_list', ['CM', 'SM'].includes(role) ? 'has-email-column': '']">
          <div v-if="order.sentNotifications && order.sentNotifications.length > 0" class="order-block-header" @click="toggleAccordion(4)">
            <div class="title">{{ notificationsTitle ('sent_notifications_list') }}</div>
            <div :class="['caret-icon', isOpenAccordionId === 4 ? 'up':'down']" />
          </div>
          <div :class="['order-block', isOpenAccordionId === 4 ? 'selected':'unselected']">
            <div
              v-for="sn in order.sentNotifications"
              :key="sn.notificationId"
              class="row"
              @click="$modal.show('readNotification', {externProps: { ...sn } })"
            >
              <div class="header">
                <div v-if="['CM', 'SM'].includes(role)" class="email">
                  {{ sn.receiverEmail }}
                </div>
                <div v-else class="subject">{{ sn.messageSubject }}</div>
                <div class="date">{{ notificationDateFormat(sn.dateTime) }}</div>
                <div :class="['icon', 'bell', !sn.viewed && ['MK', 'HQ'].includes(userRole) ? 'show': 'hide']" />
              </div>
              <div v-if="['CM', 'SM'].includes(role)" class="subject">{{ sn.messageSubject }}</div>
              <v-clamp
                class="body"
                autoresize
                :max-lines="2"
                v-html="sn.messageContent"
              />
              <!-- <div class="body" v-html="sn.messageContent"></div> -->
              <div class="status"> {{ $t(sn.status+'_short') }}</div>
            </div>
          </div>
        </div>
        <div class="order-block-wrapper market_notifications_list">
          <div v-if="order.manualReceivedNotifications && order.manualReceivedNotifications.length > 0 && ['SM', 'CM'].includes(role)" class="order-block-header" @click="toggleAccordion(5)">
            <div class="title">{{ notificationsTitle('market_notifications_list') }} </div>
            <div :class="['caret-icon', isOpenAccordionId === 5 ? 'up':'down']" />
          </div>
          <div :class="['order-block', isOpenAccordionId === 5 ? 'selected':'unselected']">
            <div
              v-for="sn in order.manualReceivedNotifications"
              :key="sn.notificationId"
              class="row"
              @click="$modal.show('readNotification', {externProps: { ...sn } })"
            >
              <div class="header">
                <div class="subject">{{ sn.messageSubject }}</div>
                <div class="date">{{ notificationDateFormat(sn.dateTime) }}</div>
                <div :class="['icon', 'bell', !sn.viewed ? 'show': 'hide']" />
              </div>
              <v-clamp
                class="body"
                autoresize
                :max-lines="2"
                v-html="sn.messageContent"
              />
              <!-- <div class="body" v-html="sn.messageContent"></div> -->
              <div class="status"> {{ $t(sn.status+'_short') }}</div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
// @ is an alias to /src
import Loader from '@/components/Loader'
import Error from '@/components/Error'
import Trackline from '@/components/Trackline.vue'

import VClamp from 'vue-clamp'

const clientStatus = ['orderReceived', 'production', 'dealer', 'delivered']
export default {
  name: 'OrderDetails',
  components: {
    Trackline,
    Loader,
    Error,
    VClamp,
  },
  data() {
    return {
      isOpenAccordionId: 1,
    }
  },
  computed: {
    role() {
      return this.$store.state.user.userData.role
    },
    dataReady() {
      return this.$store.state.orders.loaded
    },
    order() {
      return this.$route.params.id
        ? this.$store.getters['orders/getById'](this.$route.params.id)
        : null
    },
    userCountry() {
      return this.$store.state.user.userData.countryCode
    },
    userRole() {
      return this.$store.state.user.userData.role
    },
    headerActions() {
      //all but 'unreadNotifications' or 'unavailable reminder....
      return this.order.pendingActions.filter(
        d => d.id != 'unreadNotifications' && !(d.id === 'unengaged' && d.icon === 'grey-reminder')
      )
    },
    manyHeaderActions() {
      return this.headerActions.length > 2
    },
    clientTrackline() {
      let actualClientStatusInd = clientStatus.findIndex(
        t => t === this.order.customerTrackingStatus
      )
      let clientTrackline = clientStatus.map((t, ind) => {
        let disabled = ind > actualClientStatusInd
        if (!this.order.customerEngaged) disabled = true
        return { id: t, process: disabled ? 'pending' : 'done', disabled: disabled }
      })
      return clientTrackline
    },
  },
  methods: {
    toggleAccordion(id) {
      if (this.isOpenAccordionId === id) {
        this.isOpenAccordionId = null
      } else {
        this.isOpenAccordionId = id
      }
    },
    notificationDateFormat(dateTime) {
      let dif = this.moment().diff(this.moment(dateTime), 'days')
      if (dif > 2) return this.moment(dateTime).format('ll')
      return this.moment(dateTime).fromNow('days')
    },
    doAction(action, order) {
      action.action.call(this, order)
    },
    notificationsTitle(section) {
      if (section === 'market_notifications_list') {
        if (this.role === 'CM' || this.role === 'SM') return this.$t('market_notifications_list')
      } else if (section === 'sent_notifications_list') {
        if (this.role === 'HQ') return this.$t('mk_notifications_to_CM')
        else if (this.role === 'MK') return this.$t('your_notifications_to_CM')
        else if (this.role === 'SM') return this.$t('sent_notifications_list')
        else if (this.role === 'CM') return this.$t('sent_notifications_list')
      }
      return ''
    },
  },
}
</script>
<style scoped lang="stylus">
.order-details-container
  &.subroute
    flex: 1 1 0%
    overflow-y: auto

  .mobile &
    display: flex
    flex-direction: column

  > .header
    .mobile &
      align-items: center
      padding: 10px 15px
      min-height: 50px

  .back-button
    display: flex
    align-items: center
    margin-bottom: 50px
    cursor: pointer

  .back-arrow
    m-icon("arrow-left-black", 20 15)
    margin-right: 10px

  .back-text
    font-weight: $fw-medium
    m-font-size(13, 16)
    text-transform: capitalize

  .actions-header
    display: flex
    flex: 0 0 70px
    justify-content: flex-end
    align-items: center
    margin-top: 15px

    &:empty
      display: none

    .mobile &
      margin-top: 0

    .icon
      position: relative
      margin-left: 6px
      border: 1px solid $cyprus
      background-color: #fff
      background-size: 50% 50% !important
      cursor: pointer

      &:before
        position: absolute
        top: -1px
        left: -7px
        display: block
        width: 6px
        height: calc(100% + 2px)
        background-color: $wild-sand
        content: ""

      &.bell
        border-color: $paarl
        background-size: 45% 45% !important
        m-icon("bell-paarl", 50)
        pointer-events: none

      &.re-register
        m-icon("re-register-cyprus", 50)

      &.mail
        background-color: $cyprus
        m-icon("mail-white", 50)

      &.paper-plane
        m-icon("paper-plane-cyprus", 50)

      &.reminder
        background-size: 55% 55% !important
        m-icon("reminder-cyprus", 50)

      &.grey-reminder
        border-color: $alto
        background-color: $gallery
        m-icon("reminder-alto", 50)
        pointer-events: none

      &.car
        m-icon("car-cyprus", 50)

      &.assign-cm
        background-size: 54% 56% !important
        m-icon("assign-cm-cyprus", 60)

.sidebar
  display: flex
  flex: 0 0 20%
  flex-direction: column
  padding: 25px
  width: 20%

.order-status
  color: rgba($dune, 0.5)
  m-font-size(12, 14)
  text-transform: uppercase

  .mobile &
    margin-bottom: 10px

.order-dates-container
  display: flex
  flex-direction: column

.order-status-container
  display: flex
  margin-bottom: 25px
  width: 100%

  .mobile &
    margin-bottom: 0

  .order-date
    font-weight: $fw-light
    m-font-size(12, 14)
    margin-left: 10px

    &.delayed
      color: $cyprus
      font-weight: $fw-medium

      &.alarm-red
        color: red

  .icon
    margin-left: 5px
    m-icon("alarm-small-cyprus", 14)

    &.alarm-red
      m-icon("alarm-small-red", 14)

.details
  display: flex
  flex: 0 0 80%
  flex-direction: column
  overflow-x: hidden
  padding: 0 25px 25px

.actions-header
  display: flex
  flex: 0 0 70px
  justify-content: flex-end
  align-items: center
  margin-top: 15px

  .icon
    position: relative
    margin-left: 6px
    border: 1px solid $cyprus
    background-color: #fff
    background-size: 50% 50% !important
    cursor: pointer

    &:before
      position: absolute
      top: -1px
      left: -7px
      display: block
      width: 6px
      height: calc(100% + 2px)
      background-color: $wild-sand
      content: ""

    &.bell
      border-color: $paarl
      background-size: 45% 45% !important
      m-icon("bell-paarl", 50)
      pointer-events: none

    &.re-register
      m-icon("re-register-cyprus", 50)

    &.mail
      background-color: $cyprus
      m-icon("mail-white", 50)

    &.paper-plane
      m-icon("paper-plane-cyprus", 50)

    &.reminder
      background-size: 55% 55% !important
      m-icon("reminder-cyprus", 50)

    &.grey-reminder
      border-color: $alto
      background-color: $gallery
      m-icon("reminder-alto", 50)
      pointer-events: none

    &.car
      m-icon("car-cyprus", 50)

    &.assign-cm
      background-size: 54% 56% !important
      m-icon("assign-cm-cyprus", 60)

.order-details
  margin-top: 20px
  margin-bottom: 20px
  padding: 55px 45px 25px
  background-color: #fff

.order-block
  display: flex
  flex-wrap: wrap
  width: 100%

  .desktop &
    display: grid
    grid-template-columns: 25% 25% 25% 25%

    .detail
      &.span-double
        grid-column-end: span 2

  .detail
    flex: 0 0 25%
    margin-bottom: 30px
    padding-right: 50px

    &.disabled
      display: none

    .title
      font-weight: $fw-medium
      m-font-size(12)
      margin-bottom: 20px

    .datum
      font-weight: $fw-light
      m-font-size(14)

.client-status
  margin-bottom: 20px
  padding: 30px 45px
  background-color: #fff

  .title
    font-weight: $fw-medium
    m-font-size(16, 20)
    margin-bottom: 40px

.client-status-trackline
  display: flex
  align-items: center

  .mobile &
    flex-direction: column

  .status
    position: relative
    flex: 1 1 0%

    .mobile &
      flex: 0 0 50px
      padding-left: 20px
      width: 100%

    &:not(:last-child)
      &:before
        position: absolute
        top: -10px
        left: 0
        width: 100%
        height: 1px
        background-color: rgba($dune, 0.3)
        content: ""

        .mobile &
          position: absolute
          top: 5px
          left: 0
          width: 1px
          height: 100%
          background-color: rgba($dune, 0.3)
          content: ""

    &:after
      position: absolute
      top: -15px
      left: 0
      display: block
      width: 10px
      height: 10px
      border: 1px solid rgba($dune, 0.3)
      border-radius: 50%
      background-color: #000
      content: ""

      .mobile &
        top: 5px
        left: -5px
        width: 12px
        height: 12px

    &.disabled
      &:after
        background-color: #fff

  .status-title
    m-font-size(12)

    .mobile &
      m-font-size(16, 20)

  .status-subtitle
    font-weight: $fw-light
    m-font-size(11)
    margin-top: 2px

    .mobile &
      m-font-size(14, 18)

.notifications-section
  margin-bottom: 20px

.notifications-header
  display: flex
  align-items: center
  margin-bottom: 10px

  .header-col
    flex: 0 0 15%
    m-font-size(14, 18)

    &:first-child
      flex: 0 0 70%

    &:not(:first-child)
      font-weight: $fw-light

    .has-email-column &
      &:first-child
        flex: 0 0 50%

      &.email
        flex: 0 0 20%

.notifications-body
  m-font-size(12)

  .row
    display: flex
    align-items: center
    background-color: #fff

    &:not(:last-child)
      margin-bottom: 5px

    > div
      flex: 0 0 15%
      padding-top: 15px
      padding-bottom: 15px

      &:first-child
        flex: 0 0 70%
        padding-left: 20px

    .has-email-column &
      > div
        &:first-child
          flex: 0 0 50%

        &.email
          flex: 0 0 20%
          m-ellipsis(calc(100% - 25px))

  .content
    overflow: hidden

    .subject
      font-weight: $fw-bold
      m-ellipsis(calc(100% - 50px))
      margin-bottom: 5px

    .body
      m-ellipsis(50%)

      >>> *
        display: inline
        margin-right: 4px

      >>> > br
        display: none

  .status
    position: relative

  .icon
    display: none

    &.show
      display: block

    &.bell
      left: -30px
      m-icon("bell-paarl", 16)
      position: absolute
      top: 50%
      transform: translateY(-50%)

.mobile &
  height: 100%

  .header
    display: flex

    .back-button
      margin-bottom: 0

    .actions-header-container
      flex: 1 1 0%

  .main-wrapper
    flex-direction: column
    overflow-y: auto

    .order-block-wrapper
      display: flex
      flex-direction: column
      width: 100%

      &.order_details
        .order-block
          flex-direction: row

        .detail
          flex: 0 0 50%
          padding-right: 30px

          &:nth-last-child(-n + 2)
            margin-bottom: 0

      &.client_tracking_view
        .order-block
          padding-left: 20px

      &.sent_notifications_list,
      &.market_notifications_list
        .row
          &:not(:last-child)
            margin-bottom: 15px
            padding-bottom: 15px
            border-bottom: 1px solid $alto

      .order-block-header
        position: relative
        display: flex
        flex: 0 0 60px
        align-items: center
        padding: 0 15px

        &:after
          position: absolute
          bottom: 0
          left: 15px
          width: calc(100% - 30px)
          height: 1px
          background-color: $alto
          content: ""

        .title
          flex: 1 1 0%

        .caret-icon
          // transition: transform 0.25s ease-in-out
          &.down
            background-size: 50% 50% !important
            m-icon("caret-down", 20)

          &.up
            background-size: 50% 50% !important
            m-icon("caret-down", 20)
            // animation: rotation 0.25s linear 0s 1
            transform: rotate(180deg)

      .order-block
        display: flex
        flex-direction: column
        padding: 15px
        width: 100%
        background-color: #fff

        &.unselected
          display: none

        .order-dates-container
          margin-bottom: 0
          padding-left: 5px

        .row
          display: flex
          flex-direction: column
          width: 100%

          .header
            display: flex
            align-items: center
            margin-bottom: 10px

            .subject,
            .email
              flex: 1 1 0%
              margin-right: 30px

            .subject
              font-weight: $fw-medium
              m-ellipsis(calc(100% - 50px))
              m-font-size(14, 18)
              margin-bottom: 0

            .email
              m-ellipsis(calc(100% - 50px))
              // m-font-size(12, 14)
              // opacity: 0.35

            .date
              m-font-size(12, 14)
              opacity: 0.35

            .icon
              display: none

              &.show
                display: block

              &.bell
                m-icon("bell-paarl", 16)
                margin-left: 10px

          .subject
            m-ellipsis(100%)
            font-weight: $fw-medium
            m-font-size(14, 18)
            margin-bottom: 10px

          .body
            m-ellipsis(100%)
            m-font-size(14, 18)
            margin-bottom: 10px

            >>> *
              display: inline
              margin-right: 5px

          .status,
          .email
            m-font-size(12, 14)
            opacity: 0.35
</style>