<template>
  <LangToggle v-model="lang" />
</template>

<script>
import LangToggle from '@/components/LangToggle.vue'

import { setUserLan } from '@/api'

export default {
  name: 'HeaderLangToggle',
  components: {
    LangToggle,
  },
  data() {
    return {}
  },
  computed: {
    lang: {
      // getter
      get: function() {
        return this.$store.state.i18n.lang
      },
      // setter
      set: function(newValue) {
        setUserLan(newValue)
      },
    },
  },
  methods: {},
}
</script>

<style scoped lang="stylus">
.lang-toggle
  >>> .lang-btn
    .mobile &
      width: 100%
      border-bottom: none
      text-align: start

  >>> .lang-menu
    .mobile &
      padding: 40px
      padding-top: 50px
      padding-top: 70px !important
      padding-left: 40px
      width: 100%
      text-align: start

  &.HQ
    >>> .lang-menu
      .mobile &
        padding-left: 20px !important
</style>