<template>
  <div id="period-dropdown" :class="['period-comp', isOpen ? 'open': 'closed']">
    <div class="period-filters">
      <div v-for="mode in modes" :key="mode.id" class="filters-inputs">
        <label>
          <input
            :id="mode.id"
            v-model="internalMode"
            type="radio"
            :name="mode.id"
            :value="mode.id"
            class="radio-input"
          >
          <div class="fake-radio" />
          <div class="label-text">{{ $t('time_'+mode.id) }}</div>
        </label>
      </div>
    </div>
    <!-- {{ this.$store.state.i18n.lang }} -- {{ rangeSelectorOptions.map((d)=>d.label) }} -->
    <div v-if="internalMode && internalMode !== 'period'" class="date-selector">
      <InputWithLabel
        v-model="internalRangeSelector.endDate"
        component="SelectComp"
        :label="$t('time_' + internalMode)"
        :configs="{items: rangeSelectorOptions, multiple : false, itemText : 'label', itemValue: 'endDate'}"
      />
    </div>
    <div v-else-if="internalMode" class="double-date-selector">
      <div class="date-selector">
        <InputWithLabel
          v-model="internalRangeSelector.startDate"
          component="SelectComp"
          :label="$t('time_from')"
          :configs="{items: rangeSelectorOptionsStart, multiple : false, itemText : 'label', itemValue: 'startDate'}"
        />
      </div>
      <div class="date-selector">
        <InputWithLabel
          v-model="internalRangeSelector.endDate"
          component="SelectComp"
          :label="$t('time_until')"
          :configs="{items: rangeSelectorOptionsEnd, multiple : false, itemText : 'label', itemValue: 'endDate'}"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  getDefaultStartDate,
  getStartDate,
  getDefaultEndDate,
  getDefaultLabel,
  getTimeList,
} from '../reporting_lite_subfolder/utils/DatesUtils'
export default {
  name: 'PeriodComp',
  components: {
    InputWithLabel: () => import('./InputWithLabel.vue'),
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: { required: true },
    isOpen: { required: true },
    configs: { required: true },
  },
  data() {
    return {
      modes: [{ id: 'month' }, { id: 'week' }, { id: 'ytd' }, { id: 'period' }],
      internalRangeSelector: {},
    }
  },
  computed: {
    internalMode: {
      get() {
        return this.value.periodType
      },
      set(value) {
        this.$emit('change', {
          periodType: value,
          label: getDefaultLabel(value), // this.$t('time_' + value) + ': ' +
          rangeObj: {
            label: getDefaultLabel(value),
            startDate: getDefaultStartDate(value),
            endDate: getDefaultEndDate(value),
          },
        })
      },
    },
    rangeSelectorOptions() {
      let opt = []
      this.$store.state.i18n.lang
      opt = getTimeList(this.value.periodType === 'period' ? 'month' : this.value.periodType) || []
      return opt
    },
    rangeSelectorOptionsEnd() {
      this.$store.state.i18n.lang
      return this.rangeSelectorOptions.map(t => {
        if (t.endDate < this.internalRangeSelector.startDate) return { ...t, $isDisabled: true }
        else return t
      })
    },
    rangeSelectorOptionsStart() {
      this.$store.state.i18n.lang
      return this.rangeSelectorOptions.map(t => {
        if (t.startDate > this.internalRangeSelector.endDate) return { ...t, $isDisabled: true }
        else return t
      })
    },
  },
  watch: {
    internalRangeSelector: {
      handler(value) {
        if (
          this.value.rangeObj.startDate !== value.startDate ||
          this.value.rangeObj.endDate !== value.endDate
        ) {
          let rangeObj = {}
          if (this.value.periodType === 'period') {
            rangeObj = {
              label: getDefaultLabel(this.value.periodType, value.startDate, value.endDate),
              startDate: value.startDate,
              endDate: value.endDate,
            }
          } else {
            let startDate = getStartDate(this.value.periodType, value.endDate)
            rangeObj = {
              label: getDefaultLabel(this.value.periodType, startDate, value.endDate),
              startDate: startDate,
              endDate: value.endDate,
            }
          }
          let aux = {
            periodType: this.value.periodType,
            label: getDefaultLabel(this.value.periodType, value.startDate, value.endDate),
            rangeObj: rangeObj, //              this.$t('time_' + this.value.periodType) + ': ' +
          }
          this.$emit('change', aux)
        }
      },
      deep: true,
      // immediate: true,
    },
    value: {
      handler(value) {
        this.internalRangeSelector = JSON.parse(JSON.stringify(value.rangeObj)) //value.rangeObj //
      },
      deep: true,
      immediate: true,
    },
  },
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="stylus" scoped>
.period-comp
  position: absolute
  top: 50px
  z-index: 5
  display: flex
  flex-direction: column
  align-items: center
  padding: 5px 0
  border: 1px solid #e8e8e8
  border-top: none
  border-bottom-right-radius: 5px
  border-bottom-left-radius: 5px
  background-color: #fff
  cursor: default

  .mobile &
    background-color: $wild-sand

  &.closed
    display: none

  &.open
    display: flex

  .period-filters
    display: flex
    flex-direction: column
    margin-top: vw(10px)
    margin-bottom: vw(10px)
    padding-right: vw(10px)
    padding-left: vw(10px)
    width: 100%

  .filters-inputs
    &:not(:last-child)
      margin-bottom: vw(10px)

    .radio-input
      position: absolute
      margin: 0
      width: 1px
      height: 1px
      opacity: 0

    label
      display: flex
      align-items: center
      cursor: pointer

    input[type="radio"]
      &:checked
        + .fake-radio
          m-icon("fake-radio-checked", 20)

    .fake-radio
      m-icon("fake-radio", 20)
      margin-right: 10px

    .label-text
      m-font-size(16, 20)

  .date-selector
    padding-top: 10px
    width: 90%
    background-color: #fff

    .mobile &
      background-color: $wild-sand

  .double-date-selector
    display: flex
    justify-content: space-around
    padding-top: 10px
    width: 90%
    background-color: #fff

    .mobile &
      background-color: $wild-sand

    .date-selector
      width: 43%
      background-color: #fff

      .mobile &
        background-color: $wild-sand
</style>