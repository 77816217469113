<template>
  <div class="re-register">
    <div class="text" v-html="$t(data.body)"></div>
  </div>
</template>

<script>
import modalContentMixin from '@/components/modals/modalContentMixin'
export default {
  name: 'ReRegister',
  props: ['data'],
  mixins: [modalContentMixin],
  computed: {
    contentValue() {
      return true
    },
  },
}
</script>

<style scoped lang="stylus">
.text
  margin-bottom: 20px
</style>