<template>
  <div class="loader-container">
    <div class="loader" />
  </div>
</template>

<script>
export default {
  name: 'Loader',
}
</script>

<style scoped lang="stylus">
.loader
  width: vw(60px)
  height: vw(60px)
  background-image: url("~@/assets/loading.svg")
  background-position: center center
  background-size: contain
  background-repeat: no-repeat
  animation: rotation 2s linear 0s infinite

  @keyframes rotation
    from
      transform: rotate(0)

    to
      transform: rotate(360deg)
</style>