<template>
  <div class="header-navigation">
    <div
      v-if="isMobile && ['MK', 'HQ'].includes(userInfo.role) "
      :class="[
        'funnel-tab tab',
        { selected: $route.name === 'funnel' },
      ]"
      @click="() => {goTo('funnel')}"
    >
      {{ $t("funnel_title") }}
    </div>
    <div
      v-if="isMobile"
      :class="[
        'my-tasks-tab tab',
        { selected: $route.name === 'tasks' },
      ]"
      @click="() => {goTo('tasks')}"
    >
      {{ $t(myTasksTitle) }}
    </div>
    <div
      v-if="isMobile"
      :class="[
        'trackline-tab tab',
        { selected: $route.name === 'trackline' },
      ]"
      @click="() => {goTo('trackline')}"
    >
      {{ $t("trackline_tab_title") }}
    </div>
    <div
      :class="[
        'active-orders-tab tab',
        { selected: $route.name === 'active' },
      ]"
      @click="() => {goTo('active')}"
    >
      {{ $t("active_orders") }} ({{ totalActiveOrders }})
    </div>
    <div
      :class="[
        'order-history-tab tab',
        { selected: $route.name === 'history' },
      ]"
      @click="() => {goTo('history'); }"
    >
      {{ $t("order_history") }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeaderNavigation',
  computed: {
    userInfo() {
      return this.$store.state.user.userData
    },
    myTasksTitle() {
      return this.userInfo.role === 'HQ' ? 'hq_my_tasks_title' : 'my_tasks_title'
    },
    totalActiveOrders() {
      return this.$store.state.summary.data.totalActiveOrders
    },
  },
  methods: {
    goTo(nameView) {
      if (this.$route.name != nameView) {
        this.$router.push({ name: nameView })
      }
    },
  },
}
</script>

<style scoped lang="stylus">
.header-navigation
  display: flex
  align-self: flex-end
  align-items: center

  .mobile &
    align-self: initial
    overflow-x: auto
    height: 100%

  .tab
    border-bottom: 2px solid transparent
    font-weight: $fw-medium
    cursor: pointer
    m-font-size(16, 20)
    padding: 20px

    &.selected
      border-bottom-color: #000
      cursor: default

      .mobile &
        font-weight: $fw-bold

    .mobile &
      display: flex
      align-items: center
      height: 100%
      border-bottom-width: 3px
      font-weight: $fw-regular
      m-font-size(14, 18)
      padding: 0
      white-space: nowrap

      &:not(:last-child)
        margin-right: 30px
</style>