import { t } from '../../i18n'

var dayjs = require('dayjs')
var isoWeek = require('dayjs/plugin/isoWeek')
dayjs.extend(isoWeek)

function getPeriodDates(startDate, endDate, periodType, dataType) {
  if (dataType === 'evolutive' && periodType === 'week') {
    return {
      startDate: getQueryFormattedStrFromDate(startDate.subtract(9, 'weeks')),
      endDate: getQueryFormattedStrFromDate(endDate),
    }
  }
  return {
    startDate: getQueryFormattedStrFromDate(startDate),
    endDate: getQueryFormattedStrFromDate(endDate),
  }
}
function getLastPeriodDates(periodType, startDate, endDate) {
  let subtractOption = 'month'
  let quantity = 1
  if (periodType === 'week') {
    subtractOption = 'weeks'
  } else if (periodType === 'ytd' || periodType === 'period') {
    quantity = 1 + endDate.diff(startDate, 'month')
  }
  return {
    lastPeriodStartDate: getQueryFormattedStrFromDate(startDate.subtract(quantity, subtractOption)),
    lastPeriodEndDate: getQueryFormattedStrFromDate(endDate.subtract(quantity, subtractOption)),
  }
}
function getLastYearDates(periodType, startDate, endDate) {
  let subtractOption = 'year'
  let quantity = 1
  if (periodType === 'week') {
    quantity = 52
    subtractOption = 'weeks'
  }
  return {
    lastYearStartDate: getQueryFormattedStrFromDate(startDate.subtract(quantity, subtractOption)),
    lastYearEndDate: getQueryFormattedStrFromDate(endDate.subtract(quantity, subtractOption)),
  }
}
export function getTimeAggDate(periodType) {
  // value.periodType === 'period'  || value.periodType === 'ytd' ----> 'MONTH'
  // value.periodType === 'month' -----> 'DAY'
  // value.periodType === 'week' ----->  'WEEK'
  return {
    timeAgg: periodType === 'month' ? 'DAY' : periodType === 'week' ? 'WEEK' : 'MONTH',
  }
}
export function getPeriodParams(value, dataType) {
  let startDate = getDayJSFromStr(value.rangeObj.startDate)
  let endDate = getDayJSFromStr(value.rangeObj.endDate)
  let periodType = value.periodType
  if (dataType === 'evolutive') {
    return {
      ...getPeriodDates(startDate, endDate, periodType, dataType),
      ...getTimeAggDate(periodType),
    }
  } else {
    //dataType = 'data'
    return {
      ...getPeriodDates(startDate, endDate, periodType, dataType),
      ...getLastYearDates(periodType, startDate, endDate),
      ...getLastPeriodDates(periodType, startDate, endDate),
    }
  }
}

export function getTimeList(periodType) {
  //min by database '20210101'
  let minDate = dayjs(new Date(2021, 0, 1))

  let defaultVal = getDefaultTimePeriodValue(periodType)
  let timeOptArr = [
    defaultVal.rangeObj,
    // { ...defaultVal, id: defaultVal.label, date: { from: defaultVal.from, to: defaultVal.to } },
  ]
  let subtractOption = periodType === 'week' ? 'weeks' : 'month'
  let endOfOption = periodType === 'week' ? 'isoWeek' : 'month'
  let startOfOption = periodType === 'ytd' || periodType === 'period' ? 'year' : endOfOption

  let endDate = getDayJSFromStr(defaultVal.rangeObj.endDate)
  let startDate = getDayJSFromStr(defaultVal.rangeObj.startDate)
  let timeOpt = {}
  do {
    endDate = endDate.subtract(1, subtractOption).endOf(endOfOption)
    startDate = endDate.startOf(startOfOption)
    timeOpt = {
      label: getDefaultLabel(periodType, getStrFromDate(startDate), getStrFromDate(endDate)),
      endDate: getStrFromDate(endDate),
      startDate: getStrFromDate(startDate),
    }
    // timeOpt = {
    //   ...timeOpt,
    //   ...getLabelFields(timeOpt.periodType, timeOpt.startDate, timeOpt.endDate),
    // }
    // timeOpt = { ...timeOpt, id: timeOpt.label }
    timeOptArr.push(timeOpt)
  } while (endDate > minDate)

  // console.log(timeOptArr)

  return timeOptArr.slice(0, -1)
}

export function getDefaultEndDate(periodType) {
  let endDate = dayjs()
    .subtract(1, 'month')
    .endOf('month')
  if (periodType === 'week') {
    endDate = dayjs()
      .subtract(1, 'weeks')
      .endOf('isoWeek')
  }
  return getStrFromDate(endDate)
}
export function getStartDate(periodType, endDateStr) {
  let endDate = getDayJSFromStr(endDateStr)
  //case period: startdate is independent of endDate
  let startDate = null

  if (periodType === 'week') {
    startDate = endDate.startOf('isoWeek')
  } else if (periodType === 'month') {
    startDate = endDate.startOf('month')
  } else if (periodType === 'ytd') {
    startDate = endDate.startOf('year')
  }
  return getStrFromDate(startDate)
}

export function getDefaultStartDate(periodType) {
  let startDate = dayjs()
  if (periodType === 'week') {
    startDate = startDate.subtract(1, 'weeks').startOf('isoWeek')
  } else if (periodType === 'month') {
    startDate = startDate.subtract(1, 'month').startOf('month')
  } else if (periodType === 'ytd') {
    startDate = startDate.subtract(1, 'month').startOf('year')
  } else {
    //case period
    startDate = startDate.subtract(1, 'year').startOf('month')
  }
  return getStrFromDate(startDate)
}
function getStrFromDate(date) {
  return date.format()
}
function getQueryFormattedStrFromDate(date) {
  return date.format('YYYYMMDD')
}
export function getDayJSFromStr(dateStr) {
  return dayjs(dateStr)
}
export function getDefaultLabel(periodType, startDate = null, endDate = null) {
  if (endDate === null) {
    endDate = getDefaultEndDate(periodType)
  }
  if (startDate === null) {
    startDate = getDefaultStartDate(periodType)
  }
  startDate = getDayJSFromStr(startDate)
  endDate = getDayJSFromStr(endDate)

  let label = endDate.format('MMMM YYYY')
  if (periodType === 'week') {
    label = t('time_week') + ' ' + startDate.isoWeek() + ' - ' + startDate.year()
    // (startDate.year() !== dayjs().year() ? ' - ' + startDate.year() : '',
  } else if (periodType === 'ytd') {
    label =
      (startDate.year() === endDate.year()
        ? startDate.format('MMM')
        : startDate.format('MMM YYYY')) +
      ' - ' +
      endDate.format('MMM YYYY')
  } else if (periodType === 'period') {
    label =
      (startDate.year() === endDate.year()
        ? startDate.format('MMM')
        : startDate.format('MMM YYYY')) +
      ' - ' +
      endDate.format('MMM YYYY')
  }
  return label.charAt(0).toUpperCase() + label.slice(1)
}

export function getDefaultTimePeriodValue(option) {
  let periodType = option || 'month'
  let endDate = getDefaultEndDate(periodType)
  let startDate = getDefaultStartDate(periodType)
  let defVal = {
    periodType: periodType,
    label: getDefaultLabel(periodType, startDate, endDate),
    rangeObj: {
      label: getDefaultLabel(periodType, startDate, endDate),
      endDate: endDate,
      startDate: startDate,
    },
  }
  return defVal
}
