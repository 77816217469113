<template>
  <div :class="['header', { 'hide': hide }]">
    <div class="header-inner">
      <div class="top-header">
        <div class="left">
          <div class="logo" @click="$emit('rolechoose')" />
          <div v-if="!isMobile && isReportingTabAvailable" class="header-navigation">
            <div
              :class="['tracking', 'tab', { selected: parentRoute === 'tracking' }]"
              @click="() => {goTo('active')}"
            >
              {{ $t('tracking_tab') }}
            </div>
            <div
              :class="['reporting', 'tab', { selected: parentRoute === 'reporting' }]"
              @click="() => {goTo('reporting')}"
            >
              {{ $t('reporting_tab') }}
            </div>
          </div>
        </div>
        <div v-if="!isMobile" class="right">
          <div class="confidential-label" />
          <HeaderLangToggle />
          <div class="info-icon" @click="showInfo" />
          <div class="logout-icon" @click="doLogout" />
        </div>
        <div v-if="isMobile" class="right">
          <div class="confidential-label" />
          <div class="burger-icon" @click="mobileBurgerOpen=true" />
        </div>
      </div>
      <div v-if="searchOpen" class="mobile-search-wrapper">
        <div class="search-line-1">
          <Search @close="searchOpen = false" />
          <div class="close-icon" @click="searchOpen = false" />
        </div>
        <v-clamp class="search-long-explanation" autoresize :max-lines="2">
          *{{ $t('search_long_explanation') }}
        </v-clamp>
      </div>
      <div v-if="mobileBurgerOpen" class="burger-menu">
        <div class="top">
          <div class="close-icon" @click="mobileBurgerOpen=false" />
        </div>
        <div class="medium">
          <div v-if="isReportingTabAvailable" class="header-navigation-wrapper">
            <div class="header-navigation">
              <div
                :class="['tracking', 'tab', { selected: parentRoute === 'tracking' }]"
                @click="() => {mobileBurgerOpen=false; goTo('tasks')}"
              >
                {{ $t('tracking_tab') }}
              </div>
              <div
                :class="['reporting', 'tab', { selected: parentRoute === 'reporting' }]"
                @click="() => {mobileBurgerOpen=false; goTo('reporting')}"
              >
                {{ $t('reporting_tab') }}
              </div>
            </div>
          </div>
          <div class="list">
            <div
              v-if="parentRoute === 'tracking'"
              class="list-item search-wrapper"
              @click="()=>{mobileBurgerOpen=false;searchOpen=!searchOpen}"
            >
              <div :class="['search-btn', searchOpen ? 'close-icon':'search-icon']" />
              <div class="search-txt">{{ $t('Search') }}</div>
            </div>
            <HeaderLangToggle class="list-item" />
            <div class="list-item info" @click="showInfo">
              {{ $t('more_info') }}
            </div>
          </div>
        </div>
        <div class="bottom">
          <div class="btn-wrapper">
            <div
              v-if="isRegistrationAvailable"
              class="btn btn--primary register-btn"
              @click="show('register')"
            >
              {{ t("register_order") }}
            </div>
          </div>
          <div class="btn-wrapper">
            <div
              class="btn btn--primary logout-btn"
              @click="doLogout"
            >
              <div class="logout-icon" />
              <div class="logout-text">{{ $t('logout') }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Search from '@/components/Search'
import HeaderLangToggle from '@/components/HeaderLangToggle'

import VClamp from 'vue-clamp'
import { logout } from '@/api'

export default {
  name: 'Header',
  components: {
    HeaderLangToggle,
    Search,
    VClamp,
  },
  props: ['hide'],
  data() {
    return {
      searchOpen: false,
      mobileBurgerOpen: false,
    }
  },
  computed: {
    userInfo() {
      return this.$store.state.user.userData
    },
    isReportingTabAvailable() {
      return ['HQ'].includes(this.userInfo.role) // ANNA així es com capem la ReportingTab a nomes HQ transitoriament
    },
    isRegistrationAvailable() {
      return (
        this.$route.name === 'tracking' &&
        !['MK', 'HQ'].includes(this.userInfo.role) &&
        ['ES', 'DE', 'AT'].includes(this.userInfo.countryCode)
      )
    },
    parentRoute() {
      return this.$route.path.split('/')[1]
    },
  },
  methods: {
    goTo(nameView) {
      if (this.$route.name != nameView) {
        this.$router.push({ name: nameView })
      }
    },
    doLogout() {
      console.log('logout')
      logout().then(() => {
        this.$store.dispatch('user/resetUserInfo')
        this.$router.push({ name: 'login' })
      })
    },
    show(name) {
      this.$modal.show(name)
    },
    showInfo() {
      if (this.parentRoute === 'reporting') this.show('reportingInfo')
      else this.show('legend')
    },
  },
}
</script>

<style scoped lang="stylus">
$search-mobile-header-height = $top-mobile-header-height + 50px

.mobile-search-wrapper
  align-items: center
  background-color: $wild-sand
  display: flex
  flex-direction: column
  height: $search-mobile-header-height
  justify-content: center
  position: absolute
  top: $top-mobile-header-height
  width: 100%
  z-index: 2

  &:before
    background-color: rgba(#000, 0.5)
    content: ""
    display: block
    height: 100vh
    left: 0
    position: absolute
    top: $search-mobile-header-height
    width: 100%

  .search-line-1
    align-items: center
    display: flex

    .close-icon
      background-size: 85% 85% !important
      margin-left: 5px
      m-icon("close", 20)
      flex: 0 0 auto

    .search
      background-color: #fff

  .search-long-explanation
    m-font-size(10, 12)
    align-self: flex-start
    margin: 10px 20px

.burger-menu
  $left = 20px
  background-color: $wild-sand
  display: flex
  flex-direction: column
  height: 100%
  left: $left
  padding: vw(20px)
  position: fixed
  top: 0
  width: "calc(100% - %s)" % $left
  z-index: 100

  &:before
    background-color: rgba(#000, 0.5)
    content: ""
    display: block
    height: 100vh
    left: -($left)
    position: absolute
    top: 0
    width: $left

  .top
    flex: 0 0 $top-mobile-header-height

    .close-icon
      background-size: 85% 85% !important
      m-icon("close", 20)
      position: absolute
      right: 15px
      top: 20px

  .medium
    display: flex
    flex: 1 1 auto
    flex-direction: column

    .header-navigation-wrapper
      flex: 0 0 auto
      margin-bottom: 20px

    .list
      display: flex
      flex-direction: column

      .list-item
        border-bottom: 1px solid grey
        padding: 20px 0

  .bottom
    display: flex
    flex-direction: column

    .btn-wrapper
      .logout-btn
        display: flex

        .logout-icon
          m-icon("logout-white", 25)
          margin-right: 15px

.header
  // display: flex
  // flex-direction: column
  background-color: #fff
  z-index: 10

  .mobile &
    z-index: unset

  &.hide
    display: none

  .header-inner
    padding: 0 vw(20px)

    +m-breakpoint(lg)
      margin: 0 auto
      width: $breakpoints[lg]

    .mobile &
      padding: 0

  .top-header
    align-items: center
    display: flex
    // width: 100%
    height: 70px
    position: relative
    z-index: 1

    .mobile &
      height: $top-mobile-header-height
      padding: 0 15px

    .left
      align-items: center
      display: flex

      .logo
        background-image: url("~@/assets/cupra-logo.svg")
        background-position: center center
        background-repeat: no-repeat
        background-size: contain
        display: block
        height: 22px
        // flex: 0 0 auto
        margin-right: 50px
        width: 145px

    .right
      align-items: center
      display: flex
      margin-left: auto

      // height: 100%
      .burger-icon
        m-icon("hamburger", 20)

      .info-icon
        m-icon("info-black", 25 30)
        background-size: contain
        cursor: pointer
        margin-left: 40px

      .register-btn
        margin-left: 30px

      .round-user-icon
        $size = 40px
        border: 1px solid #000
        border-radius: 50%
        color: black
        cursor: pointer
        height: $size
        line-height: $size
        margin-left: 30px
        position: relative
        text-align: center
        text-transform: uppercase
        width: $size

      .logout-icon
        m-icon("logout-cyprus", 35 40)
        background-size: contain
        cursor: pointer
        margin-left: 30px

.confidential-label
  background-image: url("~@/assets/confidential-white.png")
  background-position: center center
  background-repeat: no-repeat
  background-size: contain
  display: block
  height: 80px
  // flex: 0 0 auto
  margin-right: 10px
  width: 100px

  &.mobile
    height: 80px
    // flex: 0 0 auto
    margin-right: 50px
    width: 100px

.header-navigation
  align-items: center
  align-self: flex-end
  display: flex

  .mobile &
    align-self: initial
    height: 100%
    overflow-x: auto

  .tab
    m-font-size(16, 20)
    border-bottom: 2px solid transparent
    cursor: pointer
    font-weight: $fw-medium
    padding-bottom: 5px

    &:not(:last-child)
      margin-right: 20px

    &.selected
      border-bottom-color: #000
      cursor: default

      .mobile &
        font-weight: $fw-bold

    .mobile &
      align-items: center
      border-bottom-width: 3px
      display: flex
      font-weight: $fw-regular
      height: 100%
      m-font-size(14, 18)
      padding: 0
      white-space: nowrap

      &:not(:last-child)
        margin-right: 30px

.search-wrapper
  display: flex

.search-btn
  margin-right: 20px

  &.search-icon
    m-icon("search-black", 20)

  &.close-icon
    background-size: 85% 85% !important
    m-icon("close", 20)
</style>