<template>
  <ul :class="['actions-list', {'active': filtersExpanded}]">
    <li
      v-if="!isMobile"
      class="actions-item toggle-filters"
      :class="filtersExpanded ? 'active' : ''"
      @click="$emit('toggle-filters')"
    >
      <div v-if="!filtersExpanded">{{ $t('show_all_filters_btn') }}</div>
      <div v-else>{{ $t('hide_all_filters_btn') }}</div>
    </li>
    <li
      v-if="showFlipAll"
      class="actions-item flip-wrapper"
      @click="$emit('flip-all')"
    >
      <div class="icon" />
      <div class="button-txt">{{ $t('flip_btn') }}</div>
    </li>
    <li class="actions-item compare-wrapper" :class="comparing? 'active':''" @click="$emit('toggle-compare')">
      <div class="icon" :class="comparing? 'close':'arrows'" />
      <div class="button-txt">{{ $t('compare_btn') }}</div>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    showFlipAll: {
      type: Boolean,
      default: true,
    },
    filtersExpanded: {
      type: Boolean,
      default: true,
    },
    comparing: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="stylus" scoped>
.actions-list
  display: flex
  margin-top: vw(15px)

  .mobile &
    justify-content: space-between
    margin-top: 0
    padding: vw(10px) vw(15px)
    width: 100%
    border-bottom: 1px solid $sisal
    background-color: #fff

.actions-item
  m-font-size(16, 19)
  display: flex
  align-items: center
  font-weight: $fw-medium
  cursor: pointer

  .mobile &
    m-font-size(14, 20)
    font-family: "Cupra Book"

  &:not(:last-child)
    margin-right: vw(30px)

    .mobile &
      margin: 0

  &.active
    color: $paarl

    &.toggle-filters
      > div
        &:before
          background-color: $paarl

    &.flip-wrapper
      .icon
        m-icon("flip-cupra", 20)

    &.compare-wrapper
      .icon
        m-icon("compare-cupra", 20)

  &.toggle-filters
    > div
      position: relative
      padding-left: vw(40px)

      &:before
        position: absolute
        bottom: 4px
        left: 0
        width: vw(30px)
        height: 1px
        background-color: #000
        content: ""

  &.flip-wrapper
    .icon
      m-icon("flip-black", 20)

  &.compare-wrapper
    .icon
      m-icon("compare-black", 20)

  .icon
    margin-right: vw(10px)
</style>