export default {
html_title: "",
welcome_title: "",
welcome_body: "",
continue_btn: "",
welcome_body_first_time: "",
country_code: "",
dealer_code: "",
hide_summary_btn: "",
show_summary_btn: "",
hide_funnel_btn: "",
show_funnel_btn: "",
my_tasks_title: "",
funnel_title: "",
pending_welcome_emails: "",
pendingWelcome: "",
cm_pending_welcome_info_on_click: "",
sm_pending_welcome_info_on_click: "",
unengaged_clients: "",
unengagedClients: "",
cm_unengaged_clients_info_on_click: "",
sm_unengaged_clients_info_on_click: "",
mk_unengaged_clients_info_on_click: "",
pushable_notifications: "",
pushableNotifications: "",
cm_pushable_notifications_info_on_click: "",
sm_pushable_notifications_info_on_click: "",
unread_notifications: "",
unreadNotifications: "",
cm_unread_notifications_info_on_click: "",
sm_unread_notifications_info_on_click: "",
mk_unread_notifications_info_on_click: "",
possible_delayed_orders: "",
possibleDelayedOrders: "",
cm_possible_delayed_orders_info_on_click: "",
sm_possible_delayed_orders_info_on_click: "",
possible_delayed_market_orders: "",
possibleDelayedMarketOrders: "",
mk_possible_delayed_market_orders_info_on_click: "",
delayed_cars: "",
delayedCars: "",
mk_delayed_cars_info_on_click: "",
total_active_orders: "",
totalActiveOrders: "",
mk_total_active_orders_info_on_click: "",
welcome_email_sent: "",
welcomeEmailSent: "",
mk_welcome_email_sent: "",
pending_deliveries: "",
pendingDeliveries: "",
mk_pending_deliveries_info_on_click: "",
cm_active: "",
cmActives: "",
mk_cm_active: "",
legend_title: "",
send_notification_legend: "",
send_welcome_legend: "",
send_reminder_legend: "",
inform_car_arrival_legend: "",
unread_notifications_legend: "",
reregister_legend: "",
send_welcome_email_tooltip: "",
send_reminder_tooltip: "",
send_push_notification_tooltip: "",
inform_car_arrival_tooltip: "",
reregister_this_order_tooltip: "",
log_out: "",
register_order: "",
download_excel: "",
send_welcome_btn: "",
send_reminder_btn: "",
send_notification_btn: "",
inform_car_arrival_btn: "",
show_results_btn: "",
cancel_btn: "",
confirm_btn: "",
submit_registration_btn: "",
print_btn: "",
clear_btn: "",
search_text: "",
trackline_tab_title: "",
PRE_A500_short: "",
PRODUCTION_STARTS_short: "",
PRODUCTION_ENDS_short: "",
FACTORY_DEPART_short: "",
COMPOUND_ARRIVAL_short: "",
COMPOUND_DEPART_short: "",
DEALER_ARRIVAL_short: "",
AAK_short: "",
PRE_A500: "",
PRODUCTION_STARTS: "",
PRODUCTION_ENDS: "",
FACTORY_DEPART: "",
COMPOUND_ARRIVAL: "",
COMPOUND_DEPART: "",
DEALER_ARRIVAL: "",
AAK: "",
filter_tab_title: "",
pending_actions: "",
order_date_range: "",
delivery_date_range: "",
active_orders: "",
order_history: "",
tracking_id: "",
status: "",
model: "",
last_status_change: "",
order_date: "",
cm_code: "",
dealer_code_short: "",
delivery_date: "",
delivered_on_time: "",
yes: "",
no: "",
days_until_delivery: "",
back: "",
commission_nr: "",
tmaimg: "",
engine: "",
trim: "",
color: "",
prs: "",
client_tracking_view: "",
orderReceived: "",
production: "",
dealer: "",
delivered: "",
pending: "",
sent_notifications_list: "",
market_notifications_list: "",
completed_orders: "",
register_title: "",
register_explanation: "",
welcome_mail_title: "",
client_mail_field: "",
client_mail_placeholder: "",
reminder_title: "",
send_arrival_notification_title: "",
inform_car_arrival_title: "",
inform_car_arrival_explanation: "",
welcome_mail_confirmation_title: "",
welcome_mail_confirmation_explanation: "",
order_status: "",
ok_btn: "",
subject: "",
body: "",
purchaseDate: "",
CMCode: "",
dealerCode: "",
deliveryDate: "",
deliveredOnTime: "",
filters: "",
models: "",
trackingId: "",
pr: "",
delayed: "",
trackingDays: "",
lastStatusChange: "",
trackingStatus: "",
done: "",
date: "",
PR: "",
pendingActions: "",
welcome_footer: "",
successful_generic_title: "",
successful_generic_text: "",
wrong_generic_title: "",
wrong_generic_text: "",
activeOrders: "",
delayedOrders: "",
activeCM: "",
clear: "",
de_AT: "",
de_CH: "",
de_DE: "",
en_GB: "",
es_ES: "",
fr_CH: "",
fr_FR: "",
it_CH: "",
it_IT: "",
nl_NL: "",
close_btn: "",
reregistration_available: "",
pending_reminders: "",
CM_to_customer_sent_notifications_list: "",
your_notifications_to_CM: "",
read_notification_title: "",
save_btn: "",
print_preview_title: "",
preview_title: "",
turn_your_device_to_landscape_mode: "",
dealer_code_input_explanation: "",
tracking_id_input_explanation: "",
download_btn: "",
pending_reminder: "",
cm_pending_reminder_info_on_click: "",
sm_pending_reminder_info_on_click: "",
authenticate_as_sales_manager: "",
authenticate_as_sales_manager_title: "",
authenticate_as_sales_manager_explanation: "",
assign_cupra_master_legend: "",
assign_cupra_master_title: "",
assign_cupra_master_explanation: "",
assign_btn: "",
authenticate_btn: "",
send_massive_notification_btn: "",
send_massive_notification_title: "",
send_massive_notification_explanation: "",
login_btn: "",
redirected_title: "",
redirected_body: "",
preview_btn: "",
reregister_explanation: "",
reregister_title: "",
welcomeMessageSent: "",
pendingToDeliver: "",
send_mk_notification_tooltip: "",
download_excel_title: "",
download_excel_explanation: "",
selection_massive_this_page_part_1: "",
selection_massive_this_page_part_2: "",
selection_massive_all_pages_part_1: "",
selection_massive_all_pages_part_2: "",
send_notification_title: "",
choose_cupra_master: "",
pendingReminders: "",
send_mk_notification_legend: "",
mk_unread_notifications_legend: "",
turn_your_device_to_portrait_mode: "",
more_info: "",
apply_filters_mobile_btn: "",
cmName: "",
logout: "",
sort_by: "",
order_details: "",
dealer_or_importer_code: "",
not_found: "",
searching: "",
pendingManualReminder: "",
unassigned_seller: "",
unassignedSeller: "",
countryCode: "",
vin: "",
hq_my_tasks_title: "",
show_hq_summary_btn: "",
hide_hq_summary_btn: "",
hq_possible_delayed_market_orders_info_on_click: "",
hq_delayed_cars_info_on_click: "",
hq_unread_notifications_info_on_click: "",
hq_pending_actions: "",
country: "",
email: "",
search_long_explanation: "",
mk_notifications_to_CM: "",
possibleDelayNotDelayed: "",
tracking_tab: "",
search: "",
time_period: "",
time_week: "",
time_ytd: "",
time_month: "",
time_from: "",
time_until: ""
}