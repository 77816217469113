<template>
  <div class="order-list">
    <template v-if="!isMobile && !listReady">
      <Loader />
    </template>
    <template v-else>
      <div v-if="!isMobile" :class="['order-list-header', {'all-pages-massive-notification-selection': globalCheckboxMassiveSelectionStatus === 'selected'}]">
        <!-- && morePages -->
        <div v-if="globalCheckboxMassiveSelectionStatus === 'selected'" class="all-pages-massive-notification">
          <!-- && morePages -->
          <div class="select-all first">
            <template v-if="!selectedAllPagesForMassiveNotification">
              <div v-html="$t('selection_massive_this_page_part_1', {PAGE_LENGTH: ordersListLength})" />
              <!-- {{$t('selection_massive_this_page_part_1', {PAGE_LENGTH: 50})}} -->
            </template>
            <template v-else>
              <div v-html="$t('selection_massive_all_pages_part_1', {ORDERS_TOTAL: ordersTotal})" />
            </template>
          </div>
          <div class="select-all second" @click="toggleAllPagesVsThisPageMassiveNotification">
            <template v-if="!selectedAllPagesForMassiveNotification">
              <div v-html="$t('selection_massive_this_page_part_2', {ORDERS_TOTAL: ordersTotal})" />
            </template>
            <template v-else>
              <div v-html="$t('selection_massive_all_pages_part_2')" />
            </template>
          </div>
        </div>
        <div
          v-if="isMassiveNotificationAvailable"
          :class="['btn', 'btn--primary', isMassiveNotificationBtnDisabled ? 'disabled': '' ]"
          @click="$modal.show('sendMassiveNotification', {externProps: { notificationConfigId: 7, selectedAllPagesForMassiveNotification, selectedForMassiveNotificationIdList }})"
        >
          {{ $t('send_massive_notification_btn') }}
        </div>
      </div>
      <div :class="['order-list-header', ['activeOrders', 'orderHistory'].includes(tab) ? 'show':'hide' ]">
        <div
          v-if="isMobile"
          class="sort-mobile-container"
        >
          <div class="sort-text">{{ $t('sort_by') }}:</div>
          <InputWithLabel
            v-model="sortMobileSelected"
            component="SelectComp"
            :label="null"
            :configs="{items: sortableColumnsMobileOptions, multiple : false, itemText : 'text', itemValue: 'value'}"
          />
          <div class="sort-type" @click="changeMobileSort(sortMobileSelected)">
            <div :class="['arrow', 'down', sort.sortType === 'desc' ? 'selected':'unselected']" />
            <div :class="['arrow', 'up', sort.sortType === 'desc' ? 'unselected':'selected']" />
          </div>
        </div>
        <div
          v-if="!isMobile"
          class="btn btn--secondary btn--underline"
          @click="$modal.show('downloadExcel')"
        >
          {{ $t("download_excel") }}
        </div>
        <Pagination v-if="!isMobile" :tab="tab" />
        <div class="icons-container">
          <div
            v-if="isMobile"
            class="icon-download"
            @click="$modal.show('downloadExcel')"
          />
          <div
            v-if="isMobile"
            class="icon-filter"
            @click="filtersMobileMenuOn = true"
          />
        </div>
      </div>
      <FiltersMobileMenu
        v-if="filtersMobileMenuOn"
        :tab="tab"
        @close="filtersMobileMenuOn = false"
        @changed-filters="changeMobileFilters"
      />
      <div v-if="isMobile" class="list-cards">
        <OrderMobileCard
          v-for="order in ordersMobile"
          :key="order.trackingId"
          v-model="checkedMobileCards[order.trackingId]"
          :role="userRole"
          :order="order"
          :tab="tab"
          :open-actions="openedCard===order.trackingId"
          @toggle="(val)=>onCardToggle(order,val)"
        />
        <infinite-loading :identifier="infiniteId" @infinite="infiniteHandler" />
      </div>
      <div v-if="isMobile && isMassiveNotificationAvailable" :class="['btn-wrapper', tab === 'activeOrders'? 'absolute':'fixed']">
        <div
          v-if="!isMassiveNotificationBtnDisabled"
          :class="[ tab === 'activeOrders' ? 'btn btn--primary': 'text']"
          @click="$modal.show('sendMassiveNotification', {externProps: { notificationConfigId: 7, selectedAllPagesForMassiveNotification, selectedForMassiveNotificationIdList: selectedForMassiveNotificationIdMobileList }})"
        >
          {{ $t('send_massive_notification_btn') }}
        </div>
      </div>
      <div v-if="!isMobile" class="list-container">
        <table class="list">
          <tr class="list-header">
            <th v-if="isMassiveNotificationAvailable" class="value check">
              <div :class="['check-box', globalCheckboxMassiveSelectionStatus]" @click="globalCheckForMassiveNotificationSelection" />
            </th>
            <th v-for="col in columns" :key="col" class="col-header">
              <div :class="['col-header-container', sortableColumns.includes(col) ? 'sortable': 'unsortable']" @click="changeSort(col)">
                <div class="col-header-name">
                  <template v-if="!['pendingActions', 'anyDelay', 'delayedIconXOrderHistory'].includes(col)">{{ $t(col) }}</template>
                  <template v-else />
                </div>
                <div :class="['col-header-sort', sort.sortBy === col ? 'selected': 'unselected', sort.sortType === 'desc' ? 'down':'up']" />
              </div>
            </th>
          </tr>
          <tr v-for="order in orders" :key="order.trackingId" class="order">
            <td v-if="isMassiveNotificationAvailable" class="value check">
              <div :class="['check-box', isSelected(order) ? 'selected': 'unselected']" @click="toggleOrderForMassiveNotificationSelection(order)" />
            </td>
            <td v-for="col in columns" :key="col" class="value">
              <div
                v-if="col != 'pendingActions'"
                :class="[
                  col,
                  {'icon': order[col]},
                  order[col] && order[col].value && order[col].icon ? order[col].icon : '',
                  {'isPaarl': col === 'trackingStatus' && moment().diff(moment(order['lastStatusChange']), 'days') < 2}]"
                @click="goTo('details', order.trackingId)"
              >
                {{ fieldFormat(order,col) }}
              </div>
              <div v-else class="actions-container">
                <div
                  v-for="action in order[col]"
                  :key="action.id"
                  :class="['icon', action.icon]"
                  @click="doAction(action,order)"
                />
                <!--la action.action esta definida a lstore i es un this.$show.modal en essencia. A lstore no hi ha el this i per tant se li ha de passar. I el MDF del vue no li agrada que li passis el this des del html... per aixo creem el "proxy" de doAction. -->
              </div>
            </td>
          </tr>
        </table>
      </div>
    </template>
  </div>
</template>

<script>
import Loader from './Loader'
import InputWithLabel from '@/components/InputWithLabel'
import Pagination from '@/components/Pagination.vue'
import OrderMobileCard from '@/components/OrderMobileCard.vue'
import formatOrderMixin from '@/components/formatOrderMixin'
import FiltersMobileMenu from '@/components/FiltersMobileMenu'

const columnsConfigurations = {
  activeOrders: {
    CM: [
      'purchaseDate',
      'trackingId',
      'trackingStatus',
      'model',
      'anyDelay',
      'lastStatusChange',
      'pendingActions',
    ],
    SM: [
      'purchaseDate',
      'trackingId',
      'trackingStatus',
      'model',
      'cmName',
      'anyDelay',
      'lastStatusChange',
      'pendingActions',
    ],
    MK: [
      'purchaseDate',
      'trackingId',
      'trackingStatus',
      'model',
      'dealerCode',
      'anyDelay',
      'lastStatusChange',
      'pendingActions',
    ],
    HQ: [
      'country',
      'purchaseDate',
      'trackingId',
      'trackingStatus',
      'model',
      'anyDelay',
      'lastStatusChange',
      'pendingActions',
    ],
  },
  orderHistory: {
    CM: [
      'purchaseDate',
      'trackingId',
      'model',
      'deliveryDate',
      'delayedIconXOrderHistory',
      'delayed',
      'trackingDays',
    ],
    SM: [
      'purchaseDate',
      'trackingId',
      'model',
      'cmName',
      'deliveryDate',
      'delayedIconXOrderHistory',
      'delayed',
      'trackingDays',
    ],
    MK: [
      'purchaseDate',
      'trackingId',
      'model',
      'dealerCode',
      'deliveryDate',
      'delayedIconXOrderHistory',
      'delayed',
      'trackingDays',
    ],
    HQ: [
      'country',
      'purchaseDate',
      'trackingId',
      'model',
      'deliveryDate',
      'delayedIconXOrderHistory',
      'delayed',
      'trackingDays',
    ],
  },
}
const sortableColumns = [
  'purchaseDate',
  'trackingId',
  'lastStatusChange',
  'cmName',
  'dealerCode',
  'deliveryDate',
  'trackingDays',
  'country',
] //

export default {
  name: 'OrderList',
  components: {
    Loader,
    OrderMobileCard,
    InputWithLabel,
    Pagination,
    FiltersMobileMenu,
  },
  mixins: [formatOrderMixin],
  props: {
    tab: String,
  },
  data() {
    return {
      checkedMobileCards: {},
      filtersMobileMenuOn: false,
      ordersMobile: [],
      pageMobile: 1,
      infiniteId: +new Date(),
      selectedForMassiveNotificationIdList: [],
      selectedAllPagesForMassiveNotification: false,
      openedCard: null,
    }
  },
  computed: {
    selectedForMassiveNotificationIdMobileList() {
      return Object.keys(this.checkedMobileCards).filter(k => this.checkedMobileCards[k])
    },
    sortMobileSelected: {
      // getter
      get: function () {
        return this.$store.state.orders.sort.sortBy
      },
      // setter
      set: function (newValue) {
        this.changeMobileSort(newValue)
      },
    },
    isMassiveNotificationBtnDisabled() {
      return this.isMobile
        ? this.selectedForMassiveNotificationIdMobileList.length === 0
        : this.selectedForMassiveNotificationIdList.length === 0
    },
    selectedForMassiveNotificationElementList() {
      return this.orders.filter(el =>
        this.selectedForMassiveNotificationIdList.includes(el.trackingId)
      )
    },
    globalCheckboxMassiveSelectionStatus() {
      if (this.selectedForMassiveNotificationElementList.length === 0) {
        return 'unselected'
      } else if (this.orders.length === this.selectedForMassiveNotificationElementList.length) {
        return 'selected'
      } else {
        return 'clearable'
      }
    },
    isMassiveNotificationAvailable() {
      return this.userRole === 'MK' && this.tab != 'orderHistory'
    },
    sortableColumns() {
      return sortableColumns
    },
    sortableColumnsMobileOptions() {
      return this.sortableColumns
        .filter(t => this.columns.includes(t))
        .map(t => {
          return { text: this.$t(t), value: t }
        })
    },
    sort() {
      return this.$store.state.orders.sort
    },
    columns() {
      let configTab = this.tab === 'orderHistory' ? this.tab : 'activeOrders'
      return columnsConfigurations[configTab][this.userRole]
    },
    morePages() {
      return this.ordersTotal > this.ordersListLength
    },
    ordersListLength() {
      return this.$store.state.orders.list.length
    },
    ordersTotal() {
      return this.$store.state.orders.total
    },
    orders() {
      return this.$store.state.orders.list
    },
    userRole() {
      return this.$store.state.user.userData.role
    },
    listReady() {
      return this.$store.state.orders.loaded
    },
  },
  watch: {
    orders() {
      this.selectedForMassiveNotificationIdList = []
      this.selectedAllPagesForMassiveNotification = false
    },
  },
  methods: {
    onCardToggle(order, val) {
      this.openedCard = val ? order.trackingId : null
    },
    infiniteHandler($state) {
      this.$store.dispatch('orders/change', { page: this.pageMobile }).then(data => {
        if (data.list.length) {
          this.pageMobile += 1
          this.ordersMobile.push(...data.list)
          $state.loaded()
        } else {
          $state.complete()
        }
      })
    },
    toggleAllPagesVsThisPageMassiveNotification() {
      if (!this.selectedAllPagesForMassiveNotification) {
        this.selectedAllPagesForMassiveNotification = true
      } else {
        this.selectedAllPagesForMassiveNotification = false
        this.selectedForMassiveNotificationIdList = []
      }
    },
    globalCheckForMassiveNotificationSelection() {
      if (this.globalCheckboxMassiveSelectionStatus === 'unselected') {
        this.selectedForMassiveNotificationIdList = this.orders.map(t => t.trackingId)
      } else if (this.globalCheckboxMassiveSelectionStatus === 'selected') {
        this.selectedForMassiveNotificationIdList = []
      } else if (this.globalCheckboxMassiveSelectionStatus === 'clearable') {
        this.selectedForMassiveNotificationIdList = []
      }
    },
    toggleOrderForMassiveNotificationSelection(order) {
      let index = this.selectedForMassiveNotificationIdList.findIndex(el => el === order.trackingId)
      this.selectedAllPagesForMassiveNotification = false
      if (index > -1) {
        this.selectedForMassiveNotificationIdList.splice(index, 1)
      } else {
        this.selectedForMassiveNotificationIdList.push(order.trackingId)
      }
    },
    isSelected(order) {
      return this.selectedForMassiveNotificationIdList.findIndex(el => el === order.trackingId) > -1
    },
    changeSort(col) {
      let sortObj = {}
      if (col === this.sort.sortBy)
        sortObj = { sortBy: col, sortType: this.sort.sortType === 'asc' ? 'desc' : 'asc' }
      else sortObj = { sortBy: col, sortType: 'desc' }
      return this.$store.dispatch('orders/changeSort', sortObj)
    },
    changeMobileSort(col) {
      let self = this
      this.changeSort(col).then(data => {
        self.infiniteId += 1
        self.pageMobile = 2
        if (data.list.length) {
          self.ordersMobile = data.list
        } else {
          self.ordersMobile = []
        }
      })
    },
    changeMobileFilters() {
      let self = this
      self.infiniteId += 1
      self.pageMobile = 1
      self.ordersMobile = []
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
.ctrl
  display: flex
  flex-direction: column
  color: grey
  text-align: end

.order-list
  position: relative
  display: flex
  flex-direction: column
  padding: 25px

  .mobile &
    padding: 0

  .order-list-header
    display: flex
    justify-content: flex-end
    align-items: center
    margin-bottom: 30px
    width: 100%

    &:empty
      display: none

    &.hide
      display: none

    &.all-pages-massive-notification-selection
      margin-top: -5px
      margin-bottom: 25px
      padding: 5px
      width: calc(100% + 5px)
      border-radius: 4px
      background-color: rgba($cyprus, 0.25)

    .mobile &
      position: sticky
      top: 0
      z-index: 1
      flex: 0 0 60px
      justify-content: flex-start
      margin-bottom: 0
      padding: 0 15px
      height: 60px
      background-color: $wild-sand

  .sort-mobile-container
    display: flex
    align-items: center
    align-items: center

    .sort-text
      font-weight: $fw-light
      m-font-size(14, 18)
      margin-right: 4px

    .input-with-label
      min-height: 25px
      width: 150px
      height: 25px

      &.label-up
        border: none

      >>> .icon
        top: 3px

      >>> .value
        top: 50%
        font-weight: $fw-regular
        transform: translateY(-50%)

    .sort-type
      display: flex
      align-items: center
      margin-left: 4px

      .arrow
        cursor: pointer
        m-icon("arrow", 10 25)

        &.down
          m-icon("arrow-filter-down", 20)

        &.up
          m-icon("arrow-filter-up", 20)

        &.unselected
          display: none

  .icons-container
    display: flex
    align-items: center

    .mobile &
      margin-left: auto

    > [class*="icon-"]
      &:not(:last-child)
        margin-right: 5px

  .icon-filter
    background-size: 60% 60% !important
    m-icon("filter-mobile", 25)

  .icon-download
    background-size: 60% 60% !important
    m-icon("download-mobile", 25)

  .list-cards
    overflow-x: hidden
    overflow-y: auto

    >>> .infinite-status-prompt
      &:nth-child(2),
      &:nth-child(3)
        display: none

  .all-pages-massive-notification
    display: flex
    flex: 1 1 0%
    flex-wrap: wrap
    align-items: center
    margin: 0 10px
    color: $cyprus

    .select-all
      &:not(:last-child)
        margin-right: 5px

      &.second
        text-decoration: underline
        font-weight: $fw-bold

  .loader-container
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)

.list-container
  flex: 1 1 0%
  margin-bottom: 30px

table,
td,
th
  border: none

.list
  width: 100%
  border-collapse: collapse

.list-header
  height: 40px

.col-header
  m-font-size(14, 16)
  padding: 5px 10px
  text-align: left
  font-weight: $fw-light

  .col-header-container
    display: flex
    align-items: center

    .col-header-name
      flex: 1 1 0%
      text-align: left

    &.unsortable
      pointer-events: none

    &.sortable
      cursor: pointer

      &:hover
        .col-header-sort
          &.unselected
            opacity: 0.75

      .col-header-sort
        cursor: pointer
        m-icon("caret-down", 10)
        margin-left: 5px

        &.unselected
          opacity: 0.25

        &.selected
          opacity: 1

          &.up
            transform: rotate(180deg)

  &:first-child
    padding-left: 20px
    // width: 120px

  // &:nth-child(2)
  // width: 100px

  // &:nth-last-child(2)
  // width: 140px
  &:last-child
    padding: 0

.order
  height: 60px
  border-bottom: 6px solid $wild-sand
  background-color: #fff

.check-box
  &.selected
    // color: blue
    m-icon("checkbox-checked", 14)

  &.unselected
    // color: red
    m-icon("checkbox-empty", 14)

  &.clearable
    // color: green
    m-icon("checkbox-clearable", 14)

.value,
>>>.item
  m-font-size(14, 18)
  padding: 0 10px

  &:first-child
    padding-left: 20px

  &:last-child
    padding: 0

  > div
    display: flex
    align-items: center
    height: 58px

    &:not(.actions-container)
      cursor: pointer

>>> .delayedIconXOrderHistory
  &.icon
    m-icon("alarm-small-cyprus", 15)

>>> .anyDelay
  &.icon
    &.alarm
      m-icon("alarm-small-cyprus", 15)

    &.alarm-red
      m-icon("alarm-small-red", 15)

>>> .isPaarl
  color: $paarl
  font-weight: $fw-medium

>>> .actions-container
  display: flex
  justify-content: flex-end
  align-items: center
  height: 58px

  .icon-download
    position: relative
    margin-left: 6px
    border: 1px solid $cyprus
    background-color: #fff
    background-size: 50% 50% !important
    cursor: pointer

    &:before
      position: absolute
      top: -1px
      left: -7px
      display: block
      width: 6px
      height: calc(100% + 2px)
      background-color: $wild-sand
      content: ""

      .mobile &
        background-color: #fff

  .icon
    position: relative
    margin-left: 6px
    border: 1px solid $cyprus
    background-color: #fff
    background-size: 50% 50% !important
    cursor: pointer

    &.bell
      m-icon("bell-paarl", 60)
      border-color: $paarl
      background-size: 45% 45% !important

    &.re-register
      m-icon("re-register-cyprus", 60)

    &.mail
      m-icon("mail-white", 60)
      background-color: $cyprus

    &.paper-plane
      m-icon("paper-plane-cyprus", 60)

    &.reminder
      m-icon("reminder-cyprus", 60)
      background-size: 55% 55% !important

    &.grey-reminder
      m-icon("reminder-alto", 60)
      border-color: $alto
      background-color: $gallery
      pointer-events: none

    &.car
      m-icon("car-cyprus", 60)

    &.assign-cm
      m-icon("assign-cm-cyprus", 60)
      background-size: 54% 56% !important

.btn-wrapper
  padding: 10px
  width: 100%

  &.fixed
    position: fixed
    top: $mobile-header-height + 2px
    left: 5px

  &.absolute
    position: absolute
    bottom: 0
    left: 0

  .text
    color: $cyprus
</style>
