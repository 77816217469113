<template>
  <div class="active-orders">
    <div class="main-wrapper">
      <div v-if="!isMobile" class="sidebar">
        <div class="navigation">
          <div :class="['option', {selected: sidebarOption === 'tracking'}]" @click="removeFilters(); setSidebar('tracking')"> {{ $t('trackline_tab_title') }}</div>
          <div :class="['option', {selected: sidebarOption === 'filters'}]" @click="() => { removeShortcut();setSidebar('filters')}"> {{ $t('filter_tab_title') }}</div>
        </div>
        <template v-if="sidebarOption==='tracking'">
          <Trackline tab="activeOrders" />
        </template>
        <template v-else>
          <Filters tab="activeOrders" />
        </template>
      </div>
      <OrderList tab="activeOrders" />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import Filters from '@/components/Filters.vue'
import Trackline from '@/components/Trackline.vue'
import OrderList from '@/components/OrderList.vue'

export default {
  name: 'ActiveOrders',
  components: {
    Trackline,
    Filters,
    OrderList,
  },
  data() {
    return {
      sidebarOption: 'tracking',
    }
  },
  computed: {
    activeShortcut() {
      return (
        this.$store.state.summary.activeFilterShortcut &&
        this.$store.state.summary.activeFilterShortcut.id
      )
    },
  },
  watch: {
    activeShortcut() {
      if (this.activeShortcut != null) this.sidebarOption = 'tracking'
    },
  },
  mounted() {
    if (this.activeShortcut != null) this.sidebarOption = 'tracking'
    else if (Object.keys(this.$store.state.orders.filters).length > 1)
      this.sidebarOption = 'filters'
  },
  methods: {
    setSidebar(option) {
      this.sidebarOption = option
    },
    removeShortcut() {
      this.$store.dispatch('summary/activateShortcut', { task: null, filters: { active: true } })
    },
    removeFilters() {
      this.$store.dispatch('orders/changeFilters', { active: true })
    },
  },
}
</script>

<style scoped lang="stylus">
.active-orders
  &.subroute
    flex: 1 1 0%
    overflow-y: auto

  .main-wrapper
    display: flex

  .order-list
    flex: 1 1 0%

    .mobile &
      overflow: hidden
      background-color: #fff

  .sidebar
    display: flex
    flex: 0 0 20%
    flex-direction: column
    padding: 25px
    width: 20%

    .navigation
      display: flex
      flex: 0 0 30px
      margin-bottom: 10px

      .option
        flex: 1 1 0%
        border-bottom: 1px solid $alto
        font-weight: $fw-light
        cursor: pointer
        m-ellipsis(100%)
        m-font-size(14, 18)
        text-align: center

        &.selected
          border-bottom-color: #000
          font-weight: $fw-medium
          cursor: default
</style>