<template>
  <div class="mobile-filters-wrapper">
    <div class="filter-group-description-wrapper">
      <v-clamp class="filter-group-description" autoresize :max-lines="1">
        {{ summaryValues }}
      </v-clamp>
    </div>
    <div class="filter-btn actions-item" @click="mobileFiltersShown=true">
      <div class="icon" />
      <div class="button-txt">
        {{ $t('filters') }} ({{ countFilters }})
      </div>
    </div>
    <div v-if="mobileFiltersShown" class="filters-wrapper">
      <div class="header">
        <div class="title">{{ $t('filters') }}</div>
        <div :class="['btn-clear', 'available']" @click="resetFilters">{{ $t('reset_btn') }}</div>
        <div class="icon-close" @click="close" />
      </div>
      <filters v-model="internalFilters" :expanded="true" />
      <div class="bottom">
        <div class="apply-filters-btn-container">
          <div :class="['btn', 'btn--primary', 'apply-filters-btn', 'available']" @click="applyMobileFilters">
            {{ $t('apply_filters_mobile_btn') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getFilterDefs,
  getDefaultFilterValues,
  showFiltersDescription,
  countFiltersUsed,
} from '../utils/filterUtils'
import Filters from './Filters'

import VClamp from 'vue-clamp'
export default {
  components: {
    Filters,
    VClamp,
  },
  mixins: [],
  model: {
    props: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      mobileFiltersShown: false,
      internalFilters: JSON.parse(JSON.stringify(this.value)),
    }
  },
  computed: {
    role() {
      //only using role when its embedded
      return this.$store.state.user?.userData?.role || 'HQ'
    },
    summaryValues() {
      return showFiltersDescription(this.value, this.filtersDefs)
    },
    countFilters() {
      return countFiltersUsed(this.value)
    },
  },
  asyncComputed: {
    filtersDefs() {
      return getFilterDefs(this.value, this.role)
    },
  },
  watch: {
    value() {
      this.internalFilters = JSON.parse(JSON.stringify(this.value))
    },
  },
  methods: {
    close() {
      this.internalFilters = JSON.parse(JSON.stringify(this.value))
      this.mobileFiltersShown = false
    },
    resetFilters() {
      this.internalFilters = getDefaultFilterValues()
    },
    applyMobileFilters() {
      this.$emit('change', JSON.parse(JSON.stringify(this.internalFilters)))
      this.mobileFiltersShown = false
    },
  },
}
</script>

<style lang="stylus" scoped>
.mobile-filters-wrapper
  position: relative
  display: flex
  flex: 1 1 0%
  padding: vw(10px) vw(15px)
  width: 100%

  .filter-group-description-wrapper
    font-weight: $fw-medium

  .filter-group-description
    m-font-size(14, 20)
    margin-right: vw(20px)
    font-family: "Cupra Book"

  .filters-btn,
  .actions-item
    m-font-size(14, 20)
    display: flex
    justify-content: flex-end
    align-items: center
    margin-left: auto
    font-weight: $fw-medium
    cursor: pointer

    .mobile &
      font-family: "Cupra Book"

    .icon
      m-icon("filter-mobile", 16)
      margin-right: vw(5px)

.filters-wrapper
  $left = 20px
  position: fixed
  top: 0
  left: $left
  z-index: 100
  display: flex
  flex-direction: column
  padding: vw(20px)
  padding-top: vw(25px)
  width: "calc(100% - %s)" % $left
  height: 100%
  background-color: $wild-sand

  &:before
    position: absolute
    top: 0
    left: -($left)
    display: block
    width: $left
    height: 100vh
    background-color: rgba(#000, 0.5)
    content: ""

  .header
    display: flex
    align-items: center
    margin-right: vw(40px)
    margin-bottom: vw(20px)

  .title
    m-font-size(14, 18)
    m-letter-spacing(25)
    flex: 1 1 0%
    color: rgba($dune, 0.5)
    text-transform: uppercase

    .active-orders &
      display: none

  .btn-clear
    m-font-size(14, 18)
    m-letter-spacing(25)
    align-self: flex-end
    margin-left: auto
    color: $paarl
    text-transform: uppercase
    font-weight: $fw-medium
    opacity: 0.5
    pointer-events: none

    &.available
      opacity: 1
      cursor: pointer
      pointer-events: all

  .icon-close
    m-icon("close", 20)
    position: absolute
    top: 20px
    right: 15px
    background-size: 85% 85% !important

>>> .calendar-comp
  .calendar
    &.open
      position: relative !important
      width: 100%

>>> .select-comp
  margin-bottom: 0 !important
  height: auto !important

  .multiselect
    top: 0 !important
    margin-top: 50px !important
    width: 100% !important

    .mobile &
      margin-top: 60px !important

    .multiselect__content-wrapper
      position: relative !important
      display: block !important
      max-height: 32vh !important
      width: auto !important

      >>> .multiselect__option
        white-space: normal !important
</style>