export default {
  props: {
    data: String
  },
  computed: {
    title () {
      return !this.isTasks ? this.$t('funnel_title') : this.userRole !== 'HQ' ? this.$t('my_tasks_title') : this.$t('hq_my_tasks_title')
    },
    summaryReady () {
      return this.$store.state.summary.loaded
    },
    userRole () {
      return this.$store.state.user.userData.role
    },
    kpis () {
      let tasksField = 'tasks'
      if (['MK', 'HQ'].includes(this.userRole)) tasksField = 'marketTasks' // ANNA A ESBORRAR POST DEMO!!!!
      return this.isTasks ? this.$store.state.summary.data[tasksField] : this.$store.state.summary.data.funnel
    },
    activeFilterShortcutId () {
      return this.$store.state.summary.activeFilterShortcut && this.$store.state.summary.activeFilterShortcut.id
    }
  },
  methods: {
    setShortcut (task) {
      if (!this.isMobile && this.$route.name != 'active') {
        this.$store.dispatch('summary/activateShortcut', {task: task, filters: {active: true}, sort: {sortBy: 'purchaseDate', sortType: 'desc'}})
        this.$router.push({'name': 'active'})
      } else if (this.isMobile) {
        let self = this
        this.$store.dispatch('summary/activateShortcut', {task: task, filters: {active: true}, sort: {sortBy: 'purchaseDate', sortType: 'desc'}})
          .then((data) => {
            self.infiniteId += 1
            self.pageMobile = 2
            if (data.list.length) {
              self.ordersMobile = data.list
            } else {
              self.ordersMobile = []
            }
          })
      } else {
        this.$store.dispatch('summary/activateShortcut', {task: task, filters: {active: true}})
      }
    }
  }
}