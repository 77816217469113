<template>
  <div v-if="Object.keys(availableLang).length > 1" v-click-outside="onClickOutside" :class="['lang-toggle', userRole, isOpen ? 'open': 'closed']">
    <div :class="['lang', 'lang-btn', Object.keys(availableLang).length > 1 ? 'enabled' : 'disabled' ]" @click="isOpen=!isOpen"> {{ availableLang[lang] }}</div>
    <div :class="['lang-menu', isOpen ? 'open': 'closed']">
      <div
        v-for="(val,key) in availableLang"
        :key="key"
        :class="['lang', {selected: key === lang}]"
        @click="setLanguage(key)"
      >
        {{ val }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LangToggle',
  components: {},
  model: {
    prop: 'lang',
    event: 'change',
  },
  props: ['lang'],
  data() {
    return {
      isOpen: false,
    }
  },
  computed: {
    availableLang() {
      //is an object like {'de-CH': DE, 'fr-CH': FR, 'it-CH': IT}
      if (this.userRole != 'HQ') {
        let countryLength = this.userCountry.length
        let aux = this.allAvailableLang //ANNAAAAAA comenta lo seguent si vols tenir tots els idiomes....
          .filter(lang => {
            let langLength = lang.length
            let countryLang = lang.slice(langLength - countryLength, langLength)
            return countryLang === this.userCountry
          })
        return Object.assign(...aux.map(v => ({ [v]: v.slice(0, 2).toUpperCase() })))
      } else {
        return [] // Object.assign(...this.allAvailableLang.map(v => ({ [v] : v })))
      }
    },
    userRole() {
      return this.$store.state.user.userData.role
    },
    userCountry() {
      return this.$store.state.user.userData.countryCode
        ? this.$store.state.user.userData.countryCode
        : 'GB'
    },
  },
  methods: {
    onClickOutside() {
      this.isOpen = false
    },
    openDropdown() {
      this.isOpen != this.isOpen
    },
    setLanguage(langSelected) {
      this.isOpen = !this.isOpen
      this.$emit('change', langSelected)
    },
  },
}
</script>

<style scoped lang="stylus">
.lang-toggle
  display: flex
  flex-direction: column
  m-font-size(16, 20)
  position: relative

  &.open
    .lang-btn
      border-bottom-width: 2px
      font-weight: $fw-medium
      opacity: 1

  &.HQ
    .lang-btn
      width: 60px

    .lang-menu
      left: -20px

  .lang-btn
    position: relative
    z-index: 110
    width: 20px
    border-bottom: 1px solid #000
    text-align: center
    opacity: 0.75
    cursor: pointer

    &:hover,
    &:active
      opacity: 1

    &.disabled
      opacity: 0.25
      pointer-events: none

  .lang-menu
    position: absolute
    top: -10px
    left: -40px
    padding: 10px
    padding-top: 40px
    width: 100px
    background-color: #fff
    box-shadow: 0 2px 5px rgba(#000, 0.15)
    text-align: center

    .send &
      .mobile &
        left: -20px
        width: 55px

    &.closed
      display: none

    .lang
      cursor: pointer

      .mobile &
        height: 30px

      &:hover,
      &:active
        font-weight: $fw-medium

      &:not(:last-child)
        margin-bottom: 5px

      &.selected
        font-weight: $fw-regular
        opacity: 0.25
        cursor: default
</style>