export default {
  html_title: 'Tracking Tool',
  welcome_title: 'Willkommen im CUPRA Tracking Tool',
  welcome_body: '-',
  continue_btn: 'Weiter',
  welcome_body_first_time:
    'Dies ist deine erste Anmeldung, wir benötigen daher einige Zusatz-Infos. Diese Daten werden nur einmal angefordert. <br><br>Bitte fülle die folgenden Felder aus:',
  country_code: 'ISO-Ländercode',
  dealer_code: 'Händlernummer',
  hide_summary_btn: 'Meine Tasks ausblenden',
  show_summary_btn: 'Meine Tasks anzeigen',
  hide_funnel_btn: 'Filter ausblenden',
  show_funnel_btn: 'Filter anzeigen',
  my_tasks_title: 'Meine Tasks',
  funnel_title: 'Filter',
  pending_welcome_emails: 'Nicht gesendete Willkommens-Mails',
  pendingWelcome: 'Nicht gesendete Willkommens-Mails',
  cm_pending_welcome_info_on_click: 'Zugriffsdaten an den Kunden senden',
  sm_pending_welcome_info_on_click: 'Zugriffsdaten an den Kunden senden',
  unengaged_clients: 'Kunden ohne Registrierung für das Tracking Tool',
  unengagedClients: 'Kunden ohne Registrierung für das Tracking Tool',
  cm_unengaged_clients_info_on_click:
    'Diese Kunden haben noch nicht auf ihr Tracking Tool zugegriffen',
  sm_unengaged_clients_info_on_click:
    'Diese Kunden haben noch nicht auf ihr Tracking Tool zugegriffen',
  mk_unengaged_clients_info_on_click:
    'Diese Kunden haben noch nicht auf ihr Tracking Tool zugegriffen',
  pushable_notifications: 'Nicht gesendete Ankunfts-Mails',
  pushableNotifications: 'Nicht gesendete Ankunfts-Mails',
  cm_pushable_notifications_info_on_click: 'Information senden, dass das Fahrzeug eingetroffen ist',
  sm_pushable_notifications_info_on_click: 'Information senden, dass das Fahrzeug eingetroffen ist',
  unread_notifications: 'Ungelesene Nachrichten',
  unreadNotifications: 'Ungelesene Nachrichten',
  cm_unread_notifications_info_on_click: 'Diese Nachrichten hast du noch nicht gelesen.',
  sm_unread_notifications_info_on_click: 'Diese Nachrichten haben deine CMs noch nicht gelesen.',
  mk_unread_notifications_info_on_click:
    'Diese Benachrichtigungen wurden noch nicht vom betreffenden CUPRA Master gelesen',
  possible_delayed_orders: 'Verzögerte Bestellungen',
  possibleDelayedOrders: 'Verzögerte Bestellungen',
  cm_possible_delayed_orders_info_on_click:
    'Aktive Bestellungen, die vor mehr als 27 Tagen getätigt wurden.',
  sm_possible_delayed_orders_info_on_click:
    'Aktive Bestellungen, die vor mehr als 27 Tagen getätigt wurden.',
  possible_delayed_market_orders: 'Möglicherweise verzögerte Bestellungen',
  possibleDelayedMarketOrders: 'Möglicherweise verzögerte Bestellungen',
  mk_possible_delayed_market_orders_info_on_click:
    'Diese Bestellungen sind aktiv, wurden vor mehr als 27 Tagen getätigt und sind noch nicht beim Händler eingetroffen.',
  delayed_cars: 'Verzögerte Fahrzeuge',
  delayedCars: 'Verzögerte Fahrzeuge',
  mk_delayed_cars_info_on_click:
    'Diese Bestellungen sind aktiv, wurden vor mehr als 30 Tagen getätigt und noch nicht geliefert.',
  total_active_orders: 'Aktive Bestellungen',
  totalActiveOrders: 'Aktive Bestellungen',
  mk_total_active_orders_info_on_click: 'Noch nicht gelieferte Bestellungen',
  welcome_email_sent: 'Gesendete Willkommens-Nachrichten',
  welcomeEmailSent: 'Gesendete Willkommens-Nachrichten',
  mk_welcome_email_sent:
    'Verhältnis zwischen aktiven Bestellungen mit versendeter Willkommens-Nachricht und Gesamtanzahl',
  pending_deliveries: 'Ausstehende Lieferungen',
  pendingDeliveries: 'Ausstehende Lieferungen',
  mk_pending_deliveries_info_on_click: 'Bestellungen, die beim Händler auf AaK warten',
  cm_active: 'In diesem Tool aktive CUPRA Masters',
  cmActives: 'In diesem Tool aktive CUPRA Masters',
  mk_cm_active:
    'CUPRA Masters, die in der letzten Woche in das Tool eingestiegen sind, gelten als aktiv.',
  legend_title: 'Informationen über deine möglichen nächsten Schritte',
  send_notification_legend:
    'Nachricht „Dein Fahrzeug ist beim Händler eingetroffen“ an den Kunden senden',
  send_welcome_legend: 'Willkommens-Nachricht an den Kunden senden',
  send_reminder_legend: 'Den Kunden an die Willkommens-Nachricht erinnern',
  inform_car_arrival_legend:
    'Das System über eine beim Händler eingegangene Bestellung informieren',
  unread_notifications_legend: 'Direktzugriff auf ungelesene Nachrichten des Importeurs',
  reregister_legend: 'Eine stornierte Bestellung einem neuen Kunden zuordnen. Erneuern.',
  send_welcome_email_tooltip: 'Willkommens-Nachricht senden',
  send_reminder_tooltip: 'Erinnerung senden',
  send_push_notification_tooltip: 'Push-Benachrichtigung senden',
  inform_car_arrival_tooltip: 'Ankunft des Fahrzeugs beim Händler bekanntgeben',
  reregister_this_order_tooltip: 'Diese Bestellung erneuern',
  log_out: 'Abmelden',
  register_order: 'Neue Bestellung aufgeben',
  download_excel: 'Excel herunterladen',
  send_welcome_btn: 'Willkommens-Nachricht senden',
  send_reminder_btn: 'Erinnerung senden',
  send_notification_btn: 'Benachrichtigung senden',
  inform_car_arrival_btn: 'Information senden',
  show_results_btn: 'Ergebnisse anzeigen',
  cancel_btn: 'Stornieren',
  confirm_btn: 'Bestätigen',
  submit_registration_btn: 'Vorgang abschließen',
  print_btn: 'PDF drucken',
  clear_btn: 'LÖSCHEN',
  search_text: 'Tracking-ID, Kommissionsnummer, VIN oder E-Mail',
  trackline_tab_title: 'Tracking-Verlauf',
  PRE_A500_short: 'Bestellung erhalten',
  PRODUCTION_STARTS_short: 'Produktion gestartet',
  PRODUCTION_ENDS_short: 'Produktion abgeschlossen',
  FACTORY_DEPART_short: 'Ausgang Werk',
  COMPOUND_ARRIVAL_short: 'Eingang Lagerplatz',
  COMPOUND_DEPART_short: 'Ausgang Lagerplatz',
  DEALER_ARRIVAL_short: 'Eingang Händler',
  AAK_short: 'Auslieferung an Kunde (AaK)',
  PRE_A500: 'Bestellung erhalten',
  PRODUCTION_STARTS: 'Produktion gestartet',
  PRODUCTION_ENDS: 'Produktion abgeschlossen',
  FACTORY_DEPART: 'Fahrzeug verlässt das Werk',
  COMPOUND_ARRIVAL: 'Fahrzeug erreicht den Lagerplatz',
  COMPOUND_DEPART: 'Fahrzeug verlässt den Lagerplatz',
  DEALER_ARRIVAL: 'Fahrzeug trifft beim Händler ein',
  AAK: 'Auslieferung an Kunde (AaK)',
  filter_tab_title: 'Filter',
  pending_actions: 'Ausstehende Aktionen',
  order_date_range: 'Zeitraum Bestelldatum',
  delivery_date_range: 'Zeitraum Lieferdatum',
  active_orders: 'Aktive Bestellungen',
  order_history: 'Bestellhistorie',
  tracking_id: 'Tracking-ID',
  status: 'Status',
  model: 'Modell',
  last_status_change: 'Letzte Statusänderung',
  order_date: 'Bestelldatum',
  cm_code: 'CUPRA Master Code',
  dealer_code_short: 'Händler',
  delivery_date: 'Lieferdatum',
  delivered_on_time: 'Pünktliche Lieferung',
  yes: 'Ja',
  no: 'Nein',
  days_until_delivery: 'Tage bis zur Lieferung',
  back: 'zurück',
  commission_nr: 'Kommissions-Nr.',
  tmaimg: 'Modellcode',
  engine: 'Motor',
  trim: 'Ausstattungsvariante',
  color: 'Farbe',
  prs: 'Optionen',
  client_tracking_view: 'Kunden-Tracking-Ansicht',
  orderReceived: 'Bestellung erhalten',
  production: 'Produktionsprozess',
  dealer: 'Ankunft beim Händler',
  delivered: 'Umfrage',
  pending: 'Ausstehend',
  sent_notifications_list: 'Gesendete Benachrichtigungen',
  market_notifications_list: 'Importeurs-Benachrichtigungen',
  completed_orders: 'Abgeschlossene Bestellungen',
  register_title: 'Bestellung aufgeben',
  register_explanation:
    'Nach Aufgabe dieser Bestellung wird die Bestellung dir und deinem Händler zugeordnet. Ab diesem Zeitpunkt kannst du die Willkommens-Nachricht an den Kunden senden.',
  welcome_mail_title: 'Willkommens-Nachricht senden',
  client_mail_field: 'E-Mail-Adresse des Kunden',
  client_mail_placeholder: 'beispiel@beispiel.at',
  reminder_title: 'Erinnerung an die Willkommens-Nachricht senden',
  send_arrival_notification_title: 'Nachricht „Fahrzeug beim Händler eingetroffen“ senden',
  inform_car_arrival_title: 'Dieses Fahrzeug ist beim Händler eingetroffen!',
  inform_car_arrival_explanation:
    'Möglicherweise erfährst du als erster vom Eintreffen des Fahrzeugs. Bitte informiere uns darüber.<br> Bestätigst du, dass das Fahrzeug mit der Tracking-ID %trackingId% beim Händler eingetroffen ist?',
  welcome_mail_confirmation_title: 'Die Willkommens-Nachricht wurde an den Kunden versendet',
  welcome_mail_confirmation_explanation:
    'Wenn dein Kunde der Zusendung von E-Mails zugestimmt hat, wird er per E-Mail benachrichtigt. Um zu überprüfen, ob der Kunde in das Tracking-Tool eingestiegen ist, musst du auf Bestelldetails klicken.',
  order_status: 'Bestellstatus',
  ok_btn: 'OK',
  subject: 'Betreff',
  body: 'Text',
  purchaseDate: 'Bestelldatum',
  CMCode: 'CUPRA Master Code',
  dealerCode: 'Händler',
  deliveryDate: 'Lieferdatum',
  deliveredOnTime: 'Pünktliche Lieferung',
  filters: 'Filter',
  models: 'Modell',
  trackingId: 'Tracking-ID',
  pr: 'PRs',
  delayed: 'Verzögerte Fahrzeuge',
  trackingDays: 'Tage bis zur Lieferung',
  lastStatusChange: 'Letzte Statusänderung',
  trackingStatus: 'Status',
  done: 'Erledigt',
  date: 'Datum',
  PR: 'Optionen',
  pendingActions: 'Ausstehende Aktionen',
  welcome_footer: '-',
  successful_generic_title: 'Erledigt!',
  successful_generic_text: '-',
  wrong_generic_title: 'Ups, etwas ist schief gegangen.',
  wrong_generic_text: 'Bitte versuche es nochmal. <br><br> Ein Serverfehler ist aufgetreten:<br>',
  activeOrders: 'Aktive Bestellungen',
  delayedOrders: 'Verzögerte Fahrzeuge',
  activeCM: 'Aktive CUPRA Master',
  clear: 'Löschen',
  de_AT: 'Deutsch (AT)',
  de_CH: 'Deutsch (CH)',
  de_DE: 'Deutsch (DE)',
  en_GB: 'English (GB)',
  es_ES: 'Español (ES)',
  fr_CH: 'Français (CH)',
  fr_FR: 'Français (FR)',
  it_CH: 'Italiano (CH)',
  it_IT: 'Italiano (IT)',
  nl_NL: 'Nederlands (NL)',
  close_btn: 'Schließen',
  reregistration_available: 'Wiederanmeldung möglich',
  pending_reminders: 'Ausstehende E-Mail Erinnerungen',
  CM_to_customer_sent_notifications_list:
    'Gesendete Nachrichten an den Kunden durch den CUPRA Master',
  your_notifications_to_CM: 'Deine Nachricht an den CUPRA Master',
  read_notification_title: 'Lese diese Nachricht',
  save_btn: 'Speichern',
  print_preview_title: 'Druckvorschau',
  preview_title: 'Vorschau',
  turn_your_device_to_landscape_mode: 'Drehe dein Gerät, um die Website im Querformat anzuzeigen.',
  dealer_code_input_explanation: 'Füge deine Händlernummer ein.',
  tracking_id_input_explanation:
    'Tracking-ID (bspw. 2366522192021) = Kommissionsnummer (236652) + Länderkennung (219) + Fahrzeugjahr (2021)',
  download_btn: 'Download',
  pending_reminder: 'Ausstehende E-Mail Erinnerungen',
  cm_pending_reminder_info_on_click: 'Erinnerungs-Mails an nicht registrierte Kunden.',
  sm_pending_reminder_info_on_click: 'Erinnerungs-Mails an nicht registrierte Kunden.',
  authenticate_as_sales_manager: 'Als Verkaufsleiter authentifizieren',
  authenticate_as_sales_manager_title: 'Als Verkaufsleiter authentifizieren',
  authenticate_as_sales_manager_explanation: '',
  assign_cupra_master_legend: 'Eine Bestellung einem CUPRA Master zuweisen',
  assign_cupra_master_title: 'Einen CUPRA Master zuweisen',
  assign_cupra_master_explanation: 'Eine Bestellung einem CUPRA Master zuweisen',
  assign_btn: 'Zuweisen',
  authenticate_btn: 'Authentifizieren',
  send_massive_notification_btn: 'Nachricht senden',
  send_massive_notification_title: 'Nachricht senden',
  send_massive_notification_explanation: 'Zu den ausgewählten Bestellungen',
  login_btn: 'Zugang',
  redirected_title: 'Bist du bereits im Händlerportal (GRP) eingeloggt?',
  redirected_body:
    'Bitte klicke auf  "Weiter". Andernfalls prüfe bitte, ob dein Browser das Pop-Up-Fenster blockiert.',
  preview_btn: 'Vorschau',
  reregister_explanation:
    'Eine stornierte Bestellung einem neuen Kunden zuordnen. Erneut erfassen.',
  reregister_title: 'Wiederanmeldung',
  welcomeMessageSent: 'Gesendete Welcome-E-Mails',
  pendingToDeliver: 'Offene zu liefernde Bestellungen',
  send_mk_notification_tooltip: 'Nachricht senden',
  download_excel_title: 'Liste downloaden',
  download_excel_explanation: 'Die Listenauswahl basiert auf deinen Filtern',
  selection_massive_this_page_part_1:
    'Alle <strong>%PAGE_LENGTH%</strong> Bestellungen auf dieser Seite sind ausgewählt.',
  selection_massive_this_page_part_2: 'Alle %ORDERS_TOTAL% Bestellungen auswählen',
  selection_massive_all_pages_part_1: 'Alle %ORDERS_TOTAL% sind ausgewählt',
  selection_massive_all_pages_part_2: 'Auswahl löschen',
  send_notification_title: 'Nachricht senden',
  choose_cupra_master: 'Wähle einen CUPRA Master aus deinem Autohaus',
  pendingReminders: 'Ausstehende E-Mail Erinnerungen',
  send_mk_notification_legend: 'Nachrichten an den CUPRA Master einer Bestellung senden',
  mk_unread_notifications_legend:
    'Diese Nachrichten wurden noch nicht von dem zugehörigen CUPRA Master gelesen',
  turn_your_device_to_portrait_mode: 'Drehe dein Gerät, um die Website im Hochformat anzuzeigen',
  more_info: 'Mehr Informationen',
  apply_filters_mobile_btn: 'Filter anwenden',
  cmName: 'CUPRA Master',
  logout: 'Logout',
  sort_by: 'Sortieren nach',
  order_details: 'Bestelldetails',
  dealer_or_importer_code: 'Händlernummer',
  not_found: 'Nicht gefunden',
  searching: 'Suche...',
  pendingManualReminder: 'Ausstehende E-Mail Erinnerungen',
  unassigned_seller: 'Nicht zugewiesener CUPRA Master',
  unassignedSeller: 'Nicht zugewiesener CUPRA Master',
  countryCode: 'ISO-Ländercode',
  vin: 'VIN',
  hq_my_tasks_title: '-',
  show_hq_summary_btn: '-',
  hide_hq_summary_btn: '-',
  hq_possible_delayed_market_orders_info_on_click: '-',
  hq_delayed_cars_info_on_click: '-',
  hq_unread_notifications_info_on_click: '-',
  hq_pending_actions: '-',
  country: '-',
  email: 'E-Mail',
  search_long_explanation: 'Tracking-ID, Kommissionsnummer, VIN oder E-Mail',
  mk_notifications_to_CM: '-',
  possibleDelayNotDelayed: '',
  tracking_tab: '',
  search: '',
  time_period: '',
  time_week: '',
  time_ytd: '',
  time_month: '',
  time_from: '',
  time_until: '',
}
