export default {
flip_btn: "Switch",
compare_btn: "Compare",
show_all_filters_btn: "More Filters",
hide_all_filters_btn: "Hide More Filters",
toggle_filters_btn: "Toggle Filters",
country: "Country",
timePeriod: "Period",
model: "Model",
tmaimg: "TMAIMG",
phev: "PHEV",
pr: "PR",
color: "Color",
dealer: "Dealer",
cm: "Cupra Master",
active: "Active Orders",
CM: "cupra masters",
orders: "orders",
"%": "%",
ab_card_title: "Factory Order Entry (AB)",
fu_card_title: "Factory Order Generation (FU)",
ae_card_title: "Incoming Orders (AE)",
aak_card_title: "Delivery Orders (AAK)",
cm_with_orders_card_title: "CupraMasters with orders registered",
tt_registered_orders_card_title: "Tracking Tool registered orders",
welcome_email_sent_orders_card_title: "Orders with welcome email sent",
engaged_customer_orders_card_title: "Orders with customers engaged to TT",
ab_card_primary: "Prio",
ab_card_all_cupra: "All Cupra",
ab_card_share: "Share",
ab_card_vs_last_period: "vs Last Period",
ab_card_vs_last_year: "vs Last Year",
ab_card_primary_evol: "Prio",
ab_card_all_cupra_evol: "All Cupra",
fu_card_primary: "Prio",
fu_card_all_cupra: "All Cupra",
fu_card_share: "Share",
fu_card_vs_last_period: "vs Last Period",
fu_card_vs_last_year: "vs Last Year",
fu_card_primary_evol: "Prio",
fu_card_all_cupra_evol: "All Cupra",
ae_card_primary: "Prio",
ae_card_all_cupra: "All Cupra",
ae_card_share: "Share",
ae_card_vs_last_period: "vs Last Period",
ae_card_vs_last_year: "vs Last Year",
ae_card_primary_evol: "Prio",
ae_card_all_cupra_evol: "All Cupra",
aak_card_primary: "Prio",
aak_card_all_cupra: "All Cupra",
aak_card_share: "Share",
aak_card_vs_last_period: "vs Last Period",
aak_card_vs_last_year: "vs Last Year",
aak_card_primary_evol: "Prio",
aak_card_all_cupra_evol: "All Cupra",
cm_with_orders_card_primary: "-",
cm_with_orders_card_vs_last_period: "vs Last Period",
cm_with_orders_card_vs_last_year: "vs Last Year",
cm_with_orders_card_primary_evol: "Prio",
tt_registered_orders_card_primary: "-",
tt_registered_orders_card_vs_last_period: "vs Last Period",
tt_registered_orders_card_vs_last_year: "vs Last Year",
tt_registered_orders_card_vs_ae: "vs AEs",
tt_registered_orders_card_primary_evol: "Prio",
welcome_email_sent_orders_card_primary: "-",
welcome_email_sent_orders_card_vs_last_period: "vs Last Period",
welcome_email_sent_orders_card_vs_last_year: "vs Last Year",
welcome_email_sent_orders_card_vs_tt_orders: "vs Tracked Orders",
welcome_email_sent_orders_card_primary_evol: "Prio",
engaged_customer_orders_card_primary: "-",
engaged_customer_orders_card_vs_last_period: "vs Last Period",
engaged_customer_orders_card_vs_last_year: "vs Last Year",
engaged_customer_orders_card_vs_welcom_email_sent: "vs Welcome Emails Sent",
engaged_customer_orders_card_primary_evol: "Prio",
reporting_tab_global_info_title: "",
reporting_tab_global_info_body: "",
reporting_tab: "Reporting",
time_period: "Period",
time_week: "Week",
time_ytd: "YTD",
time_month: "Month",
time_from: "From",
time_until: "Until",
reset_btn: "Reset",
active_filters: "Active Filters",
motor: "Motor"
}