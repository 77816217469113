import { getSummary } from '@/api'
function injectData (data) {
  if (!data.buckets || !data.kpis) return
  data.buckets.forEach((item) => {
    item.disabled = item.total === 0
    item.name = item.trackingStatus + '_short'
    item.filter = { trackingStatus: item.id}
  })
  let DEALER_ARRIVAL_id = data.buckets.find(t => { return t.trackingStatus === "DEALER_ARRIVAL" }).id
  data.kpis.forEach((item) => {
    item.id = item.kpiId
    item.label = item.id
    switch (item.id) {
      case 'pendingWelcome':
        item.tasks = 1
        item.icon = "mail"
        item.explanation = {
          CM: "cm_pending_welcome_info_on_click",
          SM: "sm_pending_welcome_info_on_click"
        }
        item.filter = {"sendNotificationAvailable": "welcome_message"}
        break
      case 'pendingManualReminder':
        item.tasks = 5
        item.icon = "reminder"
        item.explanation = {
          CM: "cm_pending_reminder_info_on_click",
          SM: "sm_pending_reminder_info_on_click",
        }
        item.filter = {"sendNotificationAvailable": "manual_reminder"}
        break
      case 'unengagedClients':
        // item.tasks = 5
        item.funnel = 3
        item.over = true
        // item.icon = "reminder"
        item.explanation = {
          CM: "cm_unengaged_clients_info_on_click",
          SM: "sm_unengaged_clients_info_on_click",
          MK: "mk_unengaged_clients_info_on_click",
          HQ: "mk_unengaged_clients_info_on_click"
        }
        item.filter = {"customerEngaged": false}
        break
      case 'pushableNotifications':
        item.tasks = 2
        item.icon = "paper-plane"
        item.filter = {"sendNotificationAvailable": "cm_client_message"}
        item.explanation = {
          CM: "cm_pushable_notifications_info_on_click",
          SM: "sm_pushable_notifications_info_on_click"
        }
        break
      case 'unreadNotifications':
        item.tasks = 3
        item.marketTasks = 2
        item.icon = "bell"
        item.filter = {"hasUnreadManualNotifications": true}
        item.explanation = {
          CM: "cm_unread_notifications_info_on_click",
          SM: "sm_unread_notifications_info_on_click",
          MK: "mk_unread_notifications_info_on_click",
          HQ: "mk_unread_notifications_info_on_click"
        }
        break
      case 'possibleDelayedOrders':
        item.tasks = 4
        item.icon = "alarm"
        item.filter = {"possibleDelay": true}
        item.explanation = {
          CM: "cm_possible_delayed_orders_info_on_click",
          SM: "sm_possible_delayed_orders_info_on_click"
        }
      break
      case 'possibleDelayNotDelayed':
        item.label = "possibleDelayedMarketOrders"
        item.marketTasks = 1
        item.icon = "alarm"
        item.filter = {"possibleDelayNotDelayed": true}
        item.explanation = {
          MK: "mk_possible_delayed_market_orders_info_on_click",
          HQ: "hq_possible_delayed_market_orders_info_on_click"
        }
        break
      case 'delayedCars':
        item.marketTasks = 3
        item.icon = "alarm-red"
        item.filter = {"delayed": true}
         item.explanation = {
          MK: "mk_delayed_cars_info_on_click",
          HQ: "hq_delayed_cars_info_on_click"
        }
        break
      case "activeOrders":
        item.funnel = 1
        item.filter = {"active": true}
        item.explanation = {
          MK: "mk_total_active_orders_info_on_click",
          HQ: "mk_total_active_orders_info_on_click"
        }
        break
      case "welcomeMessageSent":
        item.funnel = 2
        item.over = true
        item.explanation = {
          MK: "mk_welcome_email_sent",
          HQ: "mk_welcome_email_sent"
        }
        item.filter = {"sentNotification": "welcome_message"}
        break
      case "pendingToDeliver":
        item.funnel = 4
        item.over = true
        item.explanation = {
          MK: "mk_pending_deliveries_info_on_click",
          HQ: "mk_pending_deliveries_info_on_click"
        }
        item.filter = { "trackingStatus": DEALER_ARRIVAL_id}
        break
      case "activeCM":
        item.funnel = 5
        item.explanation = {
          MK: "mk_cm_active",
          HQ: "mk_cm_active"
        }
        break
    }
  })
}
export default {
  namespaced: true,
  state: {
    data: [],
    activeFilterShortcut: null,
    loaded: false
  },
  mutations: {
    setData (state, data) {
      state.loaded = true
      if (!data.kpis) {
        data.tasks = []
        data.marketTasks = []
        data.funnel = []
      } else {
        data.tasks = data.kpis.filter(t => t && t.tasks)
        data.tasks.sort((a, b) => a.tasks - b.tasks)
        data.marketTasks = data.kpis.filter(t => t && t.marketTasks)
        data.marketTasks.sort((a, b) => a.marketTasks - b.marketTasks)
        data.funnel = data.kpis.filter(t => t && t.funnel)
        data.funnel.sort((a, b) => a.funnel - b.funnel)
      }
      state.data = data
    },
    setActiveFilterShortcut (state, data) {
      state.activeFilterShortcut = data
    }
  },
  actions: {
    load ({state, getters, commit, rootState, rootGetters}, params) {
      state.loaded = false
      getSummary().then((response) => {
        var data = response.data
        injectData(data)
        commit('setData', data)
      })
    },
    resetShortcut({dispatch, state, getters, commit, rootState, rootGetters},params) {
      commit('setActiveFilterShortcut', null)
    },
    activateShortcut({dispatch, state, getters, commit, rootState, rootGetters}, params) {
      // unset
      if (params.task === null || (state.activeFilterShortcut && state.activeFilterShortcut.id && params.task.id === state.activeFilterShortcut.id)) {
        commit('setActiveFilterShortcut', null)
        return dispatch('orders/change', params,{root: true})
      } else { // set
        commit('setActiveFilterShortcut', params.task)
        let extendedParams = params
        extendedParams.filters =  {...extendedParams.filters, ...params.task.filter}
        return dispatch('orders/change', extendedParams, {root: true})
      }
    }
  }
}


