<template>
  <div class="legend">
    <div :class="['grid', userRole]">
      <div class="action-btn" v-if="['SM', 'CM'].includes(userRole)">
        <div class="icon mail"></div>
        <div class="action legend">{{$t('send_welcome_legend')}}</div>
      </div>
      <div class="action-btn" v-if="['SM', 'CM'].includes(userRole)">
        <div class="icon reminder"></div>
        <div class="action legend">{{$t('send_reminder_legend')}}</div>
      </div>
      <div class="action-btn" v-if="['SM', 'CM'].includes(userRole)">
        <div class="icon paper-plane"></div>
        <div class="action legend">{{$t('send_notification_legend')}}</div>
      </div>
      <div class="action-btn" v-if="['SM', 'CM'].includes(userRole)">
        <div class="icon car"></div>
        <div class="action legend">{{$t('inform_car_arrival_legend')}}</div>
      </div>
      <div class="action-btn" v-if="['SM', 'CM'].includes(userRole)">
        <div class="icon bell"></div>
        <div class="action legend">{{$t('unread_notifications_legend')}}</div>
      </div>
       <div class="action-btn" v-if="['MK', 'HQ'].includes(userRole)">
        <div class="icon bell"></div>
        <div class="action legend">{{$t('mk_unread_notifications_legend')}}</div>
      </div>
      <div class="action-btn" v-if="['SM', 'CM'].includes(userRole)">
        <div class="icon re-register"></div>
        <div class="action legend">{{$t('reregister_legend')}}</div>
      </div>
      <div class="action-btn" v-if="userRole==='SM'">
        <div class="icon assign-cm"></div>
        <div class="action legend">{{$t('assign_cupra_master_legend')}}</div>
      </div>
      <div class="action-btn" v-if="userRole==='MK'">
        <div class="icon paper-plane"></div>
        <div class="action legend">{{$t('send_mk_notification_legend')}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Legend',
  props: {},
  computed: {
    userRole() {
      return this.$store.state.user.userData.role
    },
  },
}
</script>

<style scoped lang="stylus">
.grid
  column-gap: 10px
  // $gap = 20px
  // display: flex
  // flex-wrap: wrap
  // height: 100%
  // margin-left: -($gap)
  // margin-right: -($gap)
  display: grid
  grid-template-columns: 400px 400px
  grid-template-rows: 80px
  row-gap: 15px

  &.HQ
    grid-template-columns: 800px

  .mobile &
    grid-template-columns: 290px

  .action-btn
    align-items: center
    display: flex

    // flex: 0 0 "calc(50% - (%s * 2))" % $gap
    // margin-left: $gap
    // margin-right: $gap
    // margin-bottom $gap
    .icon
      background-size: 50% 50% !important
      border: 1px solid $cyprus
      margin-right: 20px

      &.bell
        background-size: 45% 45% !important
        border-color: $paarl
        m-icon("bell-paarl", 60)

      &.re-register
        m-icon("re-register-cyprus", 60)

      &.mail
        background-color: $cyprus
        m-icon("mail-white", 60)

      &.paper-plane
        m-icon("paper-plane-cyprus", 60)

      &.reminder
        background-size: 55% 55% !important
        m-icon("reminder-cyprus", 60)

      &.car
        m-icon("car-cyprus", 60)

      &.assign-cm
        background-size: 54% 56% !important
        m-icon("assign-cm-cyprus", 60)

    .action
      flex: 1 1 0%
      font-weight: $fw-light
      m-font-size(16, 26)
</style>
