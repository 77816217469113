export default {
  methods: {
    fieldFormat(order, col) {
      if (['purchaseDate', 'deliveryDate'].includes(col)) {
        return this.moment(order[col]).format('ll')
      } else if (['lastStatusChange'].includes(col)) {
        let dif = this.moment().diff(this.moment(order[col]), 'days')
        if (dif > 14) return this.moment(order[col]).format('ll')
        return this.moment(order[col]).fromNow()
      } else if (['delayedIconXOrderHistory', 'anyDelay'].includes(col)) {
        return ''
      } else if (col === 'trackingStatus') {
        return this.$t(order[col] + '_short')
      } else if (col === 'delayed') {
        return order[col] ? this.$t('yes') : this.$t('no')
      } else if (col === 'country') {
        return order[col].id
      }
      return order[col]
    },
    goTo(name, trackingId) {
      this.$router.push({ name: name, params: { id: trackingId } })
    },
    doAction(action, order) {
      action.action.call(this, order)
    },
  },
}
