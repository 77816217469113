<template>
  <div class="read">
    <div class="subject">{{ data.messageSubject }}</div>
    <div class="text body" v-html="data.messageContent" />
  </div>
</template>

<script>
//  {
//   "notificationId": 3,
//   "type": "market_message",
//   "dateTime": "2020-04-05T14:30Z",
//   "status": "FACTORY_DEPART",
//   "subject": "Subject of the message",
//   "body": "Message for the user",
//   "viewed": false
// }
import modalContentMixin from '@/components/modals/modalContentMixin'
export default {
  name: 'Read',
  mixins: [modalContentMixin],
  props: ['data'],
  computed: {
    contentValue() {
      return true
    },
  },
}
</script>

<style scoped lang="stylus">
.text
  margin-bottom: 20px
</style>