import axios from 'axios'
import store from '@/store'

const qs = require('qs')

axios.defaults.withCredentials = true

let myAxios = axios.create({
  paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
})

axios.interceptors.response.use(config => {
  if (config.status === 401) {
    // eslint-disable-next-line no-undef
    if (router.current !== '/login') router.push('/login')
  }
  return config
})
axios.interceptors.request.use(config => {
  // console.log('static data' + process.env.VUE_APP_STATIC_DATA )
  // console.log('url: '+ config.url)
  if (process.env.VUE_APP_STATIC_DATA === 'true' && config.method === 'get') {
    //
    config.url = './data/' + config.url.split(process.env.VUE_APP_API_URL).pop() + '.json'
    return new Promise(res => {
      setTimeout(() => res(config), 500)
    })
  }
  if (process.env.VUE_APP_STATIC_DATA === 'true' && config.method === 'post') {
    //
    return new Promise(res => {
      setTimeout(res, 1000)
    })
  }
  // console.log('url: '+ config.url)
  return config
})
myAxios.interceptors.request.use(config => {
  // console.log('static data' + process.env.VUE_APP_STATIC_DATA )
  // console.log('url: '+ config.url)
  if (process.env.VUE_APP_STATIC_DATA === 'true' && config.method === 'get') {
    //
    config.url = './data/' + config.url.split(process.env.VUE_APP_API_URL).pop() + '.json'
    return new Promise(res => {
      setTimeout(() => res(config), 1000)
    })
  }
  if (process.env.VUE_APP_STATIC_DATA === 'true' && config.method === 'post') {
    //
    return new Promise(res => {
      setTimeout(res, 1000)
    })
  }
  // console.log('url: '+ config.url)
  return config
})

export function getSummary() {
  return axios.get(process.env.VUE_APP_API_URL + 'order/getSummary').then(response => {
    return response
  })
}
export function logout() {
  return axios.get(process.env.VUE_APP_LOGIN_URL + `/globalFastlane/rs/saml/logout`)
}
export function getUserInfo() {
  return axios
    .get(process.env.VUE_APP_API_URL + 'user/getInfo')
    .then(response => {
      // delete response.data.countryId
      // response.data.lanCode="en-GB"
      return response
    })
    .catch(e => {
      if (e.response.status === 401) {
        // window.open(process.env.VUE_APP_LOGIN_URL+e.response.data.Location)
        return e.response
      } else if (e.response.status === 403) {
        return e.response
      } else {
        console.log('should never happen (1)')
        throw e
      }
    })
}
export function setAccessInfo(params) {
  // /user/setAccessInfo
  console.log('setAccessInfo', params)
  return axios
    .post(process.env.VUE_APP_API_URL + 'user/setAccessInfo', null, { params })
    .then(response => {
      return response
    })
    .then(() => {
      return store.dispatch('user/loadUserInfo')
    })
    .catch(e => {
      if (process.env.VUE_APP_STATIC_DATA === 'false') {
        throw e
      } // else do nothing
    })
}

export function setUserLan(lang) {
  console.log('setUserLan', lang)
  return axios
    .post(process.env.VUE_APP_API_URL + 'user/setLan', null, { params: { lan: lang } })
    .then(response => {
      return response
    })
    .then(() => {
      store.dispatch('user/loadUserInfo')
    })
    .catch(() => {
      console.log('should only happen on static though...')
      store.dispatch('i18n/setLanguage', lang)
    })
}

export function getList({ filters, pagination, sort }) {
  let keysImplemented = [
    'active',
    'country',
    'trackingStatus',
    'delayed',
    'possibleDelay',
    'possibleDelayNotDelayed',
    'reRegistrationAvailable',
    'unassignedSeller',
    'sendNotificationAvailable',
    'sentNotification',
    'promoteTrackingStatusAvailable',
    'customerEngaged',
    'hasUnreadManualNotifications',
    'deliveryStartDate',
    'deliveryEndDate',
    'purchaseStartDate',
    'purchaseEndDate',
    'model',
    'tmaimg',
    'engine',
    'trim',
    'pr',
    'color',
    'dealerCode',
    'cmName',
  ]
  let implementedFilters = Object.keys(filters).reduce(function (newObj, key) {
    if (keysImplemented.indexOf(key) !== -1) {
      newObj[key] = filters[key]
    }
    return newObj
  }, {})
  console.log(filters + '\n' + implementedFilters)

  let params = {
    ...implementedFilters,
    pagSize: pagination.pagSize,
    pagNumber: pagination.pag,
    ...sort,
  }

  return myAxios.get(process.env.VUE_APP_API_URL + 'order/getList', { params }).then(response => {
    return response
  })
}
export function getOrder({ value }) {
  return axios
    .get(process.env.VUE_APP_API_URL + 'order/getOrder', { params: { trackingId: value } })
    .then(response => {
      return response
    })
}
export function searchOrders({ value }) {
  return axios
    .get(process.env.VUE_APP_API_URL + 'order/searchOrders', { params: { value: value } })
    .then(response => {
      return response
    })
  // MENTRE NO HI HA searchOrders, simulem així
  // .get(process.env.VUE_APP_API_URL + 'order/getOrder', {params: {value: value}})
  // .then(response => {
  //   let aux = {...response}
  //   aux.data = [response.data, {...response.data}]
  //   aux.data[1].trackingId = String(parseInt(aux.data[1].trackingId) + 1)
  //   return aux
  // })
}

// getCountries, getModels,getEngines,getTrims, getTmaimgs,getColors, getPRs
export function getCountries() {
  return axios
    .get(process.env.VUE_APP_API_URL + 'countryList', { params: { brand: 'cupra' } })
    .then(response => {
      return response //.data
    })
}
export function getModels() {
  return axios
    .get(process.env.VUE_APP_API_URL + 'modelList', { params: { brand: 'cupra' } })
    .then(response => {
      return response //.data
    })
}
export function getEngines() {
  return axios
    .get(process.env.VUE_APP_API_URL + 'engineList', { params: { brand: 'cupra' } })
    .then(response => {
      return response //.data
    })
}
export function getTrims() {
  return axios
    .get(process.env.VUE_APP_API_URL + 'trimList', { params: { brand: 'cupra' } })
    .then(response => {
      return response //.data
    })
}
export function getTmaimgs() {
  return axios
    .get(process.env.VUE_APP_API_URL + 'tmaimgList', { params: { brand: 'cupra' } })
    .then(response => {
      return response //.data
    })
}
export function getColors() {
  return axios
    .get(process.env.VUE_APP_API_URL + 'colorList', { params: { brand: 'cupra' } })
    .then(response => {
      return response //.data
    })
}
export function getPRs() {
  return axios
    .get(process.env.VUE_APP_API_URL + 'prList', { params: { brand: 'cupra' } }) // ?brand=cupra
    .then(response => {
      return response //.data
    })
}

export function getDealers() {
  return axios
    .get(process.env.VUE_APP_API_URL + 'dealerList', { params: { brand: 'cupra' } }) // ?brand=cupra
    .then(response => {
      return response //.data
    })
}
export function getCMs() {
  return axios
    .get(process.env.VUE_APP_API_URL + 'sellerList', { params: { brand: 'cupra' } }) // ?brand=cupra
    .then(response => {
      return response //.data
    })
}

export function postAddBooking(params) {
  // /order/addBooking
  console.log('postAddBookingParams', params)
  return axios
    .post(process.env.VUE_APP_API_URL + 'order/addBooking', null, { params })
    .then(response => {
      return response
    })
}

export function updatePurchase(params) {
  // /order/updatePurchase
  console.log('updatePurchase', params)
  return axios
    .post(process.env.VUE_APP_API_URL + 'order/updatePurchase', null, { params })
    .then(response => {
      return response
    })
}

export function assignSeller(params) {
  console.log('assignSellerParams', params)
  return axios
    .post(process.env.VUE_APP_API_URL + 'order/assignSeller', null, { params })
    .then(response => {
      return response
    })
}

export function postPromoteTrackingStatus(params) {
  // /order/promote
  console.log('postPromoteTrackingStatus', params)
  return axios
    .post(process.env.VUE_APP_API_URL + 'order/promote', null, { params })
    .then(response => {
      return response
    })
}

export function getNotificationInfo(params) {
  console.log('getNotificationInfo', params)
  return axios
    .get(process.env.VUE_APP_API_URL + 'notification/getInfo', { params })
    .then(response => {
      return response
    })
}

export function sendNotification(payload) {
  // /notification/send
  let encoded = window.btoa(encodeURIComponent(JSON.stringify(payload)))
  console.log('sendNotification', encoded) // params = {notificationConfigId:1, trackingId: 123, }
  console.log('sendNotification', window.atob(encoded))
  return axios
    .post(process.env.VUE_APP_API_URL + 'notification/send', encoded, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(response => {
      return response
    })
}

export function sendMassiveNotification(payload, massiveParams) {
  // /notification/send
  let encoded = window.btoa(encodeURIComponent(JSON.stringify(payload)))
  console.log('sendNotification', encoded) // params = {notificationConfigId:1, trackingId: 123, }
  console.log('sendNotification', window.atob(encoded))
  return myAxios
    .post(process.env.VUE_APP_API_URL + 'notification/groupSend', encoded, {
      params: massiveParams,
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(response => {
      return response
    })
}

export function setViewed(params) {
  // /notification/setViewed
  console.log('setViewed', params) // params = {notificationId: 123}
  return axios
    .post(process.env.VUE_APP_API_URL + 'notification/setViewed', null, { params })
    .then(response => {
      return response
    })
}

export function exportList(params) {
  // return myAxios
  //   .get(process.env.VUE_APP_API_URL + 'order/exportList', {params})
  //   .then(response => {
  //     return response
  //   })
  console.log(
    process.env.VUE_APP_API_URL +
      'order/exportList?' +
      qs.stringify(params, { arrayFormat: 'repeat' })
  )
  window.location =
    process.env.VUE_APP_API_URL +
    'order/exportList?' +
    qs.stringify(params, { arrayFormat: 'repeat' })
}
