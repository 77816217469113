<template>
  <div v-click-outside="{events: ['mousedown'],handler:onClickOutside}" :class="['input-with-label', labelUp ? 'label-up': 'label-down']" @click="toggle">
    <!--v-click-outside="{handler: close,events: ['mouseup','touchend']}"-->
    <div :class="['label']">{{ label.charAt(0).toUpperCase() + label.slice(1) }}</div>
    <div :class="['icon', component, isOpen ? 'up': 'down', value ? 'filled':'void']" />
    <div v-if="value" class="value">
      {{ showValues }}
    </div>
    <component
      :is="component"
      :ref="component"
      v-model="internalValue"
      class="component"
      v-bind="$props"
      :is-open="isOpen"
      @close="close"
    />
  </div>
</template>

<script>
import SelectComp from './SelectComp.vue'
import InputComp from './InputComp.vue'
import CalendarComp from './CalendarComp.vue'
import PeriodComp from './PeriodComp.vue'

import { getDefaultLabel } from '../reporting_lite_subfolder/utils/DatesUtils'

export default {
  name: 'InputWithLabel',
  components: {
    SelectComp,
    InputComp,
    CalendarComp,
    PeriodComp,
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: { required: true },
    label: { required: true },
    explanation: { required: false },
    component: { required: true },
    configs: { required: false },
  },
  data() {
    return {
      isOpen: false,
    }
  },
  computed: {
    labelUp() {
      if (this.isOpen) return true
      else if (this.value != [] && this.value != null) return true
      return false
    },
    internalValue: {
      get() {
        return this.value
      },
      set(newValue) {
        this.$emit('change', newValue)
      },
    },
    showValues() {
      this.$store.state.i18n.lang
      if (this.component === 'CalendarComp') {
        return (
          this.moment(this.internalValue.start).format('DD/MM/YY') +
          ' - ' +
          this.moment(this.internalValue.end).format('DD/MM/YY')
        )
      } else if (this.component === 'SelectComp') {
        if (this.configs.multiple && this.internalValue) {
          return this.internalValue
            .map(
              v =>
                this.configs.items.find(d => d[this.configs.itemValue] === v)[this.configs.itemText]
            )
            .join(', ')
        } else if (this.internalValue != null) {
          return this.configs.items.find(d => d[this.configs.itemValue] === this.internalValue)[
            this.configs.itemText
          ]
        }
      } else if (this.component === 'InputComp' && !this.isOpen) {
        return this.internalValue
      } else if (this.component === 'PeriodComp') {
        //close filter after have value
        if (!this.internalValue.default) {
          this.close()
        }
        return getDefaultLabel(
          this.internalValue.periodType,
          this.internalValue.rangeObj.startDate,
          this.internalValue.rangeObj.endDate
        )
      }
      return null
    },
  },
  methods: {
    toggle(e) {
      //console.log('toggle ')
      if (e && e.target.closest('.vc-pane-container')) return
      if (e && e.target.classList.value === 'icon-close closed') return
      //evitem que el toggle de filter-container es tanqui quan el click detectat esta dins de period-dropdown
      //pick the closest element
      let periodDropdown = e.target.closest('#period-dropdown')
      if (
        e &&
        periodDropdown &&
        periodDropdown.contains(e.target) &&
        e.currentTarget.parentElement.className === 'filter-container'
      )
        return
      this.isOpen = !this.isOpen
    },
    close() {
      // console.log('close emitted')
      this.isOpen = false
    },
    onClickOutside(e) {
      // console.log('clickoutside', this._uid, e.target)
      if (!e.target.closest('.input-with-label')) {
        // console.log('actual outside')
        this.isOpen = false
      }
    },
  },
}
</script>
<style lang="stylus" scoped>
.input-with-label
  position: relative
  display: flex
  align-items: center
  min-height: 50px
  border-bottom: 1px solid $alto
  cursor: pointer

  .mobile &
    min-height: 60px

  &.label-up
    border-bottom: 1px solid rgba(#000, 1)

    .label
      position: absolute
      m-font-size(10, 16)
      top: -4px
      font-weight: $fw-medium

      .mobile &
        top: 4px

  &.label-down
    .label
      m-font-size(14, 20)
      top: 15px
      font-weight: $fw-light

  .label
    font-weight: $fw-light
    m-ellipsis(calc(100% - 35px))
    m-font-size(14, 20)
    position: absolute
    top: 15px
    z-index: 1
    transition: all 0.25s ease-in-out

    .mobile &
      top: 20px

  .icon
    position: absolute
    top: 15px
    right: 5px
    z-index: 1

    .mobile &
      top: 20px

    &.CalendarComp
      &.void
        m-icon("calendar-cyprus", 20)

      + .value
        top: 20px

        .mobile &
          top: 25px

    &.SelectComp,
    &.PeriodComp
      &.down
        background-size: 50% 50% !important
        m-icon("caret-down", 20)
        transition: transform 0.25s ease-in-out

      &.up
        background-size: 50% 50% !important
        m-icon("caret-down", 20)
        transform: rotate(180deg)
        animation: rotation 0.25s linear 0s 1

        @keyframes rotation
          from
            transform: rotate(0)

          to
            transform: rotate(180deg)

  .value
    font-weight: $fw-medium
    m-ellipsis(calc(100% - 35px))
    m-font-size(14, 20)
    position: absolute
    top: 20px
    z-index: 1
    width: 100%

    .mobile &
      top: 25px

  .component
    width: 100%

    &.calendar-comp
      margin-top: 50px

    &.select-comp
      width: 100%
      height: 49px

      .mobile &
        height: 60px

      &.open
        background-color: #fff

        .mobile &
          background-color: $wild-sand

      >>> .multiselect__select
        display: none

    >>> input
      border: none
      background-color: transparent
      font-weight: $fw-light
      m-font-size(14, 20)
      padding: 0
      width: 100%
      outline: none
</style>