export default {
    mounted() {
      const resizeObserver = new ResizeObserver(entries => {
        entries.forEach(entry => {
          // console.log("contentRect",entry.contentRect)
          this.size = {
            width: entry.contentRect.width,
            height: entry.contentRect.height,
          }
        })
      })
      resizeObserver.observe(this.$el)
      // this.size = {
      //   width: this.$el.clientWidth,
      //   height: this.$el.clientHeight,
      // }
    },
    data() {
      return {
        size: { width: 0, height: 0 },
      }
    },
  }
  