var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header-navigation"},[(_vm.isMobile && ['MK', 'HQ'].includes(_vm.userInfo.role) )?_c('div',{class:[
      'funnel-tab tab',
      { selected: _vm.$route.name === 'funnel' } ],on:{"click":function () {_vm.goTo('funnel')}}},[_vm._v(" "+_vm._s(_vm.$t("funnel_title"))+" ")]):_vm._e(),(_vm.isMobile)?_c('div',{class:[
      'my-tasks-tab tab',
      { selected: _vm.$route.name === 'tasks' } ],on:{"click":function () {_vm.goTo('tasks')}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.myTasksTitle))+" ")]):_vm._e(),(_vm.isMobile)?_c('div',{class:[
      'trackline-tab tab',
      { selected: _vm.$route.name === 'trackline' } ],on:{"click":function () {_vm.goTo('trackline')}}},[_vm._v(" "+_vm._s(_vm.$t("trackline_tab_title"))+" ")]):_vm._e(),_c('div',{class:[
      'active-orders-tab tab',
      { selected: _vm.$route.name === 'active' } ],on:{"click":function () {_vm.goTo('active')}}},[_vm._v(" "+_vm._s(_vm.$t("active_orders"))+" ("+_vm._s(_vm.totalActiveOrders)+") ")]),_c('div',{class:[
      'order-history-tab tab',
      { selected: _vm.$route.name === 'history' } ],on:{"click":function () {_vm.goTo('history'); }}},[_vm._v(" "+_vm._s(_vm.$t("order_history"))+" ")])])}
var staticRenderFns = []

export { render, staticRenderFns }