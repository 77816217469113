<template>
  <div class="trackline-view">
    <div class="total-orders">{$t('total_active_orders')}}: {{totalActiveOrders}}</div>
    <Trackline  tab="tracklineView"/>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import Trackline from '@/components/Trackline.vue'

export default {
  name: 'TracklineView',
  components: {
    Trackline,
  },
  computed: {
    totalActiveOrders() {
      return this.$store.state.summary.data.totalActiveOrders
    },
  },
}
</script>

<style scoped lang="stylus">
.trackline-view
  display: flex

  .mobile &
    flex-direction: column
    overflow-x: hidden
    overflow-y: auto
    padding: 15px 0

  .total-orders
    align-self: flex-end
    color: $dune
    m-font-size(13, 16)
    margin-bottom: 10px
    padding-right: 15px

  >>> .trackline
    .mobile &
      flex: 1 1 0%
      overflow-x: hidden
      overflow-y: auto
      padding-left: 35px
      padding-top: 0
</style>