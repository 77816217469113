<template>
  <modal
    class="my-modals"
    :scrollable="true"
    :width="myModalWidth"
    :height="modalsObj[name].height || defaultOpt.height"
    :name="name"
    @before-open="beforeOpen"
    @before-close="refresh"
  >
    <div v-if="status==='isLoading'" class="container loading"><Loader /></div>
    <div v-else class="container">
      <div class="icon close" @click="close(name)" />
      <div class="title">
        {{ $t(modalsObj[name][status + 'title'] || defaultOpt[status + 'title']) }}
      </div>
      <component
        :is="modalsObj[name].component"
        v-if="status===''"
        ref="modalBody"
        v-model="content"
        class="body"
        :class="name"
        :data="popupData"
      />
      <div v-else class="body" v-html="successfulOrWrongBodyMessage" />
      <div class="actions-section">
        <div class="btn btn--primary btn--ghost cancel-btn" @click="close(name)">
          <template v-if="status==='' && !['legend', 'preview', 'readNotification', 'reportingInfo'].includes(name)">{{ $t('cancel_btn') }} </template>
          <template v-else>{{ $t('ok_btn') }} </template>
        </div>
        <div v-if="existSpecificActions(name)" class="specific-actions">
          <div
            v-for="action in modalsObj[name]['otherActions']"
            :key="action.name"
            class="btn"
            :class="[action.name]"
            @click="()=>action.action($refs.modalBody.getValue())"
          >
            {{ $t(action.name) }}
          </div>
          <div
            v-for="action in modalsObj[name]['mainAction']"
            :key="action.name"
            class="btn"
            :class="[action.name, content === null ? 'disabled' : '']"
            @click="()=>action.action(content)"
          >
            {{ $t(action.name) }}
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
// import jsPDF from 'jspdf'
// import html2canvas from "html2canvas"

import {
  postAddBooking,
  updatePurchase,
  assignSeller,
  postPromoteTrackingStatus,
  sendNotification,
  sendMassiveNotification,
  exportList,
  setViewed,
} from '@/api'
import Loader from '@/components/Loader'
import Legend from '@/components/modals/Legend.vue'
import Register from '@/components/modals/Register.vue'
import ReRegister from '@/components/modals/ReRegister.vue'
import PromoteTrackingStatus from '@/components/modals/PromoteTrackingStatus.vue'
import Send from '@/components/modals/Send.vue'
import Download from '@/components/modals/Download.vue'
import Read from '@/components/modals/Read.vue'
import Print from '@/components/modals/Print.vue'
import AssignCM from '@/components/modals/AssignCM.vue'

import ReportingInfo from '@/reporting_lite_subfolder/components/modals/ReportingInfo.vue'

export default {
  name: 'MyModals',
  components: {
    Loader,
    Legend,
    Register,
    ReRegister,
    PromoteTrackingStatus,
    Send,
    Download,
    Read,
    Print,
    AssignCM,
    ReportingInfo,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      content: null,
      status: '',
      extProps: {},
      defaultOpt: {
        width: '700px',
        mobileWidth: '95%',
        height: 'auto',
        maxHeight: '100px',
        // successfulwidth: "700px",
        // successfulheight: "400px",
        // wrongwidth: "700px",
        // wrongheight: "400px",
        successfultitle: 'successful_generic_title',
        successfulbody: 'successful_generic_text',
        wrongtitle: 'wrong_generic_title',
        wrongbody: 'wrong_generic_text',
      },
      modalsObj: {
        reportingInfo: {
          title: 'reporting_tab_global_info_title',
          component: 'ReportingInfo',
          body: 'reporting_tab_global_info_body',
        },
        legend: {
          title: 'legend_title',
          component: 'Legend',
          width: '900px',
          closeBtnLiteral: 'ok_btn',
        },
        register: {
          title: 'register_title',
          component: 'Register',
          width: '400px',
          body: 'register_explanation',
          mainAction: [
            {
              name: 'submit_registration_btn',
              action: params => this.submitRegistration(params),
            },
          ],
        },
        reRegister: {
          width: '600px',
          title: 'reregister_title',
          component: 'ReRegister',
          body: 'reregister_explanation',
          mainAction: [
            {
              name: 'submit_registration_btn',
              action: () => {
                this.submitReRegistration({ trackingId: this.extProps.trackingId })
              },
            },
          ],
        },
        informCarArrival: {
          width: '600px',
          title: 'inform_car_arrival_title',
          component: 'PromoteTrackingStatus',
          body: 'inform_car_arrival_explanation',
          mainAction: [
            {
              name: 'inform_car_arrival_btn',
              action: () => this.promoteTrackingStatus(),
            },
          ],
        },
        print: {
          title: 'print_preview_title',
          height: '700px',
          width: '700px',
          component: 'Print',
          mainAction: [
            {
              name: 'save_btn',
              action: () => this.$refs.modalBody.downloadWithCSS(),
            },
          ],
        },
        preview: {
          title: 'preview_title',
          height: '700px',
          width: '700px',
          component: 'Print',
          mainAction: [],
        },
        sendWelcome: {
          title: 'welcome_mail_title',
          // height: '700px',
          // width: '700px',
          component: 'Send',
          otherActions: [
            {
              name: 'print',
              action: params => {
                this.$modal.show('print', { externProps: params })
              },
            },
          ],
          mainAction: [
            {
              name: 'send_welcome_btn',
              action: params => this.sendNotification(params),
            },
          ],
        },
        sendReminder: {
          title: 'reminder_title',
          component: 'Send',
          otherActions: [
            {
              name: 'preview_btn',
              action: params => {
                this.$modal.show('preview', { externProps: params })
              },
            },
          ],
          mainAction: [
            {
              name: 'send_reminder_btn',
              action: params => this.sendNotification(params),
            },
          ],
        },
        sendArrivalNotification: {
          title: 'send_arrival_notification_title',
          component: 'Send', //'SendArrivalNotification',
          otherActions: [
            {
              name: 'preview_btn',
              action: params => {
                this.$modal.show('preview', { externProps: params })
              },
            },
          ],
          mainAction: [
            {
              name: 'send_notification_btn',
              action: params => this.sendNotification(params),
            },
          ],
        },
        sendMkNotification: {
          title: 'send_notification_title',
          component: 'Send', //'SendArrivalNotification',
          mainAction: [
            {
              name: 'send_notification_btn',
              action: params => this.sendNotification(params),
            },
          ],
        },
        sendMassiveNotification: {
          title: 'send_massive_notification_title',
          component: 'Send',
          body: 'send_massive_notification_explanation',
          mainAction: [
            {
              name: 'send_massive_notification_btn',
              action: params => this.sendMassiveNotification(params),
            },
          ],
        },
        downloadExcel: {
          title: 'download_excel_title',
          width: '600px',
          body: 'download_excel_explanation',
          component: 'Download',
          mainAction: [
            {
              name: 'download_btn',
              action: params => this.downloadExcel(params),
            },
          ],
        },
        readNotification: {
          title: 'read_notification_title',
          component: 'Read',
          width: '600px',
          mainAction: [],
        },
        assignCupraMaster: {
          title: 'assign_cupra_master_title',
          component: 'AssignCM',
          width: '600px',
          body: 'assign_cupra_master_title',
          mainAction: [
            {
              name: 'assign_btn',
              action: params => this.assignCupraMaster(params), // ANNA A CANVIAR
            },
          ],
        },
      },
    }
  },
  computed: {
    userRole() {
      return this.$store.state.user.userData.role
    },
    myModalWidth() {
      // this.isMobile ? (this.modalsObj[name].width || this.defaultOpt.width) :
      // console.log(this.name)
      // console.log(
      //   'width',
      //   this.isMobile
      //     ? this.modalsObj[this.name].mobileWidth || this.defaultOpt.mobileWidth
      //     : this.modalsObj[this.name].width || this.defaultOpt.width
      // )
      return this.isMobile
        ? this.modalsObj[this.name].mobileWidth || this.defaultOpt.mobileWidth
        : this.modalsObj[this.name].width || this.defaultOpt.width
    },
    successfulOrWrongBodyMessage() {
      let htmlText = this.$t(
        this.modalsObj[this.name][this.status + 'body'] || this.defaultOpt[this.status + 'body']
      )
      if (this.status === 'wrong') htmlText += this.error.response.data.message || this.error
      return htmlText
    },
    popupData() {
      return { id: this.name, ...this.modalsObj[this.name], ...this.extProps }
    },
  },
  methods: {
    existSpecificActions(name) {
      let mainActionsLength =
        this.modalsObj[name] && this.modalsObj[name]['mainAction']
          ? this.modalsObj[name]['mainAction'].length
          : 0
      let otherActionsLength =
        this.modalsObj[name] && this.modalsObj[name]['otherActions']
          ? this.modalsObj[name]['otherActions'].length
          : 0
      return this.status === '' && mainActionsLength + otherActionsLength > 0
    },
    refresh() {
      if (
        ['successful', 'wrong'].includes(this.status) &&
        !['legend', 'downloadExcel'].includes(this.name)
      ) {
        // console.log(event)
        this.$store.dispatch('summary/load')
        this.$store.dispatch('orders/load')
      } else if (this.status === '' && this.name === 'readNotification') {
        this.readNotification({
          notificationId: this.extProps.notificationId,
          id: this.name,
          viewed: this.extProps.viewed,
        })
      }
    },
    beforeOpen(event) {
      this.status = ''
      if (event.params && event.params.externProps) {
        this.extProps = event.params.externProps
      }
    },
    close(modalId) {
      this.$modal.hide(modalId)
    },
    submitRegistration(params) {
      this.status = 'isLoading'
      console.log('submitRegistration', params)
      postAddBooking({ value: params.trackingId })
        .then(() => {
          this.status = 'successful'
        })
        .catch(e => {
          // console.log(e.response.data)
          this.error = e
          this.status = 'wrong'
        })
    },
    submitReRegistration(params) {
      this.status = 'isLoading'
      console.log('submitRERegistration', params)
      updatePurchase({ trackingId: params.trackingId })
        .then(() => {
          this.status = 'successful'
        })
        .catch(e => {
          // console.log(e.response.data)
          this.error = e
          this.status = 'wrong'
        })
    },
    assignCupraMaster(params) {
      this.status = 'isLoading'
      let extendedParams = {
        trackingId: this.extProps.trackingId,
        ...params,
      }
      console.log('assignCupraMaster')
      assignSeller(extendedParams)
        .then(() => {
          console.log('assignSeller')
          this.status = 'successful'
        })
        .catch(e => {
          // console.log(e.response.data)
          this.status = 'wrong'
          this.error = e
        })
    },
    promoteTrackingStatus() {
      this.status = 'isLoading'
      console.log('promoteTrackingStatus')
      postPromoteTrackingStatus({ trackingId: this.extProps.trackingId })
        .then(() => {
          console.log('promoteTrackingStatus')
          this.status = 'successful'
        })
        .catch(e => {
          // console.log(e.response.data)
          this.error = e
          this.status = 'wrong'
        })
    },
    sendNotification(params) {
      this.status = 'isLoading'
      let extendedParams = {
        trackingId: this.extProps.trackingId,
        notificationConfigId: this.extProps.notificationConfigId,
        ...params,
      }
      console.log('sendNotification')
      sendNotification(extendedParams)
        .then(() => {
          console.log('sendNotification')
          this.status = 'successful'
        })
        .catch(e => {
          // console.log(e.response.data)
          this.status = 'wrong'
          this.error = e
        })
    },
    sendMassiveNotification(params) {
      this.status = 'isLoading'
      let massiveParams = {}
      if (this.extProps.selectedAllPagesForMassiveNotification) {
        massiveParams = { ...this.$store.state.orders.filters }
      } else {
        massiveParams = { trackingIdList: this.extProps.selectedForMassiveNotificationIdList }
      }

      let extendedParams = {
        ...params,
        notificationConfigId: this.extProps.notificationConfigId,
      }
      console.log('sendMassiveNotification')
      sendMassiveNotification(extendedParams, massiveParams)
        .then(() => {
          console.log('sendMassiveNotification')
          this.status = 'successful'
        })
        .catch(e => {
          // console.log(e.response.data)
          this.status = 'wrong'
          this.error = e
        })
    },
    downloadExcel() {
      this.status = 'isLoading'
      console.log('downloadExcel')

      exportList({ ...this.$store.state.orders.filters, ...this.$store.state.orders.pagination })
      this.status = 'successful'
      // .then(()=>{
      //   this.status = 'successful'
      // })
      // .catch((e)=>{
      //   // console.log(e.response.data)
      //   this.error = e
      //   this.status = 'wrong'
      // })
    },
    readNotification(params) {
      if (params.viewed === false && !['MK', 'HQ'].includes(this.userRole)) {
        this.status = 'isLoading' //params: {notificationId}
        console.log('readNotification')
        setViewed({ notificationId: params.notificationId })
          .then(() => {
            this.$store.dispatch('summary/load')
            this.$store.dispatch('orders/load')
          })
          .catch(e => {
            // console.log(e.response.data)
            this.error = e
            this.status = 'wrong'
          })
      }
    },
  },
}
</script>

<style scoped lang="stylus">
.my-modals
  >>> .vm--overlay
    background: rgba(#000, 0.75)

  >>> .vm--modal
    max-height: 90%
    transition: all 0.25s ease-in-out

.container
  position: relative
  display: flex
  flex-direction: column
  padding: 60px 55px 45px
  height: 100%

  .mobile &
    padding: 40px 20px 20px

  &.loading
    height: 300px

  .loader-container
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)

  .close
    position: absolute
    m-icon("close", 20)
    top: 20px
    right: 20px
    cursor: pointer

    .mobile &
      top: 15px
      right: 18px
      background-size: 85% 85% !important

  .title
    font-weight: $fw-light
    m-font-size(24, 30)
    margin-bottom: 20px

  .body
    position: relative
    display: flex
    flex: 1 1 0%
    flex-direction: column
    margin-bottom: 40px
    height: calc(100% - 150px)

    &.register
      margin-bottom: 80px

    &.print
      overflow-x: hidden
      overflow-y: auto

    &.send
      min-height: 100px

      + .actions-section
        .mobile &
          flex-direction: column-reverse

        .specific-actions
          .mobile &
            margin-bottom: 20px
            width: 100%

          .btn
            &:last-child
              .mobile &
                flex: 1 1 0%

    &.read
      >>> .subject
        margin-bottom: 10px

    >>> .loader-container
      position: absolute
      top: 50%
      left: 50%
      transform: translate(-50%, -50%)

    >>> .text
      font-weight: $fw-light
      m-font-size(14, 20)

  .actions-section
    display: flex
    justify-content: center
    align-items: center

    .btn
      &.print
        m-font-size(0)
        padding: 14px

        &:after
          display: block
          content: ""
          m-icon("print-white", 30)

    .specific-actions
      display: flex
      flex: 1 1 0%
      justify-content: flex-end
      margin-left: 10px

      .mobile &
        margin-left: 0

      .btn
        height: 60px

        &:not(.print)
          padding: 1em
</style>
