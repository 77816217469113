<template>
  <div class="dashboard-component">
    <div class="header">
      <div class="header-inner">
        <filters v-if="!isMobile" v-model="filtersValues" :expanded="filtersExpanded" />
        <mobile-filters-wrapper v-else v-model="filtersValues" />
        <slot name="actions" />
      </div>
    </div>
    <div class="content" ref="scroller" @scroll="$emit('scroll', $event.target.scrollLeft)">
      <card
        v-for="c in cardMetas"
        :key="c.id"
        v-model="cardsFlipStates[c.id]"
        :meta="c"
        :filters="filtersValues"
        :mode="comparing && !isMobile ?'full':'front-back'"
      />
    </div>
  </div>
</template>

<script>
import Card from './Card.vue'
import { getCardList } from '../services/api'
import { getDefaultFilterValues } from '../utils/filterUtils'
import Filters from './Filters.vue'
import MobileFiltersWrapper from './MobileFiltersWrapper.vue'
export default {
  components: {
    Filters,
    MobileFiltersWrapper,
    Card,
  },
  props: {
    filtersExpanded: {
      type: Boolean,
    },
    comparing: {
      type: Boolean,
    },
    scroll:{
      type: Number
    }
  },
  data() {
    return {
      filtersValues: getDefaultFilterValues(),
      cardsFlipStates: {},
    }
  },
  watch: {
    cardMetas() {
      this.cardsFlipState = this.cardMetas.reduce((res, card) => {
        res[card.id] = false
        return res
      }, {})
    },
    scroll(value){
      this.$refs.scroller.scrollTo(value,0)
    }
  },
  asyncComputed: {
    cardMetas() {
      return getCardList().then(res => res.data)
    },
  },
  methods: {
    flipAll(side = null) {
      let value = false // side === 'front'
      if (side === 'back') {
        value = true
      } else if (!side) {
        value =
          Object.keys(this.cardsFlipStates).every(key => this.cardsFlipStates[key]) &&
          Object.keys(this.cardsFlipStates).length === this.cardMetas.length
            ? false
            : true
      }
      this.cardsFlipStates = this.cardMetas.reduce((res, obj) => {
        res[obj.id] = value
        return res
      }, {})
    },
  },
}
</script>

<style lang="stylus" scoped>
.dashboard-component
  display: flex
  flex-direction: column

  .header
    position: sticky
    top: 0
    z-index: 9
    border-bottom: 1px solid $gallery
    background-color: #fff

    .mobile &
      border-top: 1px solid $sisal
      border-bottom: 1px solid $sisal
      background-color: $wild-sand

    .comparing &
      .mobile &
        position: relative
        z-index: unset
        
    .header-inner
      display: flex
      align-items: flex-start
      padding: vw(10px) vw(20px)

      .mobile &
        flex-direction: column-reverse
        padding: 0

      +m-breakpoint(lg)
        margin-right: auto
        margin-left: auto
        max-width: $breakpoints[lg]

      .filters
        flex: 1 1 0%

        &:not(:only-child)
          margin-right: "calc(%s * 4)" % $grid-margin

        .mobile &
          margin-right: 0

  .content
    display: flex
    flex-wrap: wrap
    padding: $grid-margin
    min-height: 100%
    width: 100%

    .mobile &
      padding: 0

    +m-breakpoint(lg)
      margin: 0 auto
      max-width: $breakpoints[lg]

    .comparing &
      .mobile &
        flex-wrap: nowrap
        overflow: auto;

    .card
      $percentage = percentage((1 / 3))
      $width = "calc(%s - (%s * 2))" % ($percentage $grid-margin)
      m-keep-ratio()
      flex: 0 0 $width
      margin: $grid-margin
      width: $width

      +m-breakpoint(xlg)
        m-keep-ratio("4-3")

      .mobile &
        $percentage = percentage(1)
        $width = "calc(%s - (%s * 2))" % ($percentage $grid-margin)
        m-keep-ratio("16-10")
        flex: 0 0 $width
        width: $width
</style>