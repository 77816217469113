export default {
  mounted() {
    this.filters = this.storeFiltersToComponentFilters(this.$store.state.orders.filters)
  },
  data() {
    return {
      defaultConfig: {
        'item-text': 'name',
        'item-value': 'id',
      },
      filterConfigurations: {
        activeOrders: {
          CM: [
            'trackingStatus',
            'purchaseDate',
            'model',
            'tmaimg',
            'engine',
            'pr',
            'color',
            'pendingActions',
          ], //,
          SM: [
            'trackingStatus',
            'purchaseDate',
            'cmName',
            'model',
            'tmaimg',
            'engine',
            'pr',
            'color',
            'pendingActionsSM',
          ], //
          MK: [
            'trackingStatus',
            'purchaseDate',
            'model',
            'tmaimg',
            'engine',
            'pr',
            'color',
            'dealerCode',
            'pendingActionsMK',
          ], //'dealerCode', '
          HQ: [
            'country',
            'trackingStatus',
            'purchaseDate',
            'model',
            'tmaimg',
            'engine',
            'pr',
            'color',
            'dealerCode',
            'pendingActionsHQ',
          ], //'dealerCode', '
        },
        orderHistory: {
          CM: [
            'purchaseDate',
            'deliveryDate',
            'model',
            'tmaimg',
            'engine',
            'pr',
            'color',
            'deliveredOnTime',
          ], //,
          SM: [
            'purchaseDate',
            'deliveryDate',
            'cmName',
            'model',
            'tmaimg',
            'engine',
            'pr',
            'color',
            'deliveredOnTime',
          ], // ,'cmName',
          MK: [
            'purchaseDate',
            'deliveryDate',
            'model',
            'tmaimg',
            'engine',
            'pr',
            'color',
            'dealerCode',
            'deliveredOnTime',
          ], //'dealerCode'
          HQ: [
            'country',
            'purchaseDate',
            'deliveryDate',
            'model',
            'tmaimg',
            'engine',
            'pr',
            'color',
            'dealerCode',
            'deliveredOnTime',
          ],
        },
      },
      filters: {},
    }
  },
  methods: {
    resetFilters() {
      this.filters = {} //}'active': this.$route.name === 'active'}
    },
    storeFiltersToComponentFilters(storeFilters) {
      let componentFilters = {}
      let active = storeFilters.active || false
      Object.keys(storeFilters).forEach(key => {
        // if (storeFilters[key]) {
        if (
          [
            'reRegistrationAvailable',
            'unassignedSeller',
            'hasUnreadManualNotifications',
            'possibleDelay',
            'possibleDelayNotDelayed',
          ].includes(key)
        ) {
          if ('pendingActions' in componentFilters) {
            componentFilters['pendingActions'].push(key)
          } else {
            componentFilters['pendingActions'] = [key]
          }
        } else if ('sendNotificationAvailable' === key) {
          if ('pendingActions' in componentFilters) {
            componentFilters['pendingActions'] = componentFilters['pendingActions'].concat(
              storeFilters['sendNotificationAvailable']
            )
          } else {
            componentFilters['pendingActions'] = storeFilters['sendNotificationAvailable']
          }
        } else if ('customerEngaged' === key && storeFilters[key] === false) {
          if ('pendingActions' in componentFilters) {
            componentFilters['pendingActions'].push('unengagedCustomer')
          } else {
            componentFilters['pendingActions'] = ['unengagedCustomer']
          }
        } else if (['purchaseStartDate', 'deliveryStartDate'].includes(key)) {
          let keyPreffix = key.split('StartDate')[0]
          if (keyPreffix + 'Date' in componentFilters) {
            componentFilters[keyPreffix + 'Date']['start'] = this.moment(
              storeFilters[key]
            ).toISOString()
          } else {
            componentFilters[keyPreffix + 'Date'] = {
              start: this.moment(storeFilters[key]).toISOString(),
            }
          }
        } else if (['purchaseEndDate', 'deliveryEndDate'].includes(key)) {
          let keyPreffix = key.split('EndDate')[0]
          if (keyPreffix + 'Date' in componentFilters) {
            componentFilters[keyPreffix + 'Date']['end'] = this.moment(
              storeFilters[key]
            ).toISOString()
          } else {
            componentFilters[keyPreffix + 'Date'] = {
              end: this.moment(storeFilters[key]).toISOString(),
            }
          }
        } else if (key === 'delayed') {
          if (active) {
            if ('pendingActions' in componentFilters) {
              componentFilters['pendingActions'].push(key)
            } else {
              componentFilters['pendingActions'] = [key]
            }
          } else {
            componentFilters['deliveredOnTime'] = storeFilters[key].map(e => !e)
          }
        } else if (key != 'active') {
          componentFilters[key] = storeFilters[key]
        }
        // }
      })
      return componentFilters
    },
    componentFiltersToStoreFilters(componentFilters) {
      let storeFilters = {}
      Object.keys(componentFilters).forEach(key => {
        if (componentFilters[key]) {
          if (key === 'pendingActions') {
            componentFilters[key].forEach(action => {
              if (
                [
                  'reRegistrationAvailable',
                  'unassignedSeller',
                  'hasUnreadManualNotifications',
                  'possibleDelay',
                  'possibleDelayNotDelayed',
                  'delayed',
                ].includes(action)
              ) {
                storeFilters[action] = true
              } else if ('unengagedCustomer' === action) {
                storeFilters['customerEngaged'] = false
              } else {
                if ('sendNotificationAvailable' in storeFilters)
                  storeFilters['sendNotificationAvailable'].push(action)
                else storeFilters['sendNotificationAvailable'] = [action]
              }
            })
          } else if (['purchaseDate', 'deliveryDate'].includes(key)) {
            let keyPreffix = key.split('Date')[0]
            storeFilters[keyPreffix + 'StartDate'] = this.moment(
              componentFilters[key]['start']
            ).format('YYYYMMDD')
            storeFilters[keyPreffix + 'EndDate'] = this.moment(componentFilters[key]['end']).format(
              'YYYYMMDD'
            )
          } else if (key === 'deliveredOnTime') {
            storeFilters['delayed'] = componentFilters[key].map(e => !e)
          } else {
            storeFilters[key] = componentFilters[key]
          }
        }
      })
      return storeFilters
    },
    applyFilters() {
      let activeFilters = this.componentFiltersToStoreFilters(this.filters)
      return this.$store.dispatch('orders/changeFilters', {
        active: this.$route.name === 'active',
        ...activeFilters,
      })
    },
  },
  computed: {
    isClearDisabled() {
      return Object.values(this.filters).reduce(
        (ac, currentValue) => (ac = ac && currentValue === null),
        true
      )
    },
    dataReady() {
      return this.$store.state.summary.loaded
    },
    trackingStatusOptions() {
      let aux = this.$store.state['summary'].data.buckets.map(t => {
        t.nameLang = this.$t(t.name)
        return t
      })
      console.log(aux)
      return aux
    },
    filterFields() {
      return this.filterConfigurations[this.tab][this.userRole].map(f => this.filterDefinitions[f])
    },
    userRole() {
      return this.$store.state.user.userData.role
    },
    filterDefinitions() {
      return {
        cmName: {
          id: 'cmName',
          component: 'SelectComp',
          'item-text': 'name',
          'item-value': 'name',
          options: this.$store.state['offers'].data.cms,
        },
        color: {
          id: 'color',
          component: 'SelectComp',
          'item-text': 'code',
          options: this.$store.state['offers'].data.colors,
        },
        country: {
          id: 'country',
          component: 'SelectComp',
          'item-text': 'id',
          'item-value': 'id',
          options: this.$store.state['countries'].data,
        },
        dealerCode: {
          id: 'dealerCode',
          component: 'SelectComp',
          'item-text': 'id',
          options: this.$store.state['offers'].data.dealers,
        },
        deliveryDate: {
          id: 'deliveryDate',
          type: 'calendar',
          component: 'CalendarComp',
        },
        deliveredOnTime: {
          id: 'deliveredOnTime',
          component: 'SelectComp',
          options: [
            { name: 'yes', id: true },
            { name: 'no', id: false },
          ],
        },
        engine: {
          id: 'engine',
          component: 'SelectComp',
          options: this.$store.state['offers'].data.engines,
        },
        model: {
          id: 'model',
          component: 'SelectComp',
          options: this.$store.state['offers'].data.models,
        },
        pendingActions: {
          id: 'pendingActions',
          component: 'SelectComp',
          'item-text': 'name',
          options: [
            { name: this.$t('pending_welcome_emails'), id: 'welcome_message' },
            { name: this.$t('pending_reminders'), id: 'manual_reminder' },
            { name: this.$t('pushable_notifications'), id: 'cm_client_message' },
            { name: this.$t('unengaged_clients'), id: 'unengagedCustomer' },
            { name: this.$t('unread_notifications'), id: 'hasUnreadManualNotifications' },
            { name: this.$t('possible_delayed_orders'), id: 'possibleDelay' },
            { name: this.$t('reregistration_available'), id: 'reRegistrationAvailable' },
          ],
        },
        pendingActionsSM: {
          id: 'pendingActions',
          component: 'SelectComp',
          'item-text': 'name',
          options: [
            { name: this.$t('pending_welcome_emails'), id: 'welcome_message' },
            { name: this.$t('pending_reminders'), id: 'manual_reminder' },
            { name: this.$t('pushable_notifications'), id: 'cm_client_message' },
            { name: this.$t('unengaged_clients'), id: 'unengagedCustomer' },
            { name: this.$t('unread_notifications'), id: 'hasUnreadManualNotifications' },
            { name: this.$t('possible_delayed_orders'), id: 'possibleDelay' },
            { name: this.$t('reregistration_available'), id: 'reRegistrationAvailable' },
            { name: this.$t('unassigned_seller'), id: 'unassignedSeller' },
          ],
        },
        pendingActionsMK: {
          id: 'pendingActions',
          component: 'SelectComp',
          'item-text': 'name',
          options: [
            { name: this.$t('unengaged_clients'), id: 'unengagedCustomer' },
            { name: this.$t('unread_notifications'), id: 'hasUnreadManualNotifications' },
            { name: this.$t('possible_delayed_orders'), id: 'possibleDelayNotDelayed' },
            { name: this.$t('delayed_cars'), id: 'delayed' },
          ],
        },
        pendingActionsHQ: {
          id: 'pendingActions',
          label: 'hq_pending_actions',
          component: 'SelectComp',
          'item-text': 'name',
          options: [
            { name: this.$t('unengaged_clients'), id: 'unengagedCustomer' },
            { name: this.$t('unread_notifications'), id: 'hasUnreadManualNotifications' },
            { name: this.$t('possible_delayed_orders'), id: 'possibleDelayNotDelayed' },
            { name: this.$t('delayed_cars'), id: 'delayed' },
          ],
        },
        purchaseDate: {
          id: 'purchaseDate',
          type: 'calendar',
          component: 'CalendarComp',
        },
        pr: {
          id: 'pr',
          label: 'PR',
          component: 'SelectComp',
          options: this.$store.state['offers'].data.prs,
          'item-text': 'code',
        },
        tmaimg: {
          id: 'tmaimg',
          component: 'SelectComp',
          options: this.$store.state['offers'].data.tmaimgs,
          'item-text': 'code',
        },
        trackingStatus: {
          id: 'trackingStatus',
          component: 'SelectComp',
          options: this.trackingStatusOptions,
          'item-text': 'nameLang',
        },
        trims: {
          id: 'trim',
          component: 'SelectComp',
          options: this.$store.state['offers'].data.trims,
        },
      }
    },
  },
}
