import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import moment from 'moment'
import vClickOutside from 'v-click-outside'
import Vue2Editor from "vue2-editor";
// import vuetify from '@/plugins/vuetify'
import DeviceDetect from './mixins/DeviceDetect'
import ResponsiveCss from './mixins/ResponsiveCss'
import VCalendar from 'v-calendar';
import VModal from 'vue-js-modal'
import InfiniteLoading from 'vue-infinite-loading';
import AsyncComputed from 'vue-async-computed'



import FormatValue from './reporting_lite_subfolder/mixins/FormatValue'
Vue.mixin(FormatValue)


Vue.config.productionTip = false

Vue.mixin(i18n)
Vue.mixin(DeviceDetect)
Vue.mixin(ResponsiveCss)

Vue.use(AsyncComputed)
Vue.use(VModal)
Vue.use(vClickOutside)
Vue.use(Vue2Editor)
Vue.use(VCalendar)
Vue.use(InfiniteLoading, { /* options */ });

Vue.prototype.moment = moment

new Vue({
  router,
  store,
  // vuetify,
  render: h => h(App)
}).$mount('#app')
