import Vue from 'vue'
import VueRouter from 'vue-router'
import LandingPage from '../views/LandingPage.vue'
import TrackingWrapper from '../views/TrackingWrapper.vue'
import ActiveOrders from '../views/ActiveOrders.vue'
import TracklineView from '../views/TracklineView.vue'
import MyTasksView from '../views/MyTasksView.vue'
import OrderHistory from '../views/OrderHistory.vue'
import OrderDetails from '../views/OrderDetails.vue'
import ReportingLiteWrapper from '../views/ReportingLiteWrapper.vue'
import ReportingDashboard from '../reporting_lite_subfolder/views/Dashboard.vue'
import store from '../store'
import { t } from '../i18n'

let storeInstance = store

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    alias: '/',
    name: 'login',
    component: LandingPage,
  },
  {
    path: '/redirected',
    name: 'redirected',
    component: LandingPage,
  },
  // {
  //   path: '/userInfoRequired',
  //   name: 'userInfoRequired',
  //   component: LandingPage,
  // },
  {
    path: '/tracking',
    name: 'tracking',
    component: TrackingWrapper,
    children: [
      {
        path: 'funnel',
        name: 'funnel',
        component: MyTasksView,
      },
      {
        path: 'tasks',
        name: 'tasks',
        component: MyTasksView,
      },
      {
        path: 'trackline',
        name: 'trackline',
        component: TracklineView,
      },
      {
        path: 'active',
        name: 'active',
        component: ActiveOrders,
      },
      {
        path: 'history',
        name: 'history',
        component: OrderHistory,
      },
      {
        path: 'details/:id',
        name: 'details',
        component: OrderDetails,
      },
    ],
  },
  {
    path: '/reporting',
    component: ReportingLiteWrapper,
    children: [
      {
        path: '',
        name: 'reporting',
        component: ReportingDashboard,
      },
    ],
  },
]

const router = new VueRouter({
  routes,
})

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPageNames = ['login', 'redirected']
  const authRequired = !publicPageNames.includes(to.name)

  const loggedIn = storeInstance.state.user.userData && storeInstance.state.user.userData.userId

  if (authRequired) {
    if (!loggedIn) {
      store
        .dispatch('user/loadUserInfo')
        .then(response => {
          if (response.status === 401) {
            window.open(process.env.VUE_APP_LOGIN_URL + response.data.Location)
            next('/redirected')
          } else if (response.status === 403) {
            alert(response.data.message + '. ' + t('long_403_message'))
            // console.log('aaa' + t('long_403_message'))
            let url =
              process.env.VUE_APP_ENV !== 'qa'
                ? 'https://grp.global.volkswagenag.com/web/module/user/organization/userOrganization'
                : 'https://grp-prelive.global.volkswagenag.com/web/module/user/organization/userOrganization'
            window.open(url)
            next('/redirected')
          } else {
            next()
          }
        })
        .catch(e => {
          next('/login')
        })
    } else {
      next()
    }
  } else {
    // case publicPages
    next()
  }
})

router.beforeEach((to, from, next) => {
  const loggedIn = storeInstance.state.user.userData && storeInstance.state.user.userData.userId
  if (
    loggedIn &&
    !storeInstance.state.user.userData.countryId &&
    storeInstance.state.user.userData.role !== 'HQ' &&
    to.name !== 'userInfoRequired'
  ) {
    next('/userInfoRequired')
  } else {
    if (to.name === 'reporting' && storeInstance.state.user.userData.role !== 'HQ') {
      // ANNA així es com capem la ReportingTab a nomes HQ transitoriament
      next('/tracking/active')
    }
    next()
  }
})

router.afterEach((to, from) => {
  const loggedIn = storeInstance.state.user.userData && storeInstance.state.user.userData.userId
  if (loggedIn) {
    if (['funnel', 'tasks', 'trackline'].includes(to.name)) {
      storeInstance.dispatch('summary/resetShortcut', {}, { root: true })
      if (!storeInstance.state.summary.loaded) {
        storeInstance.dispatch('summary/load')
      }
    } else if (to.name === 'history') {
      storeInstance.dispatch('summary/resetShortcut', {}, { root: true })
      if (
        from.name !== 'details' ||
        !storeInstance.state.orders.filters ||
        Object.keys(storeInstance.state.orders.filters).length === 0 ||
        storeInstance.state.orders.filters.active
      ) {
        storeInstance.dispatch('orders/change', {
          filters: { active: false },
          sort: { sortBy: 'deliveryDate', sortType: 'desc' },
          searched: null,
        })
      } else {
        storeInstance.dispatch('orders/change', { searched: null })
      }
    } else if (to.name === 'active') {
      if (
        from.name !== 'details' ||
        !storeInstance.state.orders.filters ||
        Object.keys(storeInstance.state.orders.filters).length === 0 ||
        !storeInstance.state.orders.filters.active
      ) {
        storeInstance.dispatch('orders/change', {
          filters: { active: true },
          sort: { sortBy: 'purchaseDate', sortType: 'desc' },
          searched: null,
        })
      } else {
        storeInstance.dispatch('orders/change', { searched: null })
      }
    } else if (to.name === 'details') {
      if (!storeInstance.getters['orders/getById'](to.params.id)) {
        storeInstance.dispatch('orders/changeSearch', to.params.id)
      }
    }
  }
})
export default router
