<template>
  <div class="pagination">
    <div class="pages">{{pagination.pag}} / {{totalPages > 0 ? totalPages : 1}}</div>
    <div class="arrows">
      <div :class="['icon', 'arrow', 'previous', {disabled: pagination.pag === 1}]" @click="changePage(pagination.pag - 1)"></div>
      <div :class="['icon', 'arrow', 'next', {disabled: pagination.pag >= totalPages}]" @click="changePage(pagination.pag + 1)"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    tab: String,
  },
  components: {},
  computed: {
    pagination() {
      return this.$store.state.orders.pagination
    },
    totalOrders() {
      return this.$store.state.orders.total
    },
    totalPages() {
      return Math.ceil(this.totalOrders / this.pagination.pagSize)
    },
  },
  methods: {
    changePage(page) {
      this.$store.dispatch('orders/changePage', page)
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
.pagination
  align-items: center
  display: flex
  margin-left: 40px

  .pages
    font-weight: $fw-medium
    m-font-size(14, 18)

  .arrows
    align-items: center
    display: flex
    margin-left: 20px

  .icon
    cursor: pointer

    &:not(:last-child)
      margin-right: 30px

    &.disabled
      cursor: default
      opacity: 0.25
      pointer-events: none

    &.previous
      m-icon("chevron-left", 6 10)

    &.next
      m-icon("chevron-right", 6 10)
</style>
