<template>
  <div class="search">
    <div class="icon search-icon" @click="submit" />
    <input
      v-model="searchStr"
      class="input-search"
      :placeholder="$t('search_text')"
      @keyup.enter="submit"
    >
    <div v-if="searchStr !== null" :class="['icon', 'close']" @click="cancelSearch" />
    <div v-else-if="!isMobile" :class="['icon', 'info']" :title="$t('search_long_explanation')" />
    <div v-if="sumbitedSearch" class="search-results-wrapper">
      <div v-if="results" class="results">
        <!-- <div class="title">Result found!</div> -->
        <div v-for="result in results" :key="result.trackingId" class="result-item-container">
          <OrderMobileCard
            :role="role"
            :order="result"
            :is-searched-order="true"
            @goToResult="goToResult(result)"
          />
        </div>
        <!-- <div class="go-btn btn btn--primary" @click="goToResult">Go to result</div> -->
      </div>
      <div v-else-if="error" class="error">{{ $t(error) }}</div>
      <div v-else class="loading"> {{ $t('searching') }}</div>
    </div>
  </div>
</template>

<script>
import { searchOrders } from '@/api'
import OrderMobileCard from '@/components/OrderMobileCard.vue'

export default {
  name: 'Search',
  components: {
    OrderMobileCard,
  },
  data() {
    return {
      searchStr: null,
      sumbitedSearch: null,
      error: null,
    }
  },
  computed: {
    role() {
      return this.$store.state.user.userData.role
    },
  },
  asyncComputed: {
    results() {
      return (
        this.sumbitedSearch &&
        searchOrders({ value: this.sumbitedSearch })
          .then(response => {
            // return response.data
            response.data.forEach(order => {
              // injectem (com ho fem a orders.js) aquests 2 camps per poguer pintar el OrderMobileCard en el search
              order.delayedIconXOrderHistory = order.delayed
              order.anyDelay = {
                value: order.possibleDelay,
                icon: ['HQ', 'MK'].includes(this.role) && order.delayed ? 'alarm-red' : 'alarm',
              }
            })
            return response.data
          })
          .catch(e => {
            this.error = 'not_found'
            return null
          })
      )
    },
  },
  methods: {
    submit() {
      this.sumbitedSearch = this.searchStr
      // this.$store.dispatch('orders/changeSearch', this.searchStr)
      // console.log('search for this commnr/trackingId', this.searchStr)
    },
    cancelSearch() {
      this.sumbitedSearch = null
      this.searchStr = null
      // this.$emit('close')
      // this.$store.dispatch('orders/changeSearch', this.searchStr)
    },
    goToResult(result) {
      this.$router.push({ name: 'details', params: { id: result.trackingId } })
      // this.searchStr = null
      this.sumbitedSearch = null
      this.result = null
      this.$emit('close')
    },
  },
}
</script>

<style scoped lang="stylus">
.search
  position: relative
  display: flex
  align-items: center
  padding: 0 20px
  width: 350px
  height: 50px
  border: 1px solid #000
  border-radius: 25px

  .icon
    &.hide
      display: none

    &.search-icon
      m-icon("search-black", 20)
      margin-right: 15px

    &.close
      cursor: pointer
      m-icon("cross", 10)
      margin-left: 15px

    &.info
      m-icon("info-black", 25)
      margin-left: 15px

  .input-search
    flex: 1 1 0%
    height: 20px
    border: none
    m-ellipsis(100%)
    outline: none

  .search-results-wrapper
    overflow-y: auto
    max-height: 340px

    .mobile &
      position: absolute
      top: calc(100% + 11px)
      left: -14px
      z-index: 1
      width: 100vw
      background-color: #fff

    .desktop &
      position: absolute
      top: calc(100% + 11px)
      left: 50%
      z-index: 1
      width: calc(100% - 10px)
      border: 1px solid $gallery
      background-color: #fff
      box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.3)
      transform: translateX(-50%)

    .results
      .title
        padding-top: 15px
        padding-right: 20px
        padding-left: 20px
        font-weight: $fw-medium

      .result-summary
        margin-bottom: 15px
        padding-top: 15px
        padding-right: 20px
        padding-left: 20px

    .error,
    .loading
      display: flex
      align-items: center
      padding: 15px 20px
      height: 68px
</style>