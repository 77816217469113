<template>
  <div id="app" :class="['app', deviceClass]">
    <template v-if="isPortrait && !isMobile">
      <div class="portrait">
        <div class="text">
          {{ $t('turn_your_device_to_landscape_mode') }}
        </div>
      </div>
    </template>
    <template v-if="!isPortrait && isMobile">
      <div class="portrait">
        <div class="text">
          {{ $t('turn_your_device_to_portrait_mode') }}
        </div>
      </div>
    </template>
    <template v-else>
      <InputWithLabel
        v-model="role"
        :class="['role', isRoleOpen? 'open': 'closed']"
        component="SelectComp"
        label="Roles (demo)"
        :configs="{ items: [{id:'CM'},{id:'SM'},{id:'MK'},{id:'HQ'}], multiple: false, itemText: 'id', itemValue: 'id'}"
      />
      <GlobalHeader
        :hide="['userInfoRequired', 'redirected', 'login'].includes($route.name)"
      />
      <!--v-on:rolechoose="roleSelectCompAvailable" -->
      <!-- <keep-alive> -->
      <router-view :key="$route.name" class="main" />
      <!-- </keep-alive> -->
      <my-modals name="legend" />
      <my-modals name="register" />
      <my-modals name="reRegister" />
      <my-modals name="informCarArrival" />
      <my-modals name="sendWelcome" />
      <my-modals name="sendReminder" />
      <my-modals name="sendArrivalNotification" />
      <my-modals name="sendMkNotification" />
      <my-modals name="sendMassiveNotification" />
      <my-modals name="downloadExcel" />
      <my-modals name="readNotification" />
      <my-modals name="print" />
      <my-modals name="preview" />
      <my-modals name="assignCupraMaster" />
      <!-- pop ups on Reporting Tab -->
      <my-modals name="reportingInfo" />
    </template>
  </div>
</template>
<script>
// @ is an alias to /src
import GlobalHeader from '@/components/GlobalHeader.vue'
import InputWithLabel from '@/components/InputWithLabel.vue'
import MyModals from '@/components/MyModals.vue'
import device from '@/utils/device'

export default {
  name: 'App',
  components: {
    GlobalHeader,
    MyModals,
    InputWithLabel,
  },
  data() {
    return {
      userStatus: null,
      isPortrait: false,
      role: 'CM',
      isRoleOpen: false,
    }
  },
  computed: {
    deviceClass() {
      return device
    },
  },
  watch: {
    role: {
      handler() {
        this.$store.dispatch('user/changeRoleDemo', this.role)
        this.$store.dispatch('orders/load') // ANNA PER LA DEMO
      }, //,
      // immediate: true
    },
  },
  mounted() {
    // this.login()
    this.orientation()
    window.addEventListener(
      'resize',
      () => {
        this.orientation()
      },
      false
    )
  },

  methods: {
    roleSelectCompAvailable() {
      return (this.isRoleOpen = !this.isRoleOpen)
    },
    orientation() {
      if (window.innerHeight > window.innerWidth) {
        console.log('// youre in PORTRAIT mode')
        this.isPortrait = true
      } else {
        console.log('// youre in landscape mode')
        this.isPortrait = false
      }
    },
  },
}
</script>

<style lang="stylus">
#app
  position: relative
  display: flex
  flex-direction: column
  height: 100%
  -moz-osx-font-smoothing: grayscale
  -webkit-font-smoothing: antialiased

  .portrait
    position: absolute
    top: 0
    left: 0
    z-index: 1000
    width: 100vw
    height: 100vh
    background-color: $cyprus

    .text
      position: absolute
      top: 50%
      left: 50%
      color: #fff
      transform: translate(-50%, -50%)

  .loader-container
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)

  .role
    &.closed
      display: none

    &.open
      position: absolute
      top: 0
      right: 50%
      z-index: 1000
      width: 95px

  .main
    flex: 1 1 0%
    overflow: hidden
    background-color: $wild-sand

    .main-wrapper
      display: flex
      overflow-x: hidden
      overflow-y: auto
      width: 100%
      height: 100%

      +m-breakpoint(lg)
        margin: 0 auto
        width: $breakpoints[lg]
</style>
