<template>
  <div class="assign-cm">
    <div class="text" v-html="$t(data.body)"></div>
    <InputWithLabel
      component="SelectComp"
      :label="$t('choose_cupra_master')+'*'"
      v-model="internalValue"
      :configs="{items: options, multiple : false, itemText : 'name', itemValue: 'userId'}"
    ></InputWithLabel>
  </div>
</template>

<script>
import InputWithLabel from '@/components/InputWithLabel.vue'
import modalContentMixin from '@/components/modals/modalContentMixin'
export default {
  name: 'AssignCM',
  components: {
    InputWithLabel,
  },
  mixins: [modalContentMixin],
  data() {
    return {
      internalValue: null,
    }
  },
  props: ['data'],
  methods: {
    validate(data) {
      return data.userId
    },
  },
  computed: {
    options() {
      return this.$store.state.offers.data.cms
    },
    contentValue() {
      return { userId: this.internalValue }
    },
  },
}
</script>

<style scoped lang="stylus">
.text
  margin-bottom: 20px
</style>