import Vue from 'vue'
import Vuex from 'vuex'
import i18n from './i18n'
import user from './modules/user'
import summary from './modules/data/summary'
import orders from './modules/data/orders'
import notification from './modules/data/notification'
import offers from './modules/data/offers'
import countries from './modules/data/countries'

Vue.use(Vuex)

// require('es6-promise').polyfill()

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
    init ({state, commit, dispatch}, params) {
      dispatch('user/loadUserInfo')
      dispatch('summary/load')
      dispatch('offers/load')
      dispatch('countries/load')
      // dispatch('orders/load')
    }
  },
  modules: {
    i18n: i18n,
    user: user,
    summary: summary,
    orders: orders,
    notification: notification,
    countries: countries,
    offers: offers
  },
  getters: {
    dataReady: (state) => (excludeArray = []) => {
      let modules = ['offers', 'countries']

      let loaded = true

      modules.forEach(mod => {
        if (!excludeArray.includes(mod)) {
          loaded = loaded & state[mod].loaded
        }
      })
      return loaded
    }
  }
})
