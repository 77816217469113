<template>
  <div class="print">
    <PrintContent class="seen" :data="data"></PrintContent>
    <PrintContent class="unseen" :data="data" ref="printable"></PrintContent>
  </div>
</template>

<script>
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import modalContentMixin from '@/components/modals/modalContentMixin'
import PrintContent from '@/components/modals/PrintContent'
export default {
  name: 'Print',
  props: ['data'],
  components: { PrintContent },
  mixins: [modalContentMixin],
  computed: {
    contentValue() {
      return true
    },
  },
  methods: {
    downloadWithCSS() {
      const doc = new jsPDF()
      /** WITH CSS */
      window.scrollTo(0, 0)
      var canvasElement = document.createElement('canvas')
      canvasElement.width = 590
      canvasElement.height = 5000 //this.$refs.printable.$el.height
      html2canvas(this.$refs.printable.$el, {
        scrollY: -window.scrollY,
        height: window.outerHeight + window.innerHeight,
        // windowHeight: window.outerHeight + window.innerHeight,
        canvas: canvasElement,
      }).then(function(canvas) {
        const img = canvas.toDataURL('image/jpeg', 1)
        doc.addImage(img, 'JPEG', 25, 10)
        doc.save('sample.pdf')
      })
    },
  },
}
</script>

<style scoped lang="stylus">
.unseen
  position: absolute
  z-index: -1 // margin-bottom: 20px
</style>