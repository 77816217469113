import { render, staticRenderFns } from "./AssignCM.vue?vue&type=template&id=3b4dc327&scoped=true&"
import script from "./AssignCM.vue?vue&type=script&lang=js&"
export * from "./AssignCM.vue?vue&type=script&lang=js&"
import style0 from "./AssignCM.vue?vue&type=style&index=0&id=3b4dc327&scoped=true&lang=stylus&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b4dc327",
  null
  
)

export default component.exports