import moment from 'moment'
var dayjs = require('dayjs')
require('dayjs/locale/de')
require('dayjs/locale/es')
require('dayjs/locale/en')
require('dayjs/locale/it')
require('dayjs/locale/fr')
export default {
  namespaced: true,
  state: {
    lang: 'en-GB',
  },
  mutations: {
    setLanguage(state, lang) {
      state.lang = lang
      moment.locale(lang.slice(0, 3))
      dayjs.locale(lang.slice(0, 2))
    },
  },
  actions: {
    setLanguage(context, lang) {
      context.commit('setLanguage', lang)
    },
  },
  getters: {
    getLocale: state => () => {
      return state.lang.slice(0, 2)
    },
  },
}
