<template>
  <div class="landing-page">
    <div v-if="!isMobile || isMobile && ['redirected', 'login'].includes($route.name)" class="image-bg" />
    <div :class="['column', isMobile && ['redirected', 'login'].includes($route.name) ? 'white':'']">
      <div class="section top">
        <div class="logo" />
      </div>
      <template v-if="$route.name==='userInfoRequired'">
        <complete-user-access-info />
      </template>
      <template v-else-if="$route.name==='redirected'">
        <div class="section body">
          <div class="title">{{ $t('redirected_title') }}</div>
          <div class="text" v-html="$t('redirected_body')" />
          <div :class="['btn', isMobile ? 'btn--tertiary btn--ghost' : '', 'continue-btn']" @click="goTo()">{{ $t('continue_btn') }}</div>
        </div>
      </template>
      <template v-else-if="$route.name==='login'">
        <!--LOGIN-->
        <div class="section body">
          <div class="title">{{ $t('welcome_title') }}</div>
          <div class="text" v-html="$t('welcome_body')" />
          <div :class="['btn', isMobile ? 'btn--tertiary btn--ghost' : '', 'continue-btn']" @click="goTo()">{{ $t('login_btn') }}</div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
// import { setAccessInfo } from '@/api'
// import InputWithLabel from '@/components/InputWithLabel'
// import Loader from '@/components/Loader'
import CompleteUserAccessInfo from '@/components/CompleteUserAccessInfo'
// import PromoteTrackingStatusVue from '../components/modals/PromoteTrackingStatus.vue'

export default {
  name: 'LandingPage',
  components: {
    CompleteUserAccessInfo,
  },
  methods: {
    goTo() {
      if (!this.isMobile) {
        this.$router.push({ name: 'active' }).catch(e => e)
      } else {
        if (['MK', 'HQ'].includes(this.$store.state.user.userData.role)) {
          this.$router.push({ name: 'funnel' }).catch(e => e)
        } else {
          this.$router.push({ name: 'tasks' }).catch(e => e)
        }
      }
    },
  },
}
</script>

<style scoped lang="stylus">
.landing-page
  display: flex

  .mobile &
    .image-bg
      position: absolute
      width: 100%
      height: 100%

    .column
      position: absolute
      padding: 20px
      width: 100%
      height: 100%
      background-color: transparent

  .image-bg
    flex: 1 1 0%

  .column
    flex: 0 0 400px
    background-color: #fff

.image-bg
  background-image: url("~@/assets/welcome-bg.jpg")
  background-position: center center
  background-size: contain
  background-size: cover
  background-repeat: no-repeat

  .mobile &
    background-position: 25% center

    &:after
      position: absolute
      width: 100%
      height: 100%
      background-color: rgba(#000, 0.5)
      content: ""

.column
  display: flex
  flex-direction: column
  overflow-y: auto
  padding: 50px

  &.white
    color: #fff

    .section
      .logo
        background-image: url("~@/assets/cupra_logo_white.png")

  .section
    display: flex
    flex-direction: column

    .input-with-label
      margin-bottom: 40px

    .title
      font-weight: $fw-light
      m-font-size(30, 40)
      margin-bottom: 30px

    .text
      font-weight: $fw-light
      m-font-size(16, 30)
      margin-bottom: 50px

    .errorMsg
      color: red
      font-weight: $fw-medium
      m-font-size(16, 30)

    .logo
      display: block
      margin-right: 50px
      width: 132px
      height: 20px
      background-image: url("~@/assets/cupra-logo.svg")
      background-position: center center
      background-size: contain
      background-repeat: no-repeat

    &.body
      flex: 1 1 0%
      justify-content: center

      .mobile &
        padding-top: 20%

        .btn
          margin-top: auto
</style>
