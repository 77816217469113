import { getFilterTree, getPrList, getColorList, getDealerList, getCMList } from '../services/api'
import { t } from '../../i18n'
import { getPeriodParams, getDefaultTimePeriodValue } from './DatesUtils'
export function getDefaultFilterValues() {
  // ANNA UNHARDCODE!
  return {
    time_period: getDefaultTimePeriodValue('month'),
  }
}
export function countFiltersUsed(currentValues) {
  return Object.keys(currentValues || {}).length
}
// export function countNonVisibleFiltersUsed(currentValues) {
//   // A MILLORAR pq ara son visble + nonvisible
//   return countFiltersUsed(currentValues)
// }

export function showFiltersDescription(value, defs) {
  if (defs) {
    let aux = Object.keys(value).map(k => {
      let filterInfo = defs.find(d => d.id === k)
      // basurillas per time_period i toggle_selects!
      if (k === 'time_period') {
        return value[k].label
      } else if (typeof value[k] == 'boolean') {
        return t(filterInfo.options[0].label) // this.$t(filterInfo.options[0].label) //
      } else {
        return value[k].map(e => {
          return filterInfo.options.find(d => d.id === e).label
        })
      }
    })
    return aux.flat().join(', ')
  }
  return value
}

export function getFilterDefs(currentValues, role) {
  let filterRoleConfigurations = {
    // cal que en filterRoleConfigurations hi hagi tripleFilter!!
    CM: [
      'time_period',
      'tripleFilter',
      'model',
      'tmaimg',
      'motor',
      'onlyPhev',
      'pr',
      'color',
      'onlyActive',
    ],
    SM: [
      'time_period',
      'tripleFilter',
      'model',
      'tmaimg',
      'motor',
      'onlyPhev',
      'pr',
      'color',
      'cm',
      'onlyActive',
    ],
    MK: [
      'time_period',
      'tripleFilter',
      'model',
      'tmaimg',
      'motor',
      'onlyPhev',
      'pr',
      'color',
      'dealer',
      'onlyActive',
    ],
    HQ: [
      'country',
      'tripleFilter',
      'time_period',
      'model',
      'tmaimg',
      'motor',
      'onlyPhev',
      'pr',
      'color',
      'dealer',
      'onlyActive',
    ],
  }
  let filterMetaDefs = [
    {
      id: 'tripleFilter',
      type: 'normal',
      optionsGetter: getFilterTree,
    },
    {
      id: 'time_period',
      type: 'normal',
      order: 1,
      component: 'PeriodComp',
      optionsGetter: () => {
        return [
          {
            id: 'ytd',
            label: 'time_ytd',
            component: 'SelectComp',
            // optionsGetter: () => getTimeList('ytd'),
          },
          {
            id: 'period',
            component: 'SelectComp',
            label: 'time_period',
            // optionsGetter: () => getTimeList('month'),
          },
          {
            id: 'week',
            component: 'SelectComp',
            label: 'time_week',
            // optionsGetter: () => getTimeList('week'),
          },
          {
            id: 'month',
            component: 'SelectComp',
            label: 'time_month',
            // optionsGetter: () => getTimeList('month'),
          },
        ]
      },
    },
    {
      id: 'onlyPhev',
      type: 'normal',
      order: 6,
      component: 'ToggleSelect',
      optionsGetter: () => {
        return [
          { id: 'phev', label: 'phev' },
          { id: 'all', label: 'all' },
        ]
      },
    },
    {
      id: 'pr',
      type: 'normal',
      order: 7,
      label: 'code',
      component: 'SelectComp',
      optionsGetter: getPrList,
    },
    {
      id: 'color',
      type: 'normal',
      label: 'code',
      order: 8,
      component: 'SelectComp',
      optionsGetter: getColorList,
    },
    {
      id: 'dealer',
      type: 'normal',
      order: 9,
      component: 'SelectComp',
      optionsGetter: getDealerList,
    },
    {
      id: 'cm',
      type: 'normal',
      order: 10,
      component: 'SelectComp',
      optionsGetter: getCMList,
    },
    {
      id: 'onlyActive',
      type: 'normal',
      order: 11,
      component: 'ToggleSelect',
      optionsGetter: () => {
        return [
          { id: 'active', label: 'active' },
          { id: 'all', label: 'all' },
        ]
      },
    },
  ]

  let promises = filterMetaDefs
    // aquí traiem dealer i cm quan no es pot ni cridar al endpoint
    // cal que en filterRoleConfigurations hi hagi tripleFilter!!
    .filter(f => filterRoleConfigurations[role].includes(f.id))
    .map(meta => {
      // console.log('META   ', meta.id)
      let options = meta.optionsGetter()
      // if (meta.id === 'time_period') {
      //   options = options.map(el => {
      //     return {
      //       ...el,
      //       items: el.optionsGetter(),
      //     }
      //   })
      // }
      return options
    })
  return Promise.all(promises).then(responses => {
    let filterDefs = {}
    responses.forEach((resp, i) => {
      let id = filterMetaDefs[i].id
      if (id === 'tripleFilter') {
        let { countryOpts, modelOpts, tmaimgOpts, engineOpts } = getTripleOptions(
          resp.data,
          currentValues
        )
        filterDefs['country'] = {
          id: 'country',
          order: 2,
          component: 'SelectComp',
          options: countryOpts,
        }
        filterDefs['model'] = {
          id: 'model',
          order: 3,
          component: 'SelectComp',
          options: modelOpts,
        }
        filterDefs['tmaimg'] = {
          id: 'tmaimg',
          order: 4,
          component: 'SelectComp',
          options: tmaimgOpts,
        }
        filterDefs['motor'] = {
          id: 'motor',
          order: 5,
          component: 'SelectComp',
          options: engineOpts,
        }
      } else {
        filterDefs[id] = {
          ...filterMetaDefs[i],
          options: resp.data || resp,
        }
      }
    })
    let orderedFilters = Object.values(filterDefs).sort((a, b) => a.order - b.order)
    let orderedRoleFilters = orderedFilters.filter(f =>
      //aquí traiem country quan cal
      filterRoleConfigurations[role].includes(f.id)
    )
    return orderedRoleFilters
  })
}

export function validateValues(value, defs) {
  let wasInvalid = false
  let valid = Object.keys(value).reduce((res, key) => {
    //cas toggle
    if (key !== 'onlyPhev' && key !== 'onlyActive' && key !== 'time_period') {
      if (value[key] !== null) {
        res[key] = value[key].filter(v => {
          let def = defs.find(fd => fd.id === key)
          return def.options.findIndex(o => o.id === v) >= 0
        })
      }
      if (res[key] === null) delete res[key]
      if (!res[key] || res[key].length !== value[key].length) wasInvalid = true
    }
    return res
  }, {})
  // hay que hacerlo "asi" porque sino entra en bucles infinitos!!!
  if (wasInvalid) return valid
  else return null //this.$emit('change', tmp)
}
export function filtersToQuery(val, dataType) {
  // console.log('FILTERS   ', val)
  let queryParams = {}
  Object.keys(val).forEach(key => {
    if (key === 'time_period') {
      queryParams = { ...queryParams, ...getPeriodParams(val[key], dataType) }
    } else if (key === 'onlyPhev') {
      queryParams['phev'] = val[key] || null
    } else if (key === 'onlyActive') {
      queryParams['active'] = val[key] || null
    } else {
      queryParams[key] = val[key]
    }
  })

  return queryParams
}
// hay que hacerlo "en tres partes" validando en cada paso, porque sino desaparecen las options de "2 mas adelante"....
function getTripleOptions(def, currentValues = {}) {
  let countryOpts = []
  let modelOpts = []
  let tmaimgOpts = []
  let engineOpts = []

  // forEach COUNTRY
  def.forEach(cou => {
    countryOpts.push({ id: cou.id, label: cou.id })
    if (
      !currentValues['country'] ||
      currentValues['country'].length === 0 ||
      currentValues['country'].indexOf(cou.id) >= 0
    ) {
      // forEach MODEL
      cou.modelList.forEach(mod => {
        modelOpts.push({ id: mod.id, label: mod.name })
        if (
          !currentValues['model'] ||
          currentValues['model'].length === 0 ||
          currentValues['model'].indexOf(mod.id) >= 0
        ) {
          // forEach tmaimg
          mod.tmaimgList.forEach(tmaimg => {
            tmaimgOpts.push({ id: tmaimg.id, label: tmaimg.code })
            engineOpts.push({ id: tmaimg.motorType.id, label: tmaimg.motorType.name })
          })
        }
      })
    }
  })

  countryOpts = uniq(countryOpts)
  modelOpts = uniq(modelOpts)
  tmaimgOpts = uniq(tmaimgOpts)
  engineOpts = uniq(engineOpts)

  return { countryOpts, modelOpts, tmaimgOpts, engineOpts }
}

function uniq(array, getter = d => d.id) {
  return array.filter((d, i) => array.findIndex(dd => getter(dd) === getter(d)) === i)
}
