<template>
  <div class="filters">
    <template v-if="!dataReady">
      <Loader />
    </template>
    <template v-else>
      <div class="header">
        <div class="title">{{ $t('filters') }}</div>
        <div :class="['btn-clear', isClearDisabled ? 'disabled': 'available']" @click="resetFilters">{{ $t('clear_btn') }}</div>
      </div>
      <div class="filters-container">
        <div
          v-for="f in filterFields"
          :key="f.id"
          class="filter-container"
        >
          <InputWithLabel
            v-model="filters[f.id]"
            :component="f['component']"
            :label="$t(f['label'] || f['id'])"
            :configs="{ multiple: true, items: f['options'] || [], itemText: f['item-text'] || defaultConfig['item-text'], itemValue: f['item-value'] || defaultConfig['item-value']}"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import InputWithLabel from '@/components/InputWithLabel.vue'
import Loader from './Loader'

import filtersMixin from '@/components/filtersMixin'

export default {
  name: 'Filters',
  components: {
    InputWithLabel,
    Loader,
  },
  mixins: [filtersMixin],
  props: {
    tab: String,
  },
  watch: {
    filters: {
      handler() {
        this.applyFilters()
      },
      deep: true,
    },
  },
}
</script>

<style scoped lang="stylus">
.filters
  display: flex
  flex-direction: column
  margin-bottom: 30px

  .header
    display: flex
    align-items: center
    margin-top: 10px
    margin-bottom: 10px

    .title
      flex: 1 1 0%
      m-font-size(14, 18)
      font-weight: $fw-light

      // text-transform: uppercase
      .active-orders &
        display: none

    .btn-clear
      m-font-size(14, 18)
      align-self: flex-end
      margin-left: auto
      color: $alto
      text-transform: uppercase
      pointer-events: none

      &.available
        color: #000
        font-weight: $fw-medium
        cursor: pointer
        pointer-events: all

.filter-container
  margin-bottom: 10px

  >>>.calendar-comp
    .calendar
      &.open
        position: relative !important

// PQ SIGUI ACORDEON
>>> .select-comp
  margin-bottom: 0 !important
  height: auto !important

  .multiselect
    top: 0 !important
    margin-top: 50px !important
    width: 100% !important

    .mobile &
      margin-top: 60px !important

    .multiselect__content-wrapper
      position: relative !important
      display: block !important
      // max-height: none !important
      max-height: 32vh !important
      width: auto !important

      .multiselect__option
        white-space: normal !important
</style>