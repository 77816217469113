<template>
  <div class="row-values">
    <div v-for="row in data" :key="row.meta.id" class="row">
      <div class="left">{{ $t(row.meta.name) }}</div>
      <div class="right">
        {{ $prettyPrint(row.data.value, row.meta.unitType) }} {{ $t(row.meta.unitType) }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['data'],
  computed: {},
}
</script>

<style lang="stylus" scoped>
.row-values
  width: 100%

  .row
    display: flex
    justify-content: space-between
    align-items: center
    padding-top: vw(5px)
    padding-bottom: vw(5px)
    border-bottom: 1px solid #D5D5D5

    .left
      m-font-size(12, 14)
      font-weight: $fw-light

    .right
      m-font-size(11, 13)
      font-weight: $fw-medium
</style>