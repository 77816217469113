<template>
  <div class="filters-mobile-menu">
    <template v-if="!dataReady">
      <Loader />
    </template>
    <template v-else>
      <div class="header">
        <div class="title">{{ $t('filters') }}</div>
        <div :class="['btn-clear', isClearDisabled ? 'disabled': 'available']" @click="resetFilters">{{ $t('clear_btn') }}</div>
        <div class="icon-close" @click="filters={}; $emit('close')" />
      </div>
      <div class="filters-container">
        <div
          v-for="f in filterFields"
          :key="f.id"
          class="filter-container"
        >
          <InputWithLabel
            v-model="filters[f.id]"
            :component="f['component']"
            :label="$t(f.id)"
            :configs="{ multiple: true, items: f['options'] || [], itemText: f['item-text'] || defaultConfig['item-text'], itemValue: f['item-value'] || defaultConfig['item-value']}"
          />
        </div>
      </div>
      <div class="apply-filters-btn-container">
        <!-- {{filters}} -->
        <div :class="['btn', 'btn--primary', 'apply-filters-btn', 'available']" @click="applyMobileFilters">
          {{ $t('apply_filters_mobile_btn') }}
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import InputWithLabel from '@/components/InputWithLabel.vue'
import Loader from './Loader'

import filtersMixin from '@/components/filtersMixin'

export default {
  name: 'Filters',
  components: {
    InputWithLabel,
    Loader,
  },
  mixins: [filtersMixin],
  props: {
    tab: String,
  },
  methods: {
    applyMobileFilters() {
      this.applyFilters().then(() => {
        this.$emit('close')
        this.$emit('changed-filters')
      })
    },
  },
}
</script>

<style scoped lang="stylus">
.filters-mobile-menu
  $left = 20px
  position: fixed
  top: 0
  left: $left
  z-index: 100
  display: flex
  flex-direction: column
  padding: vw(20px)
  width: "calc(100% - %s)" % $left
  height: 100%
  background-color: $wild-sand

  &:before
    position: absolute
    top: 0
    left: -($left)
    display: block
    width: $left
    height: 100vh
    background-color: rgba(#000, 0.5)
    content: ""

  .header
    display: flex
    align-items: center
    margin-right: vw(40px)
    margin-bottom: vw(30px)

  .title
    m-font-size(14, 18)
    m-letter-spacing(25)
    flex: 1 1 0%
    color: rgba($dune, 0.5)
    text-transform: uppercase

    .active-orders &
      display: none

  .btn-clear
    m-font-size(14, 18)
    m-letter-spacing(25)
    align-self: flex-end
    margin-left: auto
    color: $paarl
    text-transform: uppercase
    font-weight: $fw-medium
    opacity: 0.5
    pointer-events: none

    &.available
      opacity: 1
      cursor: pointer
      pointer-events: all

  .icon-close
    m-icon("close", 20)
    position: absolute
    top: 20px
    right: 15px
    background-size: 85% 85% !important

.filters-container
  flex: 1 1 0%
  overflow-y: auto
  margin-bottom: vw(30px)

>>> .calendar-comp
  .calendar
    &.open
      position: relative !important
      width: 100%

>>> .select-comp
  margin-bottom: 0 !important
  height: auto !important

  .multiselect
    top: 0 !important
    margin-top: 50px !important
    width: 100% !important

    .mobile &
      margin-top: 60px !important

    .multiselect__content-wrapper
      position: relative !important
      display: block !important
      max-height: 32vh !important
      width: auto !important

      >>> .multiselect__option
        white-space: normal !important
</style>