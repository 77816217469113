export default {
html_title: "Sistema di tracciamento",
welcome_title: "Benvenuto nel sistema di tracciamento CUPRA",
welcome_body: "-",
continue_btn: "Continua",
welcome_body_first_time: "È la prima volta che accedi al sistema, per questo abbiamo bisogno di qualche informazione in più. Queste informazioni ti saranno richieste una sola volta. <br><br>Compilare i seguenti campi:",
country_code: "Codice ISO del paese",
dealer_code: "Codice concessionario",
hide_summary_btn: "Nascondi Le mie attività",
show_summary_btn: "Mostra Le mie attività",
hide_funnel_btn: "Nascondi Funnel",
show_funnel_btn: "Mostra Funnel",
my_tasks_title: "Le mie attività",
funnel_title: "Funnel",
pending_welcome_emails: "Email di benvenuto non inviate",
pendingWelcome: "Email di benvenuto non inviate",
cm_pending_welcome_info_on_click: "Inviare le informazioni di accesso al cliente",
sm_pending_welcome_info_on_click: "Inviare le informazioni di accesso al cliente",
unengaged_clients: "Clienti disinteressati",
unengagedClients: "Clienti disinteressati",
cm_unengaged_clients_info_on_click: "Questi clienti non hanno mai fatto accesso al programma di tracciamento",
sm_unengaged_clients_info_on_click: "Questi clienti non hanno mai fatto accesso al programma di tracciamento",
mk_unengaged_clients_info_on_click: "Questi clienti non hanno mai fatto accesso al programma di tracciamento",
pushable_notifications: "Notifiche di arrivo in sospeso",
pushableNotifications: "Notifiche di arrivo in sospeso",
cm_pushable_notifications_info_on_click: "Informa il cliente che il veicolo è dal concessionario",
sm_pushable_notifications_info_on_click: "Informa il cliente che il veicolo è dal concessionario",
unread_notifications: "Notifiche non lette",
unreadNotifications: "Notifiche non lette",
cm_unread_notifications_info_on_click: "Sono messaggi ancora non letti.",
sm_unread_notifications_info_on_click: "I tuoi CUPRA Master non hanno ancora letto questi messaggi.",
mk_unread_notifications_info_on_click: "Queste notifiche non sono state lette dal CUPRA Master corrispondente.",
possible_delayed_orders: "Consegna in pochi giorni",
possibleDelayedOrders: "Consegna in pochi giorni",
cm_possible_delayed_orders_info_on_click: "Preparare tutto per la consegna del veicolo. ",
sm_possible_delayed_orders_info_on_click: "Preparare tutto per la consegna del veicolo. ",
possible_delayed_market_orders: "Possibili ordini in ritardo",
possibleDelayedMarketOrders: "Possibili ordini in ritardo",
mk_possible_delayed_market_orders_info_on_click: "Questi ordini attivi sono stati generati più di 20 giorni fa e non sono dal concessionario.",
delayed_cars: "Auto in ritardo",
delayedCars: "Auto in ritardo",
mk_delayed_cars_info_on_click: "Questi ordini attivi sono stati generati più di 25 giorni fa e non sono ancora stati consegnati.",
total_active_orders: "Ordini attivi",
totalActiveOrders: "Ordini attivi",
mk_total_active_orders_info_on_click: "Ordini creati e non consegnati",
welcome_email_sent: "Email di benvenuto inviata",
welcomeEmailSent: "Email di benvenuto inviata",
mk_welcome_email_sent: "Rapporto tra gli ordini attivi con email di benvenuto inviate rispetto al totale",
pending_deliveries: "Consegne in sospeso",
pendingDeliveries: "Consegne in sospeso",
mk_pending_deliveries_info_on_click: "Ordini presso il concessionario in attesa di notifica di vendita",
cm_active: "CUPRA Master attivi nel sistema",
cmActives: "CUPRA Master attivi nel sistema",
mk_cm_active: "Un CUPRA Master è attivo se ha effettuato il login nell’ultima settimana",
legend_title: "Informazioni su cosa puoi fare.",
send_notification_legend: "Inviare al cliente l’avviso: “l’auto è dal concessionario”",
send_welcome_legend: "Inviare al cliente l’email di benvenuto",
send_reminder_legend: "Inviare al cliente il promemoria dell'email di benvenuto",
inform_car_arrival_legend: "Informare il sistema di un ordine arrivato in concessionaria",
unread_notifications_legend: "Accesso diretto ai messaggi dell’importatore che non sono stati ancora letti",
reregister_legend: "Assegnare un ordine annullato a un nuovo cliente. Ricrearlo.",
send_welcome_email_tooltip: "Invia email di benvenuto",
send_reminder_tooltip: "Invia promemoria",
send_push_notification_tooltip: "Invia avviso urgente",
inform_car_arrival_tooltip: "Informa arrivo auto in concessionaria",
reregister_this_order_tooltip: "Crea nuovamente l’ordine",
log_out: "Log out",
register_order: "Crea un nuovo ordine",
download_excel: "Download Excel",
send_welcome_btn: "Invia email di benvenuto",
send_reminder_btn: "Invia email di promemoria",
send_notification_btn: "Invia notifica",
inform_car_arrival_btn: "Informaci",
show_results_btn: "Mostra risultati",
cancel_btn: "Annulla",
confirm_btn: "Conferma",
submit_registration_btn: "Inoltra",
print_btn: "Stampa pdf",
clear_btn: "CANCELLA",
search_text: "Codice di tracciamento,  numero di commissione, VIN o email",
trackline_tab_title: "Stato",
PRE_A500_short: "Ordine ricevuto",
PRODUCTION_STARTS_short: "Inizio produzione",
PRODUCTION_ENDS_short: "Fine produzione",
FACTORY_DEPART_short: "Uscita dalla fabbrica",
COMPOUND_ARRIVAL_short: "Arrivo in deposito",
COMPOUND_DEPART_short: "Uscita dal deposito",
DEALER_ARRIVAL_short: "Arrivo in concessionaria",
AAK_short: "notifica di vendita",
PRE_A500: "Ordine ricevuto",
PRODUCTION_STARTS: "Inizio produzione",
PRODUCTION_ENDS: "Fine produzione",
FACTORY_DEPART: "L’auto lascia la fabbrica",
COMPOUND_ARRIVAL: "L’auto arriva in deposito",
COMPOUND_DEPART: "L’auto lascia il deposito",
DEALER_ARRIVAL: "L’auto arriva in concessionaria",
AAK: "notifica di vendita",
filter_tab_title: "Filtri",
pending_actions: "Azioni in sospeso",
order_date_range: "Intervallo date degli ordini",
delivery_date_range: "Intervallo date di consegna",
active_orders: "Ordini attivi",
order_history: "Storico ordine",
tracking_id: "Codice di tracciamento",
status: "Stato",
model: "Modello",
last_status_change: "Ultimo cambio di stato",
order_date: "Data dell'ordine",
cm_code: "Codice CUPRA Master",
dealer_code_short: "Concessionario",
delivery_date: "Data di consegna",
delivered_on_time: "Consegnato in tempo",
yes: "Sì",
no: "No",
days_until_delivery: "Giorni fino alla consegna",
back: "indietro",
commission_nr: "Numero di commissione",
tmaimg: "TMAIMG",
engine: "Motore",
trim: "Assetto",
color: "Colore",
prs: "PR",
client_tracking_view: "Vista tracciamento del cliente",
orderReceived: "Ordine ricevuto",
production: "Processo di produzione",
dealer: "Arrivo in concessionaria",
delivered: "Sondaggio",
pending: "In sospeso",
sent_notifications_list: "Notifiche inviate",
market_notifications_list: "Avvisi importatore",
completed_orders: "Ordini completati",
register_title: "Crea ordine",
register_explanation: "Dopo aver registrato l’acquisto, questo ordine verrà assegnato a te e alla tua concessionaria. Sarà possibile inviare l’email di benvenuto al cliente.",
welcome_mail_title: "Invia email di benvenuto",
client_mail_field: "Email cliente",
client_mail_placeholder: "example@example.ex",
reminder_title: "Invia promemoria dell’email di benvenuto",
send_arrival_notification_title: "Inviare la notifica “l’auto è dal concessionario”.",
inform_car_arrival_title: "Quest’auto è arrivata dal concessionario!",
inform_car_arrival_explanation: "Dato che potresti essere il primo a sapere dell’arrivo, ti preghiamo di informarci.<br> Confermare che l'auto con codice di tracciamento %trackingId% è arrivata al concessionario?",
welcome_mail_confirmation_title: "Email di benvenuto inviata al cliente",
welcome_mail_confirmation_explanation: "Se il cliente ha autorizzato l'invio di email, riceverà la notifica via email. Per sapere se il cliente ha già fatto accesso al sistema di tracciamento, controllare in Dettagli dell'ordine.",
order_status: "Stato dell'ordine",
ok_btn: "OK",
subject: "Oggetto",
body: "Testo",
purchaseDate: "Data dell'ordine",
CMCode: "Codice CUPRA Master",
dealerCode: "Concessionario",
deliveryDate: "Data di consegna",
deliveredOnTime: "Consegnato in tempo",
filters: "Filtri",
models: "Modello",
trackingId: "Codice di tracciamento",
pr: "PR",
delayed: "Auto in ritardo",
trackingDays: "Giorni fino alla consegna",
lastStatusChange: "Ultimo cambio di stato",
trackingStatus: "Stato",
done: "Terminato",
date: "Data",
PR: "PR",
pendingActions: "Task da svolgere",
welcome_footer: "-",
successful_generic_title: "Terminato!",
successful_generic_text: "-",
wrong_generic_title: "Oops qualcosa non ha funzionato",
wrong_generic_text: "Riprova",
activeOrders: "Ordini attivi",
delayedOrders: "Auto in ritardo",
activeCM: "CUPRA Master attivi",
clear: "Cancella",
de_AT: "Deutsch (AT)",
de_CH: "Deutsch (CH)",
de_DE: "Deutsch (DE)",
en_GB: "English (GB)",
es_ES: "Español (ES)",
fr_CH: "Français (CH)",
fr_FR: "Français (FR)",
it_CH: "Italiano (CH)",
it_IT: "Italiano (IT)",
nl_NL: "Nederlands (NL)",
close_btn: "Chiudi",
reregistration_available: "Ri-registrazione disponibile",
pending_reminders: "Promemoria email di sollecito",
CM_to_customer_sent_notifications_list: "Notifica spedita al cliente dal CUPRA Master",
your_notifications_to_CM: "Notifica al CUPRA specialist",
read_notification_title: "Leggi questa notifica",
save_btn: "Salva",
print_preview_title: "Anteprima di Stampa",
preview_title: "Anteprima",
turn_your_device_to_landscape_mode: "cambia il tuo dispositivo  in modalita' panorama",
dealer_code_input_explanation: "Introduci il tuo codice KVPS",
tracking_id_input_explanation: "Codice di tracciamento (par ex, 2366512642021) = Num. comissione (236651) + BID paese (223) + Anno del veicolo (2021)",
download_btn: "Scarica",
pending_reminder: "E-mail pending",
cm_pending_reminder_info_on_click: "Inoltra nuovamente la mail di benvenuto ai clienti Unengaged",
sm_pending_reminder_info_on_click: "Inoltra nuovamente la mail di benvenuto ai clienti Unengaged",
authenticate_as_sales_manager: "Autenticati come Responsabile Vendite",
authenticate_as_sales_manager_title: "Autenticati come Responsabile Vendite",
authenticate_as_sales_manager_explanation: "",
assign_cupra_master_legend: "Assegna un CUPRA Garage a un ordine",
assign_cupra_master_title: "Assegna un CUPRA Garage",
assign_cupra_master_explanation: "Assegna un CUPRA Garage a questo ordine",
assign_btn: "Assegna",
authenticate_btn: "Autentica",
send_massive_notification_btn: "Invia una notifica",
send_massive_notification_title: "Invia una notifica",
send_massive_notification_explanation: "Agli ordini selezionati",
login_btn: "Accedi",
redirected_title: "Hai effettuato il log-in?",
redirected_body: "Clicca continua. Altrimenti verifica se il tuo browser sta bloccando l'apertura della pagina di accesso",
preview_btn: "Preview",
reregister_explanation: "Assegna un ordine cancellato a un nuovo Cliente. Registralo di nuovo",
reregister_title: "Registralo di nuovo",
welcomeMessageSent: "Mail di benvenuto inviata",
pendingToDeliver: "Ordini da consegnare",
send_mk_notification_tooltip: "Invia una notifica",
download_excel_title: "Scarica la lista",
download_excel_explanation: "La selezione è basata sui tuoi filtri",
selection_massive_this_page_part_1: "Tutti i <strong>%PAGE_LENGTH%</strong> ordini in questa pagina sono stati selezionati",
selection_massive_this_page_part_2: "Seleziona tutti %ORDERS_TOTAL% ordini",
selection_massive_all_pages_part_1: "Tutti %ORDERS_TOTAL% sono selezionati",
selection_massive_all_pages_part_2: "Cancella la selezione",
send_notification_title: "Invia una notifica",
choose_cupra_master: "Seleziona un CUPRA Garage",
pendingReminders: "Promemoria email di sollecito",
send_mk_notification_legend: "Invia le notifiche di un ordine a un CUPRA Garage",
mk_unread_notifications_legend: "Queste notifiche non sono state lette dal CUPRA Garage corrispondente",
turn_your_device_to_portrait_mode: "Imposta il tuo device in modalità ritratto",
more_info: "Maggiori informazioni",
apply_filters_mobile_btn: "Applica i filtri",
cmName: "CUPRA Master",
logout: "Esci",
sort_by: "Ordina per",
order_details: "Dettagli dell'ordine",
dealer_or_importer_code: "Codice CUPRA Garage o Importatore",
not_found: "Non trovato",
searching: "Sto cercando",
pendingManualReminder: "Promemoria email di sollecito",
unassigned_seller: "CUPRA Master non assegnato",
unassignedSeller: "CUPRA Master non assegnato",
countryCode: "Codice ISO del paese",
vin: "VIN",
hq_my_tasks_title: "-",
show_hq_summary_btn: "-",
hide_hq_summary_btn: "-",
hq_possible_delayed_market_orders_info_on_click: "-",
hq_delayed_cars_info_on_click: "-",
hq_unread_notifications_info_on_click: "-",
hq_pending_actions: "-",
country: "-",
email: "Email",
search_long_explanation: "Ricerca per codice di tracciamento, numero di commissione, VIN o email",
mk_notifications_to_CM: "-",
possibleDelayNotDelayed: "",
tracking_tab: "",
search: "",
time_period: "",
time_week: "",
time_ytd: "",
time_month: "",
time_from: "",
time_until: ""
}