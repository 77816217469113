<template>
  <div class="toggle-select" @click="toggle">
    <span>{{ options[0].label }}</span>
    <label class="switch">
      <input v-model="value" type="checkbox" value="test">
      <div class="slider round" />
    </label>
    <span>{{ options[1].label }}</span>
  </div>
</template>

<script>
export default {
  name: 'ToggleSelect',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: { required: true },
    options: { required: true },
  },
  data() {
    return {
      option: false,
    }
  },
  methods: {
    toggle(e) {
      e.preventDefault()
      this.option = !this.option
      this.option ? this.$emit('change', this.option) : this.$emit('change', null)
      //e.currentTarget.getElementsByTagName('input')[0].checked = this.option
    },
  },
}
</script>
<style lang="stylus" scoped>
.toggle-select
  position: relative
  display: flex
  justify-content: space-between
  align-items: center
  min-height: 50px
  border-bottom: 1px solid $alto
  cursor: pointer

  .mobile &
    min-height: 60px

  span
    m-ellipsis(calc(100% - 35px))
    m-font-size(14, 20)
    text-transform: uppercase
    font-weight: $fw-light

.switch
  position: relative
  display: inline-block
  margin: 0 10px
  width: 40px
  height: 20px

.switch input
  width: 0
  height: 0
  opacity: 0

.slider
  position: absolute
  top: 0
  right: 0
  bottom: 0
  left: 0
  border: 1px solid #ccc
  background-color: #FFF
  cursor: pointer
  transition: 0.2s
  -webkit-transition: 0.2s

.slider:before
  position: absolute
  top: 2px
  bottom: 1px
  left: 2px
  width: 14px
  height: 14px
  background-color: #ccc
  content: ""
  transition: 0.2s
  transform: translateX(20px)
  -webkit-transition: 0.2s
  -webkit-transform: translateX(18px)
  -ms-transform: translateX(20px)

input:checked + .slider
  background-color: #EEE

input:focus + .slider
  box-shadow: none

input:checked + .slider:before
  transform: translateX(0)
  -webkit-transform: translateX(0)
  -ms-transform: translateX(0)

/* Rounded sliders */
.slider.round
  border-radius: 34px

.slider.round:before
  border-radius: 50%
</style>