// import LoginService from '@/services/LoginService'
import { getUserInfo } from '@/api'
// import router from '@/router'
import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    userData: {},
  },
  getters: {},
  actions: {
    loadUserInfo({ dispatch, state, commit }, data) {
      return getUserInfo()
        .then(response => {
          if (response.status === 401) {
            console.warn('WAITING FOR EXTERNAL LOGIN')
            return response
          } else if (response.status === 403) {
            return response
          } else {
            return dispatch('setUserInfo', response.data)
          }
        })
        .catch(error => {
          console.log('should never happen (2)', error)
          reject(error)
        })
    },
    resetUserInfo({ dispatch, state, commit }) {
      commit('setUserData', {})
    },
    setUserInfo({ dispatch, state, commit }, data) {
      let user = data
      dispatch('i18n/setLanguage', user.lanCode || 'en-GB', { root: true })

      //tune it ANNA
      if (user.roles && user.roles.find(el => el.roleId === 'HEADQUARTER')) {
        user.role = 'HQ'
      } else if (user.roles && user.roles.find(el => el.roleId === 'IMPORTER')) {
        user.role = 'MK'
      } else if (user.roles && user.roles.find(el => el.roleId === 'SALESMANAGER')) {
        user.role = 'SM'
      } else {
        user.role = 'CM'
      }
      // user.role = 'MK'
      // delete user.countryId
      commit('setUserData', user)

      let promises = []
      promises.push(dispatch('countries/load', { root: true }, { root: true }))
      if (user.role === 'HQ' || (user.dealerCode && user.countryId)) {
        promises.push(dispatch('summary/load', { root: true }, { root: true }))
        promises.push(dispatch('offers/load', { root: true }, { root: true }))
      }
      return Promise.all(promises)
    },
    changeRoleDemo({ dispatch, state, commit }, data) {
      commit('changeRoleDemo', data)
    },
  },
  mutations: {
    setUserData(state, data) {
      state.userData = data
    },
    changeRoleDemo(state, data) {
      state.userData.role = data
    },
  },
}
