import { getCountries } from '@/api'

export default {
  namespaced: true,
  state: {
    data: null,
    loaded: false
  },
  actions: {
    load ({state, getters, commit, rootState, rootGetters}, params) {
      state.loaded = false

      return getCountries().then((response) => {
        var data = response.data
        commit('setData', data)
        return true
      })
    }
  },
  mutations: {
    setData (state, data) {
      state.loaded = true
      state.data = data
    }
  }
}
