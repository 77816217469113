<template>
  <div :class="['order-mobile-card', historyCard ? 'history-card': 'active-card']">
    <div class="card-wrapper" :style="{transform: cardTransform}">
      <div class="center">
        <div class="content-wrapper">
          <div v-if="!historyCard && role==='MK' && isSearchedOrder!=true" class="market-check">
            <div :class="['check-box', internalValue ? 'selected': 'unselected']" @click="internalValue = !internalValue" />
          </div>
          <div
            class="content"
            @click="() => { if(isSearchedOrder!=true) { goTo('details', order.trackingId) } else { $emit('goToResult')}}"
          >
            <div class="top">
              <div class="item trackingId"> {{ fieldFormat(order,'trackingId') }}</div>
              <div
                v-if="!historyCard"
                class="item anyDelay"
                :class="[ {'icon': order.anyDelay}, order.anyDelay && order.anyDelay.value && order.anyDelay.icon ? order.anyDelay.icon : '']"
              >
                {{ fieldFormat(order,'anyDelay') }}
              </div>
            </div>
            <div class="bottom">
              <div class="left">
                <div class="item purchaseDate"> {{ fieldFormat(order,'purchaseDate') }}</div>
                <div class="item-separator" />
                <div
                  v-if="!historyCard"
                  class="item trackingStatus"
                  :class="{'isPaarl': moment().diff(moment(order['lastStatusChange']), 'days') < 2 }"
                >
                  {{ fieldFormat(order,'trackingStatus') }}
                </div><!---->
                <div v-if="historyCard" class="item deliveryDate">{{ fieldFormat(order,'deliveryDate') }}</div>
              </div>
              <div v-if="historyCard" class="right">
                <div class="item trackingDays">{{ fieldFormat(order,'trackingDays') }}</div>
                <div
                  v-if="order.delayedIconXOrderHistory"
                  class="item delayedIconXOrderHistory"
                  :class="[ order.delayedIconXOrderHistory ? 'icon alarm' : '']"
                >
                  {{ fieldFormat(order, 'delayedIconXOrderHistory') }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="!historyCard && isSearchedOrder!=true"
          :class="['actions-swipe', {'show': order.pendingActions.length > 0}, {'open': openActions}]"
          @click="$emit('toggle', !openActions)"
        />
      </div>
      <div ref="actionsContainer" :class="['actions-container']">
        <div
          v-for="action in order.pendingActions"
          :key="action.id"
          :class="['icon', action.icon]"
          @click="doAction(action,order)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import formatOrderMixin from '@/components/formatOrderMixin'

export default {
  name: 'OrderMobileCard',
  mixins: [formatOrderMixin],
  model: {
    prop: 'checked',
    event: 'change',
  },
  props: {
    role: String,
    checked: Boolean, // { required: true},
    order: Object,
    tab: String,
    openActions: Boolean,
    isSearchedOrder: Boolean,
  },
  data() {
    return {
      showActions: false,
    }
  },
  computed: {
    historyCard() {
      // if (this.tab === 'orderHistory') return true
      if (this.order.trackingStatus === 'AAK') return true
      else return false
    },
    internalValue: {
      get() {
        return this.checked
      },
      set(newValue) {
        this.$emit('change', newValue)
      },
    },
    cardTransform() {
      if (this.openActions)
        return `translateX(-${this.$refs.actionsContainer.getBoundingClientRect().width}px)`
      else return `translate(0,0)`
    },
  },
  methods: {},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
.order-mobile-card
  display: flex
  align-items: center
  height: 70px
  border-bottom: 2px solid $wild-sand

.card-wrapper
  display: flex
  flex: 0 0 100%
  align-items: center
  width: 100%
  transition: transform 0.4s ease-in-out

  .history-card &
    overflow: hidden

.actions-container
  height: 70px

  .icon
    width: 68px !important
    height: 68px !important

    &:before
      background-color: #fff

      .mobile &
        background-color: #fff

.center
  display: flex
  flex: 0 0 100%
  align-items: center
  overflow: visible

.content-wrapper
  display: flex
  flex: 1 1 0%
  align-items: flex-start

  .market-check
    padding-left: 15px

    .mobile &
      margin-top: 0.25em

    .tracklineView &
      margin-right: 15px
      padding-left: 0

    .check-box
      m-icon("checkbox-empty", 14)

      &.selected
        // color: blue
        m-icon("checkbox-checked", 14)

      &.unselected
        // color: red
        m-icon("checkbox-empty", 14)

  .content
    display: flex
    flex-direction: column
    justify-content: center

    .item
      &:first-child
        padding-right: 0
        padding-left: 15px

        .trackline-wrapper &
          padding-left: 0

      &.trackingId
        m-font-size(16, 20)

      &.purchaseDate
        color: $rolling-stone

      &.icon
        align-self: center
        margin-left: 5px
        width: 14px !important
        height: 14px !important

    .top
      display: flex
      align-items: center
      m-font-size(16, 19)
      margin-bottom: 10px

    .bottom
      display: flex
      m-font-size(14, 18)

      .left
        display: flex
        align-items: center

        .item-separator
          margin: 0 10px
          width: 4px
          height: 4px
          border-radius: 50%
          background-color: #000

          .history-card &
            background-color: transparent
            m-icon("arrow-history", 30 8)

      .right
        display: flex
        margin-right: 15px

.actions-swipe
  display: none
  background-position: center 15px !important
  background-size: 25px 25px !important
  m-icon("swipe", 68)
  transition: transform 0.25s ease-in-out
  transform: rotate(90deg)

  &.show
    display: block

  &.open
    background-position: center 25px !important
    transform: rotate(270deg)

>>> .delayedIconXOrderHistory
  &.icon
    m-icon("alarm-small-cyprus", 15)

>>> .anyDelay
  &.icon
    &.alarm
      m-icon("alarm-small-cyprus", 15)

    &.alarm-red
      m-icon("alarm-small-red", 15)

>>> .isPaarl
  color: $paarl
  font-weight: $fw-medium

>>> .actions-container
  display: flex
  justify-content: flex-end
  align-items: center
  height: 58px

  .icon
    position: relative
    margin-left: 6px
    border: 1px solid $cyprus
    background-color: #fff
    background-size: 50% 50% !important
    cursor: pointer

    &:before
      position: absolute
      top: -1px
      left: -7px
      display: block
      width: 6px
      height: calc(100% + 2px)
      background-color: #fff
      content: ""

    &.bell
      border-color: $paarl
      background-size: 45% 45% !important
      m-icon("bell-paarl", 60)

    &.re-register
      m-icon("re-register-cyprus", 60)

    &.mail
      background-color: $cyprus
      m-icon("mail-white", 60)

    &.paper-plane
      m-icon("paper-plane-cyprus", 60)

    &.reminder
      background-size: 55% 55% !important
      m-icon("reminder-cyprus", 60)

    &.grey-reminder
      border-color: $alto
      background-color: $gallery
      m-icon("reminder-alto", 60)
      pointer-events: none

    &.car
      m-icon("car-cyprus", 60)

    &.assign-cm
      background-size: 54% 56% !important
      m-icon("assign-cm-cyprus", 60)
</style>
