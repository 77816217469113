export default {
html_title: "Outil de traçage",
welcome_title: "Bienvenue dans l’outil de traçage CUPRA !",
welcome_body: "-",
continue_btn: "Continuer",
welcome_body_first_time: "C’est votre première connexion et nous avons besoin de quelques informations. Ces informations ne vous seront demandées qu’une fois. <br><br>Veuillez remplir les champs suivants:",
country_code: "Code ISO pays",
dealer_code: "Code concessionnaire",
hide_summary_btn: "Masquer mes tâches",
show_summary_btn: "Afficher mes tâches",
hide_funnel_btn: "Masquer l’entonnoir",
show_funnel_btn: "Afficher l’entonnoir",
my_tasks_title: "Mes tâches",
funnel_title: "Entonnoir",
pending_welcome_emails: "E-mails de bienvenue en attente",
pendingWelcome: "E-mails de bienvenue en attente",
cm_pending_welcome_info_on_click: "Envoyer les informations d’accès au client",
sm_pending_welcome_info_on_click: "Envoyer les informations d’accès au client",
unengaged_clients: "Clients non impliqués",
unengagedClients: "Clients non impliqués",
cm_unengaged_clients_info_on_click: "Ces clients n’ont jamais accédé à l’outil de traçage",
sm_unengaged_clients_info_on_click: "Ces clients n’ont jamais accédé à l’outil de traçage",
mk_unengaged_clients_info_on_click: "Ces clients n’ont jamais accédé à l’outil de traçage",
pushable_notifications: "Notifications d’arrivée en attente",
pushableNotifications: "Notifications d’arrivée en attente",
cm_pushable_notifications_info_on_click: "Informer le client que la voiture est chez le concessionnaire",
sm_pushable_notifications_info_on_click: "Informer le client que la voiture est chez le concessionnaire",
unread_notifications: "Notifications non lues",
unreadNotifications: "Notifications non lues",
cm_unread_notifications_info_on_click: "Vous n’avez pas encore lu ces messages",
sm_unread_notifications_info_on_click: "Vos CUPRA Masters n’ont pas encore lu ces messages",
mk_unread_notifications_info_on_click: "Ces notifications n’ont pas été lues par le CUPRA Master correspondant",
possible_delayed_orders: "Possibles retards de commande",
possibleDelayedOrders: "Possibles retards de commande",
cm_possible_delayed_orders_info_on_click: "Ces commandes actives ont été créées il y a plus de 15 jours.",
sm_possible_delayed_orders_info_on_click: "Ces commandes actives ont été créées il y a plus de 15 jours.",
possible_delayed_market_orders: "Possibles retards de commande",
possibleDelayedMarketOrders: "Possibles retards de commande",
mk_possible_delayed_market_orders_info_on_click: "Ces commandes actives ont été créées il y a plus de 15 jours et ne sont pas chez le concessionnaire.",
delayed_cars: "Voitures retardées",
delayedCars: "Voitures retardées",
mk_delayed_cars_info_on_click: "Ces commandes actives ont été créées il y a plus de 21 jours et n’ont pas encore été livrées.",
total_active_orders: "Commandes actives",
totalActiveOrders: "Commandes actives",
mk_total_active_orders_info_on_click: "Commandes créées non livrées",
welcome_email_sent: "E-mails de bienvenue envoyés",
welcomeEmailSent: "E-mails de bienvenue envoyés",
mk_welcome_email_sent: "Rapport entre les commandes actives avec e-mail de bienvenue envoyé et le total",
pending_deliveries: "Livraison en attente",
pendingDeliveries: "Livraison en attente",
mk_pending_deliveries_info_on_click: "Commande chez le concessionnaire en attente d’Aak",
cm_active: "CUPRA Masters actifs dans cet outil",
cmActives: "CUPRA Masters actifs dans cet outil",
mk_cm_active: "Un CUPRA Master est actif s’il/elle s’est connecté au cours de la dernière semaine.",
legend_title: "Informations concernant vos possibles actions",
send_notification_legend: "Envoyer une notification « la voiture est chez le concessionnaire » au client",
send_welcome_legend: "Envoyer un e-mail de bienvenue au client",
send_reminder_legend: "Envoyer un rappel de l’e-mail de bienvenue au client",
inform_car_arrival_legend: "Informer le système qu’une commande est arrivée chez le concessionnaire",
unread_notifications_legend: "Accès direct aux messages de l’importateur que vous n’avez pas encore lus",
reregister_legend: "Assigner une commande annulée à un nouveau client. La réenregistrer.",
send_welcome_email_tooltip: "Envoyer l’e-mail de bienvenue",
send_reminder_tooltip: "Envoyer le rappel",
send_push_notification_tooltip: "Envoyer la notification Push",
inform_car_arrival_tooltip: "Informe de l’arrivée de la voiture chez le concessionnaire",
reregister_this_order_tooltip: "Réenregistrer cette commande",
log_out: "Log out",
register_order: "Enregistrer une nouvelle commande",
download_excel: "Télécharger l’Excel",
send_welcome_btn: "Envoyer l’e-mail de bienvenue",
send_reminder_btn: "Envoyer l’e-mail de rappel",
send_notification_btn: "Envoyer notification",
inform_car_arrival_btn: "Nous informer",
show_results_btn: "Afficher les résultats",
cancel_btn: "Annuler",
confirm_btn: "Confirmer",
submit_registration_btn: "Envoyer l’enregistrement",
print_btn: "Imprimer PDF",
clear_btn: "EFFACER",
search_text: "ID de traçage, numéro de commission, VIN ou e-mail",
trackline_tab_title: "Ligne de suivi",
PRE_A500_short: "Commande reçue",
PRODUCTION_STARTS_short: "Début de production",
PRODUCTION_ENDS_short: "Fin de production",
FACTORY_DEPART_short: "Quitte l’usine",
COMPOUND_ARRIVAL_short: "Arrive au parc de stockage",
COMPOUND_DEPART_short: "Quitte le parc de stockage",
DEALER_ARRIVAL_short: "Arrive chez le concessionnaire",
AAK_short: "AaK",
PRE_A500: "Commande reçue",
PRODUCTION_STARTS: "Début de production",
PRODUCTION_ENDS: "Fin de production",
FACTORY_DEPART: "La voiture quitte l’usine",
COMPOUND_ARRIVAL: "La voiture arrive au parc de stockage",
COMPOUND_DEPART: "La voiture quitte le parc de stockage",
DEALER_ARRIVAL: "La voiture arrive chez le concessionnaire",
AAK: "AaK",
filter_tab_title: "Filtres",
pending_actions: "Actions en attente",
order_date_range: "Période de commande",
delivery_date_range: "Période de livraison",
active_orders: "Commandes actives",
order_history: "Historique de commande",
tracking_id: "ID de traçage",
status: "État",
model: "Modèle",
last_status_change: "Dernier changement d’état",
order_date: "Date de commande",
cm_code: "Code CUPRA Master",
dealer_code_short: "Concessionnaire",
delivery_date: "Date de livraison",
delivered_on_time: "Livré dans les délais",
yes: "Oui",
no: "Non",
days_until_delivery: "Jours avant la livraison",
back: "retour",
commission_nr: "Numéro de commission",
tmaimg: "TMAIMG",
engine: "Moteur",
trim: "Finition",
color: "Couleur",
prs: "Numéro PR",
client_tracking_view: "Affichage de traçage client",
orderReceived: "Commande reçue",
production: "Processus de production",
dealer: "Arrivée chez le concessionnaire",
delivered: "Enquête de satisfaction",
pending: "En attente",
sent_notifications_list: "Notifications envoyées",
market_notifications_list: "Avis de l’importateur",
completed_orders: "Commandes complétées",
register_title: "Enregistrer une commande",
register_explanation: "Une fois votre achat enregistré, cette commande vous sera assignée à vous et votre concessionnaire. Vous pourrez envoyer l’e-mail de bienvenue correspondant au client.",
welcome_mail_title: "Envoyer l’e-mail de bienvenue",
client_mail_field: "E-mail client",
client_mail_placeholder: "example@example.ex",
reminder_title: "Envoyer l’e-mail de rappel de bienvenue",
send_arrival_notification_title: "Envoyer la notification « la voiture est chez le concessionnaire »",
inform_car_arrival_title: "Cette voiture est arrivée chez le concessionnaire !",
inform_car_arrival_explanation: "Étant donné que vous serez peut-être le premier informé de cette arrivée, nous vous prions de nous le notifier.<br> Souhaitez-vous confirmer que la voiture dont l’ID de traçage est %trackingId% est arrivée chez votre concessionnaire ?",
welcome_mail_confirmation_title: "E-mail de bienvenue envoyé au client",
welcome_mail_confirmation_explanation: "Si votre client a autorisé la réception d’e-mails, il/elle recevra la notification par e-mail. Pour voir si le/la client(e) a déjà accédé à son outil de traçage, consultez l’onglet « Détails de commandes ».",
order_status: "État de la commande",
ok_btn: "OK",
subject: "Sujet",
body: "Body",
purchaseDate: "Date de commande",
CMCode: "Code CUPRA Master",
dealerCode: "Concessionnaire",
deliveryDate: "Date de livraison",
deliveredOnTime: "Livré dans les délais",
filters: "Filtres",
models: "Modèle",
trackingId: "ID de traçage",
pr: "Numéro PR",
delayed: "Voitures retardées",
trackingDays: "Jours avant la livraison",
lastStatusChange: "Dernier changement d’état",
trackingStatus: "Status",
done: "Réglé",
date: "Date",
PR: "Numéro PR",
pendingActions: "Tâches",
welcome_footer: "-",
successful_generic_title: "Réglé",
successful_generic_text: "-",
wrong_generic_title: "Oops quelque chose a mal tourné",
wrong_generic_text: "Essayer à nouveau.<br><br> Le serveur ne répon pas. <br>",
activeOrders: "Commandes actives",
delayedOrders: "Voitures retardées",
activeCM: "Active CUPRA Specialists",
clear: "Supprimer",
de_AT: "Deutsch (AT)",
de_CH: "Deutsch (CH)",
de_DE: "Deutsch (DE)",
en_GB: "English (GB)",
es_ES: "Español (ES)",
fr_CH: "Français (CH)",
fr_FR: "Français (FR)",
it_CH: "Italiano (CH)",
it_IT: "Italiano (IT)",
nl_NL: "Nederlands (NL)",
close_btn: "Fermer",
reregistration_available: "Réenregistrement possible",
pending_reminders: "Rappels ouvert",
CM_to_customer_sent_notifications_list: "Mails envoyés par le vendeur au client",
your_notifications_to_CM: "Ta message au vendeur",
read_notification_title: "Lire la notification",
save_btn: "Sauvegarder",
print_preview_title: "Apercçu de l'impression",
preview_title: "L'impression",
turn_your_device_to_landscape_mode: "Tournet votre appareil sur le côté pour visualiser le site web en mode paysage",
dealer_code_input_explanation: "Entrez votre numéro de distributeur",
tracking_id_input_explanation: "ID de traçage (ex. 2366529952021) =  Numéro commission (236652) + BID (995) + année de fabrication(2021)",
download_btn: "Download",
pending_reminder: "E-Mails de rappel en suspens",
cm_pending_reminder_info_on_click: "Renvoyer l'e-mail aux clients non encore enregistrés",
sm_pending_reminder_info_on_click: "Renvoyer l'e-mail aux clients non encore enregistrés",
authenticate_as_sales_manager: "S'authentifier en tant que chef de vente",
authenticate_as_sales_manager_title: "S'authentifier en tant que chef de vente",
authenticate_as_sales_manager_explanation: "",
assign_cupra_master_legend: "Attribution d'une commande à un CUPRA Master",
assign_cupra_master_title: "Attribuer un CUPRA Master",
assign_cupra_master_explanation: "Attribution cette commande à un CUPRA Master",
assign_btn: "Attribuer  ",
authenticate_btn: "Authentifier",
send_massive_notification_btn: "Envoyer un message",
send_massive_notification_title: "Envoyer un message",
send_massive_notification_explanation: "Aus commandes sélectionnés",
login_btn: "Accès",
redirected_title: "Vous ètes-vous inscrit sur le porteil de concessionnaires?",
redirected_body: "Veuillez continuer à cliquer. Sinon, vérifiez si votre navigateur bloque l fenêtre pop-up.",
preview_btn: "aperçu",
reregister_explanation: "Affecter au bon de commande annulé à un nouveau client. Entrez à nouveau.",
reregister_title: "Réenregistrer",
welcomeMessageSent: "Envoyé des courriels de beinvenue",
pendingToDeliver: "Les commandes ouvertes à livrer",
send_mk_notification_tooltip: "Messages envoyés",
download_excel_title: "Liste de téléchargement",
download_excel_explanation: "La sélection de la liste est basée sur votre filtre.",
selection_massive_this_page_part_1: "Toutes <strong>%PAGE_LENGTH%</strong> les commandes sur cette page sont sélectionnées",
selection_massive_this_page_part_2: "Sélectionner toutes %ORDERS_TOTAL% les commandes",
selection_massive_all_pages_part_1: "Toutes les %ORDERS_TOTAL% commandes sont sélectionnées",
selection_massive_all_pages_part_2: "Supprimer la sélection",
send_notification_title: "Envoyer un message",
choose_cupra_master: "Choisir und CUPRA Master de votre entreprise",
pendingReminders: "Courriels de rappel en suspens",
send_mk_notification_legend: "Envoyer des messages au CUPRA Master",
mk_unread_notifications_legend: "Ces messages n'ot pas encore été lus par le CUPRA specialiste",
turn_your_device_to_portrait_mode: "Tournet votre appareil sur le côté pour visualiser le site web en mode paysage",
more_info: "Plus d'informations",
apply_filters_mobile_btn: "Appliquer le filtre",
cmName: "CUPRA Master",
logout: "Se déconnecter",
sort_by: "Trier par",
order_details: "Détails de la commande",
dealer_or_importer_code: "Numéro du concessionnaire",
not_found: "Pas trouvé",
searching: "Rechercher...",
pendingManualReminder: "Courriers de rappel en suspens",
unassigned_seller: "CUPRA Master non assigné",
unassignedSeller: "CUPRA Master non assigné",
countryCode: "Code ISO pays",
vin: "VIN",
hq_my_tasks_title: "-",
show_hq_summary_btn: "-",
hide_hq_summary_btn: "-",
hq_possible_delayed_market_orders_info_on_click: "-",
hq_delayed_cars_info_on_click: "-",
hq_unread_notifications_info_on_click: "-",
hq_pending_actions: "-",
country: "-",
email: "E-mail",
search_long_explanation: "Rechercher par ID de traçage, numéro de commission, VIN ou e-mail",
mk_notifications_to_CM: "-",
possibleDelayNotDelayed: "",
tracking_tab: "",
search: "",
time_period: "",
time_week: "",
time_ytd: "",
time_month: "",
time_from: "",
time_until: ""
}