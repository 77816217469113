<template>
  <div class="register">
    <div class="text" v-html="$t(data.body)"></div>
    <InputWithLabel
      component="InputComp"
      v-model="internalValue"
      :label="$t('tracking_id')+'*'"
      :explanation="$t('tracking_id_input_explanation')">
    </InputWithLabel>
  </div>
</template>

<script>
import InputWithLabel from '@/components/InputWithLabel.vue'
import modalContentMixin from '@/components/modals/modalContentMixin'
export default {
  name: 'Register',
  components: {
    InputWithLabel,
  },
  mixins: [modalContentMixin],
  data() {
    return {
      internalValue: null,
      rules: {
        length: len => v =>
          (v || '').length === len || `Invalid trackingId length, required ${len}`,
      },
    }
  },
  props: ['data'],
  methods: {
    validate(data) {
      if (data && data.trackingId && this.rules.length(13)(data.trackingId) === true) {
        return true
      }
      return false
    },
  },
  computed: {
    contentValue() {
      return { trackingId: this.internalValue }
    },
  },
}
</script>

<style scoped lang="stylus">
.text
  margin-bottom: 20px
</style>