<template>
  <div class="CompleteUserAccessInfo section body ">
    <template v-if="!countriesReady">
      <Loader />
    </template>
    <template v-else>
      <div class="title">{{ $t('welcome_title') }}</div>
      <div class="text" v-html="$t('welcome_body_first_time')" />
      <InputWithLabel
        v-model="dealerCode"
        component="InputComp"
        :label="$t('dealer_or_importer_code')+'*'"
        :explanation="$t('dealer_code_input_explanation')"
      />
      <InputWithLabel
        v-model="countrySelected"
        component="SelectComp"
        :label="$t('countryCode')+'*'"
        :configs="{items: countries, multiple : false, itemText : 'countryCode', itemValue: 'id'}"
      />
      <div v-if="errorMsg" class="errorMsg">
        <div class="title">{{ $t('wrong_generic_title') }}:</div>
        <div class="message">{{ errorMsg }}</div>
      </div>
      <div :class="['btn', 'continue-btn', isDisabled ? 'disabled': '' ]" @click="confirmData().catch((e)=>e)">{{ $t('continue_btn') }}</div>
    </template>
  </div>
</template>

<script>
import { setAccessInfo } from '@/api'
import InputWithLabel from '@/components/InputWithLabel'
import Loader from '@/components/Loader'

export default {
  name: 'CompleteUserAccessInfo',
  components: {
    InputWithLabel,
    Loader,
  },
  data() {
    return {
      errorMsg: null,
      dealerCode: null,
      countrySelected: null,
      // countries: [
      //   { state: 'España', abbr: 'ES' },
      //   { state: 'Deutschland', abbr: 'DE' },
      //   { state: 'Nederland', abbr: 'NL' },
      //   { state: 'Österreich', abbr: 'AT' },
      //   { state: 'Swiss Confederation', abbr: 'CH' },
      //   { state: 'United Kingdom', abbr: 'GB' },
      //   { state: 'Italia', abbr: 'IT' },
      //   { state: 'France', abbr: 'FR' },
      // ],
      rules: {
        dealerCode: [val => this.isDealerCodeValid(val) || 'This field is required'],
      },
    }
  },
  computed: {
    countriesReady() {
      return this.$store.state.countries.loaded
    },
    countries() {
      return this.$store.state.countries.data
    },
    // user () {
    //   return this.$store.state.user.userData
    // },
    isDisabled() {
      return this.dealerCode === null || this.countrySelected === null
    },
    showDealerCodeCheckIcon() {
      return this.isDealerCodeValid(this.dealerCode)
    },
  },
  methods: {
    confirmData() {
      // /user/setAccessInfo
      this.errorMsg = null
      setAccessInfo({ code: this.dealerCode, countryId: this.countrySelected })
        .then(res => {
          this.$router.push({ name: 'active' })
        })
        .catch(error => {
          this.errorMsg =
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : error
        })
    },
    isDealerCodeValid(val) {
      return (val || '').length > 0
    },
  },
}
</script>

<style scoped lang="stylus">
.section
  display: flex
  flex-direction: column

  .input-with-label
    margin-bottom: 40px

  .title
    font-weight: $fw-light
    m-font-size(30, 40)
    margin-bottom: 30px

  .text
    font-weight: $fw-light
    m-font-size(16, 30)
    margin-bottom: 50px

  .errorMsg
    color: red
    font-weight: $fw-medium
    m-font-size(14, 30)
    margin-bottom: 20px

    .title
      font-weight: $fw-medium
      m-font-size(16, 30)
      margin-bottom: 5px
</style>
