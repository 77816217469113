<template>
  <div class="download">
    <div class="text" v-html="t(data.body)"></div>
    <!-- <InputWithLabel
      v-model="range"
      :label="$t('choose_date_range') + '*'"
      component="CalendarComp"
    ></InputWithLabel> -->
  </div>
</template>

<script>
import InputWithLabel from '@/components/InputWithLabel'
import modalContentMixin from '@/components/modals/modalContentMixin'

export default {
  name: 'Download',
  props: ['data'],
  components: {
    InputWithLabel,
  },
  mixins: [modalContentMixin],
  computed: {
    contentValue() {
      return true //{ range: this.range }
    },
  },
  methods: {
    // validate (data) {
    //   if (data && data.range && data.range.start && data.range.end) {
    //     return true
    //   }
    //   return false
    // }
  },
  data() {
    return {
      range: null,
    }
  },
}
</script>

<style scoped lang="stylus">
.text
  margin-bottom: 40px
</style>