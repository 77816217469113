<template>
  <div :class="['input-comp', isOpen ? 'open':'closed']">
    <input
      ref="input"
      v-model="internalValue"
    />
    <div v-if="explanation" class="explanation" v-html="explanation">
    </div>
  </div>
</template>

<script>
export default {
  name: 'InputComp',
  components: {},
  model: {
    prop: 'value',
    event: 'change',
  },
  props: ['value', 'isOpen', 'explanation'],
  data() {
    return {}
  },
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(newValue) {
        this.$emit('change', newValue)
      },
    },
  },
  methods: {},
  watch: {
    isOpen() {
      if (this.isOpen) {
        this.$nextTick(() => this.$refs.input.focus())
        // this.$refs.input.focus()
      }
    },
  },
}
</script>
<style lang="stylus" scoped>
.input-comp
  display: flex
  flex-direction: column
  height: 25px
  margin-top: 15px

  &.closed
    display: none

  .explanation
    margin-top: 10px
    m-font-size(12, 16)

    .mobile &
      margin-top: 5px
</style>