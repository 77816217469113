export default {
  model: {
    prop: 'content',
    event: 'change'
  },
  props: {
    content: { required: true}
  },
  methods: {
    getValue () {
      return this.contentValue
    }
  },
  watch: {
    contentValue: {
      handler (newVal) {
        if(!this.validate || this.validate(newVal)) this.$emit('change', newVal)
        else this.$emit('change', null)
      },
      deep: true,
      immediate: true
    }
  }
}
