<template>
  <div class="calendar-comp">
    <v-date-picker
      v-model="internalValue"
      :class="['calendar', isOpen ? 'open': 'closed']"
      :columns="1"
      :locale="$store.getters['i18n/getLocale']()"
      color="cupra"
      is-range
    />
    <div
      v-if="internalValue"
      :class="['icon-close', isOpen ? 'open': 'closed']"
      @click="internalValue = null"
    />
  </div>
</template>

<script>
export default {
  name: 'CalendarComp',
  components: {},
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: { required: true },
    isOpen: { required: true },
  },
  data() {
    return {}
  },
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(newValue) {
        this.$emit('change', newValue)
      },
    },
  },
  watch: {
    internalValue() {
      if (this.internalValue && this.internalValue.start && this.internalValue.end) {
        this.$emit('close')
      }
    },
  },
  methods: {},
}
</script>
<style lang="stylus" scoped>
.calendar-comp
  position: relative

  .calendar
    &.open
      position: absolute
      left: 0
      z-index: 100

    &.closed
      display: none

  .icon-close
    background-size: 85% 85% !important
    m-icon("close", 12)
    position: absolute
    top: -36px
    right: 8px
    z-index: 101

>>> .vc-container
  --cupra-100: #CCD8DB !important
  --cupra-200: #CCD8DB !important
  --cupra-300: #99B1B6 !important
  --cupra-400: #99B1B6 !important
  --cupra-500: #668A92 !important
  --cupra-600: #668A92 !important
  --cupra-700: #33636E !important
  --cupra-800: #003C4A !important
  --cupra-900: #003C4A !important
</style>