<template>
  <div class="reporting-info">
    <div class="text" v-html="$t(data.body)" />
  </div>
</template>

<script>
export default {
  name: 'ReportingInfo',
  props: ['data'],
}
</script>

<style scoped lang="stylus">
.text
  margin-bottom: 20px
</style>