<template>
  <div class="wrapper-404">
    <div class="container-404">
      <p>{{title}}</p>
      <div class="line"></div>
      <div :class="[ 'btn' ]"
        @click="$router.back()"
      >
        {{$t('back')}}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Error',
  props: ['errorMessage'],
  computed: {
    title() {
      if (this.errorMessage) {
        return this.errorMessage
      }
      return 'Error on server'
    },
  },
}
</script>
<style scoped lang="stylus">
.wrapper-404
  background-color: #fbfaf6
  font-family: "Cupra", sans-serif
  left: 50%
  margin: 0
  position: absolute
  text-align: center
  top: 50%
  transform: translate(-50%, -50%)

@media (max-width: 768px)
  .wrapper-404
    width: 90%

.container-404
  background-color: #fff
  padding: 80px 120px 40px

@media (max-width: 768px)
  .container-404
    padding-left: 0
    padding-right: 0
    width: 100%

.container-404 h1
  font-size: 100px
  font-weight: 300
  line-height: 120px
  margin-bottom: 20px
  margin-top: 0

.container-404 .line
  background-color: #707070
  height: 1px
  margin-bottom: 40px
  margin-left: auto
  margin-right: auto
  width: 200px

.container-404 p
  font-size: 20px
  font-weight: 300
  line-height: 24px
  margin-bottom: 40px
  margin-top: 0
</style>
