<template>
  <div class="print" ref="printable">
    <div class="subject" v-html="data.messageModel.subject"></div>
    <!-- <head>
      <meta name="viewport" content="width=device-width">
      <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
      <title>Simple Transactional Email</title>
    </head> -->
    <div class="email-body" style="background-color: #F7F8F9; font-family: sans-serif; -webkit-font-smoothing: antialiased; font-size: 12px; line-height: 1.5; margin: 0; padding: 0; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;">
      <span class="preheader" style="color: transparent; display: none; height: 0; max-height: 0; max-width: 0; opacity: 0; overflow: hidden; mso-hide: all; visibility: hidden; width: 0;">data.messageModel.subject</span>
      <table border="0" class="email-table-main" width="600" cellspacing="0" cellpadding="0" style="border-collapse: collapse; mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
        <tr>
          <td width="50" style="border: none;"></td>
          <td width="500" style="border: none;">
            <table border="0" width="500" cellspacing="0" cellpadding="0" style="border-collapse: collapse; mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
              <tr>
                <td height="20" style="border: none;"></td>
              </tr>
              <tr>
                <td style="border: none;">
                  <table border="0" class="email-table-header" width="500" cellspacing="0" cellpadding="0" style="border-collapse: collapse; mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                    <tr>
                      <td width="30" style="border: none; background-color: #F7F8F9;"></td>
                      <td width="470" style="border: none; background-color: #F7F8F9;">
                        <img src="../../assets/email/cupra-logo-paarl.png" alt="Cupra logo" width="136" height="23" border="0" style="border:0; outline:none; text-decoration:none; display:block;" />
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2" height="20" style="border: none; background-color: #F7F8F9;"></td>
                    </tr>
                    <tr>
                      <td align="center" colspan="2" style="border: none; background-color: #F7F8F9;">
                        <img class="main-image" :src="imagePath" alt="" width="500" height="313" border="0" style="border:0; outline:none; text-decoration:none; display:block;" />
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td style="border: none;">
                  <table border="0" class="email-table-body" width="500" cellspacing="0" cellpadding="0" style="border-collapse: collapse; mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                    <tr>
                      <td style="border: none; background-color: #F7F8F9;">
                        <table border="0" class="email-table-inner-body" width="500" cellspacing="0" cellpadding="0" style="border-collapse: collapse; mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                          <tr>
                            <td colspan="3" height="30" style="border: none; background-color: #F7F8F9;"></td>
                          </tr>
                          <tr>
                            <td width="30" style="border: none; background-color: #F7F8F9;"></td>
                            <td width="440" style="font-family: sans-serif; border: none; background-color: #F7F8F9;">
                              <h1 class="title" style="color:#95572B; font-family: sans-serif; font-size: 24px; line-height: 1.5; margin-bottom: 30px; margin-top: 0; font-weight:normal; text-transform: uppercase;" v-html="data.messageModel.title"></h1>
                              <p style="font-family: sans-serif; font-size: 12px; line-height: 1.5;" v-html="data.messageModel.paragraph"></p>
                              <p style="font-family: sans-serif; font-size: 12px; line-height: 1.5; font-weight: 300;" v-html="data.messageModel.body"></p>
                            </td>
                            <td width="30" style="border: none; background-color: #F7F8F9;"></td>
                          </tr>
                          <tr>
                            <td colspan="3" height="10" style="border: none; background-color: #F7F8F9;"></td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td style="border: none; background-color: #F7F8F9;">
                  <table border="0" class="email-separator-outer" width="500" cellspacing="0" cellpadding="0" style="border-collapse: collapse; mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                    <tr>
                      <td style="border: none; background-color: #F7F8F9;">
                        <table border="0" class="email-separator-inner" width="500" cellspacing="0" cellpadding="0" style="border-collapse: collapse; mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                          <tr>
                            <td colspan="3" height="5" style="border: none; background-color: #F7F8F9;"></td>
                          </tr>
                          <tr>
                            <td width="30" style="border: none; background-color: #F7F8F9;"></td>
                            <td width="440" height="1" style="font-family: sans-serif; border: none; background-color: #95572B;"></td>
                            <td width="30" style="border: none; background-color: #F7F8F9;"></td>
                          </tr>
                          <tr>
                            <td colspan="3" height="5" style="border: none; background-color: #F7F8F9;"></td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td style="border: none; background-color: #F7F8F9;">
                  <table border="0" class="email-table-footer-outer" width="500" cellspacing="0" cellpadding="0" style="border-collapse: collapse; mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                    <tr>
                      <td style="border: none; background-color: #F7F8F9;">
                        <table border="0" class="email-table-footer" width="500" cellspacing="0" cellpadding="0" style="border-collapse: collapse; mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                          <tr>
                            <td colspan="3" height="20" style="border: none; background-color: #F7F8F9;"></td>
                          </tr>
                          <tr>
                            <td width="30" style="border: none; background-color: #F7F8F9;"></td>
                            <td width="440" height="1" style="font-family: sans-serif; font-size: 10px; line-height: 1.5; background-color: #F7F8F9;">
                              <p style="font-family: sans-serif; font-size: 10px; line-height: 1.5; margin-top: 0; margin-bottom: 0;" v-html="data.messageModel.footer"></p>
                            </td>
                            <td width="30" style="border: none; background-color: #F7F8F9;"></td>
                          </tr>
                          <tr>
                            <td colspan="3" height="20" style="border: none; background-color: #F7F8F9;"></td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td height="20" style="border: none;"></td>
              </tr>
            </table>
          </td>
          <td width="50" style="border: none;"></td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import arrival_notification from '@/assets/email/arrival_notification.png'
import reminder from '@/assets/email/reminder.png'
import step_2 from '@/assets/email/step_2.png'
import survey from '@/assets/email/survey.png'
import welcome from '@/assets/email/welcome.png'

export default {
  name: 'PrintContent',
  props: ['data'],
  computed: {
    imagePath() {
      switch (this.data.imageName) {
        case 'arrival_notification.png':
          return arrival_notification
          break
        case 'reminder.png':
          return reminder
          break
        case 'step_2.png':
          return step_2
          break
        case 'survey.png':
          return survey
          break
        case 'welcome.png':
          return welcome
          break
      }
      return welcome
    },
  },
}
</script>

<style scoped lang="stylus">
.subject
  font-weight: $fw-light
  m-font-size(20, 24)
  margin-bottom: 20px

// Styles only for this view, not the actual email - START
.email-table-main
  font-weight: 300
  margin: 0 auto

.email-table-main >>> span.is-block
  display: block

.email-table-main >>> ol
  list-style-type: decimal

.email-table-main >>> ul
  margin-bottom: 1em
  margin-top: 1em
  padding-left: 40px

.email-table-main >>> ul,
.email-table-main >>> li
  list-style-type: inherit

.email-table-main >>> li
  font-family: sans-serif
  font-size: 11px
  margin-bottom: 10px

.email-table-main >>> h1
  font-size: 20px !important

.email-table-main >>> a
  color: #95572B

.email-table-main >>> .color-paarl
  color: #95572B

.email-table-main >>> .private-area-link
  border: 1px solid
  border-radius: 4px
  color: #95572B
  display: inline-block
  display: none !important
  font-weight: 500
  margin-bottom: 20px
  margin-top: 20px
  padding: 10px
  text-decoration: none
  text-transform: uppercase

  .preview &
    display: inline-block !important

.email-table-main >>> .for-print
  display: inline-block !important
  margin-bottom: 0
  margin-top: 10px
  word-break: break-all

  .preview &
    display: none !important

.email-table-main >>> .main-image
  height: auto !important
  width: 300px !important

.email-table-main >>> p
  color: #000
  font-family: sans-serif
  font-size: 11px
  font-weight: 300
  line-height: 1.5
  margin-bottom: 20px
  margin-top: 0

.email-table-main p + span.is-block .private-area-link
  margin-top: 0

.email-table-footer >>> p
  font-size: 9px

.email-table-footer >>> a
  color: #000
  font-size: 9px
</style>