<template>
  <div :class="['header', { 'hide': hide }]">
    <div class="header-inner">      
      <SummaryComponent
        v-if="!isMobile"
        :class="['summary', { 'hide': !['MK', 'HQ'].includes(userInfo.role) || $route.name === 'details' }]"
        data="funnel"
      />
      <SummaryComponent
        v-if="!isMobile"
        :class="['summary', { 'hide': $route.name === 'details' }]"
        data="tasks"
      />
      <div :class="['bottom-header', { 'hide': $route.name === 'details' }]">
        <HeaderNavigation />
        <div v-if="!isMobile" class="actions">
          <Search />
        </div>
        <div class="btn-wrapper">
          <div
            v-if="isRegistrationAvailable"
            class="btn btn--primary register-btn"
            @click="show('register')"
          >
            {{ $t("register_order") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Search from '@/components/Search'
import HeaderNavigation from '@/components/HeaderNavigation'
import SummaryComponent from '@/components/SummaryComponent'

import { logout } from '@/api'

export default {
  name: 'Header',
  components: {
    Search,
    HeaderNavigation,
    SummaryComponent,
  },
  props: ['hide'],
  data() {
    return {
      searchOpen: false,
      mobileBurgerOpen: false,
    }
  },
  computed: {
    summaryReady() {
      return this.$store.state.summary.loaded
    },
    userInfo() {
      return this.$store.state.user.userData
    },
    isRegistrationAvailable() {
      return (
        this.$route.name === 'tracking' &&
        !['MK', 'HQ'].includes(this.userInfo.role) &&
        ['ES', 'DE', 'AT'].includes(this.userInfo.countryCode)
      )
      // return this.$route.name === 'tracking'
    },
  },
  methods: {
    doLogout() {
      console.log('logout')
      logout().then(() => {
        this.$store.dispatch('user/resetUserInfo')
        this.$router.push({ name: 'login' })
      })
    },
    show(name) {
      this.$modal.show(name)
    },
  },
}
</script>

<style scoped lang="stylus">
$search-mobile-header-height = $top-mobile-header-height + 30px

.mobile-search-wrapper
  position: absolute
  top: $top-mobile-header-height
  z-index: 2
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  width: 100%
  height: $search-mobile-header-height
  background-color: $wild-sand

  &:before
    position: absolute
    top: $search-mobile-header-height
    left: 0
    display: block
    width: 100%
    height: 100vh
    background-color: rgba(#000, 0.5)
    content: ""

  .search
    background-color: #fff

  .search-long-explanation
    m-font-size(10, 12)
    align-self: flex-start
    margin: 10px 20px

.burger-menu
  $left = 20px
  position: fixed
  top: 0
  left: $left
  z-index: 100
  display: flex
  flex-direction: column
  padding: vw(20px)
  width: "calc(100% - %s)" % $left
  height: 100%
  background-color: $wild-sand

  &:before
    position: absolute
    top: 0
    left: -($left)
    display: block
    width: $left
    height: 100vh
    background-color: rgba(#000, 0.5)
    content: ""

  .top
    flex: 0 0 $top-mobile-header-height

    .close-icon
      background-size: 85% 85% !important
      m-icon("close", 20)
      position: absolute
      top: 20px
      right: 15px

  .list
    display: flex
    flex: 1 1 auto
    flex-direction: column

    .list-item
      padding: 20px 0
      border-bottom: 1px solid grey

  .bottom
    display: flex
    flex-direction: column

    .btn-wrapper
      .logout-btn
        display: flex

        .logout-icon
          m-icon("logout-white", 25)
          margin-right: 15px

.header
  z-index: unset
  display: flex
  flex-direction: column
  padding: 0 25px
  background-color: #fff

  .mobile &
    padding: 0

  &.hide
    display: none

  .header-inner
    +m-breakpoint(lg)
      margin: 0 auto
      width: $breakpoints[lg]

    .mobile &
      padding: 0

  .top-header
    position: relative
    z-index: 1
    display: flex
    align-items: center
    height: $header-height

    .mobile &
      padding: 0 15px
      height: $top-mobile-header-height

    .logo
      display: block
      margin-right: 50px
      width: 132px
      height: 20px
      background-image: url("~@/assets/cupra-logo.svg")
      background-position: center center
      background-size: contain
      background-repeat: no-repeat

    .buttons-container
      display: flex
      align-items: center

      .btn
        &:not(:last-child)
          margin-right: 20px

    .right
      display: flex
      align-items: center
      margin-left: auto

      .search-btn
        margin-right: 20px

        &.search-icon
          m-icon("search-black", 20)

        &.close-icon
          background-size: 85% 85% !important
          m-icon("close", 20)

      .burger-icon
        m-icon("hamburger", 20)

      .info-icon
        m-icon("info-black", 25 30)
        margin-left: 40px
        cursor: pointer

      .register-btn
        margin-left: 30px

      .round-user-icon
        $size = 40px
        position: relative
        margin-left: 30px
        width: $size
        height: $size
        border: 1px solid #000
        border-radius: 50%
        color: black
        text-align: center
        text-transform: uppercase
        line-height: $size
        cursor: pointer

      .logout-icon
        cursor: pointer
        m-icon("logout-cyprus", 35 40)
        margin-left: 30px

  .bottom-header
    display: flex
    align-items: center
    width: 100%
    height: $bottom-header-height
    transition: height 0.4s ease-in-out, opacity 0.2s ease-in-out 0.2s

    &.hide
      height: 0 !important
      opacity: 0 !important
      transition-delay: 0s
      transition-duration: 0.4s

    .mobile &
      padding: 0 15px
      height: $bottom-mobile-header-height
      border-bottom: 1px solid $sisal

    .actions
      margin-left: auto
</style>