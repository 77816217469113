import axios from 'axios'
import { filtersToQuery } from '../../utils/filterUtils'
// import { t } from '../../i18n'

let cache = {}

const qs = require('qs')

axios.defaults.withCredentials = true

function paramsSerializer(params) {
  return qs.stringify(params, { arrayFormat: 'repeat' })
}

let axiosInstance = axios.create({
  paramsSerializer: paramsSerializer,
})

axiosInstance.interceptors.request.use(config => {
  // console.log('static data' + process.env.VUE_APP_STATIC_DATA)
  // console.log('url: ' + config.url)
  if (process.env.VUE_APP_STATIC_DATA === 'true' && config.method === 'get') {
    //
    config.url =
      './reporting_lite_data/' +
      config.url.split(process.env.VUE_APP_API_REPORTING_URL).pop() +
      '.json'
    return new Promise((res, rej) => {
      setTimeout(() => res(config), 1000)
    })
  }
  if (process.env.VUE_APP_STATIC_DATA === 'true' && config.method === 'post') {
    //
    return new Promise((res, rej) => {
      setTimeout(res, 1000)
    })
  }
  // console.log('url: '+ config.url)
  return config
})

function YYYYMMDDStrToDate(str) {
  //remove this if / else  case (just for fake data)
  if (!(str instanceof Date)) {
    return new Date(
      parseInt(str.substr(0, 4)),
      parseInt(str.substr(4, 2)) - 1,
      parseInt(str.substr(6, 2))
    )
  } else {
    return str
  }
}

// function DateToYYYYMMDDStr(date) {
//   return date.getFullYear().toString() + ('0' + (date.getMonth() + 1)).slice(-2) + ('0' + date.getDate()).slice(-2)
// }

function cachedGet(url, opts) {
  let key = url + '&' + paramsSerializer(opts?.params) || url
  if (cache[key]) return new Promise(res => res(cache[key]))
  else
    return axiosInstance.get(url, opts).then(res => {
      cache[key] = res
      return res
    })
}
export function getFilterTree() {
  return cachedGet(process.env.VUE_APP_API_REPORTING_URL + 'filterTree', {
    params: { brand: 'cupra' },
  })
}
export function getPrList() {
  return cachedGet(process.env.VUE_APP_API_REPORTING_URL + 'prList', { params: { brand: 'cupra' } })
}
export function getColorList() {
  return cachedGet(process.env.VUE_APP_API_REPORTING_URL + 'colorList', {
    params: { brand: 'cupra' },
  })
}
export function getDealerList() {
  return cachedGet(process.env.VUE_APP_API_REPORTING_URL + 'dealerList', {
    params: { brand: 'cupra' },
  })
    .then(resp => {
      return resp.data
    })
    .then(resp => {
      return resp.map(d => {
        return { ...d, label: d.id }
      })
    })
}
export function getCMList() {
  return cachedGet(process.env.VUE_APP_API_REPORTING_URL + 'sellerList', {
    params: { brand: 'cupra' },
  })
    .then(resp => {
      return resp.data
    })
    .then(resp => {
      return resp.map(d => {
        return { ...d, id: d.userId, label: d.name }
      })
    })
}
export function getCardList() {
  return cachedGet(process.env.VUE_APP_API_REPORTING_URL + 'card/list')
}

export function getCardData(id, filters) {
  // console.log('getting card data', id, filters)
  let queryParams = filtersToQuery(filters, 'data')
  return cachedGet(process.env.VUE_APP_API_REPORTING_URL + `card/${id}/data`, {
    params: { ...queryParams, brand: 'cupra' },
  })
    .then(resp => {
      return resp.data
    })
    .then(resp => {
      if (process.env.VUE_APP_STATIC_DATA === 'false') return resp
      let number = Math.floor(8 * Math.random())
      resp[number].indList.forEach(ind => (ind.value = Math.random() * 100))
      return resp[number]
    })
}

export function getCardEvolutive(id, filters) {
  // console.log('getting card evolutive', id, filters)

  let queryParams = filtersToQuery(filters, 'evolutive')
  let granul = queryParams.timeAgg //nomes per static
  return cachedGet(process.env.VUE_APP_API_REPORTING_URL + `card/${id}/evolutive`, {
    params: { ...queryParams, brand: 'cupra' },
  })
    .then(resp => {
      return resp.data
    })
    .then(resp => {
      if (process.env.VUE_APP_STATIC_DATA === 'false') {
        resp.evolList.forEach(d => {
          d.dateList.forEach(e => {
            e.date = YYYYMMDDStrToDate(e.date)
          })
        })
        return resp
      }
      let index = 0
      if (granul === 'WEEK') {
        index = 0
      } else if (granul === 'MONTH') {
        index = 1
      } else if (granul === 'DAY') {
        index = 2
      }
      resp[index].evolList.forEach(d => {
        d.dateList.forEach(e => {
          e.date = YYYYMMDDStrToDate(e.date)
        })
      })
      let prettyChallenge = [10, 100, 1000, 10000, 100000, 1000000]
      resp[index].evolList.forEach(d => {
        let ind = Math.floor(Math.random() * 6)
        d.dateList.forEach(e => {
          e.value = Math.random() * prettyChallenge[ind]
        })
      })
      return resp[index]
    })
    .then(resp => {
      if (process.env.VUE_APP_STATIC_DATA === 'false') return resp
      return new Promise(res => {
        setTimeout(() => {
          res(resp)
        }, 1000)
      })
    })
}
