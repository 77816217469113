<template>
  <div v-if="data" class="main-values">
    <div class="main-values-main">
      <span class="big">{{ $prettyPrint(main.data.value, main.meta.unitType) }}</span>
      {{ $t(main.meta.name) }}
      {{ $t(main.meta.unitType) }}
    </div>
    <div v-if="sub" class="main-values-sub">
      <span class="big">{{ $prettyPrint(sub.data.value, sub.meta.unitType) }}</span>
      {{ $t(sub.meta.name) }}
      {{ $t(sub.meta.unitType) }}
    </div>
  </div>
</template>

<script>
export default {
  props: ['data'],
  computed: {
    main() {
      return this.data[0]
    },
    sub() {
      return this.data[1] ? this.data[1] : null
    },
  },
}
</script>

<style lang="stylus" scoped>
.main-values-main
  m-font-size(11, 13)
  margin-bottom: vw(10px)
  font-family: "Cupra Book"

  .big
    m-font-size(35, 42)
    font-weight: $fw-light
    font-family: "Cupra"

.main-values-sub
  m-font-size(11, 13)
  color: #585656
  font-family: "Cupra Book"

  .big
    m-font-size(15, 18)
    color: #000
    font-weight: $fw-medium
    font-family: "Cupra"
</style>