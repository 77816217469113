<template>
  <div class="card" :class="{flipped}">
    <template v-if="mode==='full'">
      <BasicCardContent mode="full" :meta="meta" :filters="filters" />
    </template>
    <template v-else>
      <div class="flip-box-inner">
        <div class="flip-box-front">
          <BasicCardContent
            v-if="!internalFlipped"
            :mode="!internalFlipped ? 'front' : 'back'"
            :meta="meta"
            :filters="filters"
            @flip="internalFlipped=!internalFlipped"
          />
        </div>
        <div class="flip-box-back">
          <BasicCardContent
            v-if="internalFlipped"
            :mode="!internalFlipped ? 'front' : 'back'"
            :meta="meta"
            :filters="filters"
            @flip="internalFlipped=!internalFlipped"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import BasicCardContent from './BasicCardContent/BasicCardContent'

export default {
  components: { BasicCardContent },
  model: {
    prop: 'flipped',
    event: 'flip',
  },
  props: ['meta', 'filters', 'flipped', 'mode'],
  computed: {
    internalFlipped: {
      get() {
        return this.flipped
      },
      set(value) {
        this.$emit('flip', value)
      },
    },
  },
}
</script>

<style lang="stylus" scoped>
.card
  overflow: hidden
  min-height: vw(100px)
  background-color: transparent
  perspective: 1000px /* Remove this if you don't want the 3D effect */

  &.flipped
    .flip-box-inner
      transform: rotateY(180deg)

  /* This container is needed to position the front and back side */
  .flip-box-inner
    position: relative
    width: 100%
    height: 100%
    transition: transform 0.4s ease-in-out
    transform-style: preserve-3d

  .flip-box-front,
  .flip-box-back
    position: absolute
    width: 100%
    height: 100%
    background-color: white
    backface-visibility: hidden
    -webkit-backface-visibility: hidden /* Safari */

  .flip-box-back
    transform: rotateY(180deg)
</style>