<template>
  <div :class="['select-comp', isOpen ? 'open': 'closed']">
    <multiselect
      ref="multiselect"
      v-model="internalValue"
      :class="[configs.multiple ? 'multiple': 'single' ]"
      :options="configs.items"
      :close-on-select="!configs.multiple"
      :allow-empty="configs.multiple"
      :multiple="configs.multiple"
      :label="configs.itemText"
      :track-by="configs.itemText"
      :show-labels="false"
      placeholder=""
      open-direction="below"
      :searchable="false"
      @close="singleClose"
    >
      <template slot="option" slot-scope="props">
        <div class="option-item">
          <div class="selected-option-mark" />
          <div class="icon-space" />
          <div class="text">{{ props.option[configs.itemText] }}</div>
        </div>
      </template>
    </multiselect>
    <!-- <div class="label">{{label}}</div> -->
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
  name: 'SelectComp',
  components: {
    Multiselect,
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: { required: true },
    isOpen: { required: true },
    configs: { required: true },
    // items: { required: true},
    // itemText: { required: true},
    // itemValue: { required: true},
    // multiple: { default: true, type: Boolean, required: false}
  },
  data() {
    return {}
  },
  computed: {
    internalValue: {
      get() {
        if (this.configs.multiple) {
          if (!this.value) {
            return null
          }
          return this.value.map(v => this.configs.items.find(d => d[this.configs.itemValue] === v))
        } else {
          if (this.value === null) {
            return null
          }
          return this.configs.items.find(d => d[this.configs.itemValue] === this.value)
        }
      },
      set(newValue) {
        // if (newValue === null)
        //   this.$emit('change', null)

        if (this.configs.multiple) {
          this.$emit(
            'change',
            newValue.length ? newValue.map(d => d[this.configs.itemValue]) : null
          )
        } else {
          this.$emit('change', newValue[this.configs.itemValue] || null)
        }
      },
    },
  },
  watch: {
    isOpen() {
      // console.log('isOpenWatcher, this.isOpen: ', this.isOpen)
      if (this.isOpen) {
        this.$nextTick(() => this.$refs.multiselect.$el.focus())
        // this.$refs.multiselect.$el.focus()
        // this.$refs.input.focus()
      }
    },
  },
  methods: {
    singleClose() {
      // console.log('singleClose, ')
      if (!this.configs.multiple) {
        // console.log('singleClose emitted close event')
        this.$emit('close')
      }
    },
  },
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="stylus" scoped>
.select-comp
  display: flex

  &.closed
    display: none

  .multiselect
    top: 50px

    .mobile &
      top: 60px

    &.multiselect--above
      top: 0

    &.multiselect--active
      & + .label
        font-weight: $fw-medium

    .option-item
      display: flex
      align-items: center
      m-font-size(14, 20)

      .icon-space
        m-icon("checkbox-empty", 14)
        flex: 0 0 auto
        margin-right: 10px

    >>> .multiselect__content-wrapper
      min-width: 100%
      width: auto

      .mobile &
        background-color: $wild-sand

    >>> .multiselect__tags
      display: none

    >>> .multiselect__option
      display: flex
      align-items: center
      padding: 6px
      min-height: 50px

      .mobile &
        min-height: 60px
        white-space: normal !important

    >>> .multiselect__option--selected
      background-color: #fff
      color: #000
      font-weight: $fw-regular

      &.multiselect__option--highlight
        background: $gallery

      .option-item
        .icon-space
          m-icon("checkbox-checked", 14)

    >>> .multiselect__option--highlight
      background: $gallery
      color: #000

    >>> .multiselect__option--disabled
      background: #fefefe !important

    >>> .multiselect__tag
      background: #fff
      color: #000

    >>> .multiselect__tag-icon
      &:after
        color: #000

      &:focus,
      &:hover
        background: #fff

        &:after
          color: #000
</style>