<template>
  <div :class="['trackline', tab]">
    <template v-if="!summaryReady">
      <Loader />
    </template>
    <template v-else>
      <div class="trackline-wrapper">
        <div
          v-for="status in trackline"
          :key="status.trackingStatus"
          :class="['status-container', {'selected': activeFilterShortcutId === status.id && ['activeOrders', 'tracklineView'].includes(tab) }, {'disabled': status.disabled }]"
        >
          <div :class="['status']" @click="setShortcut(status)">
            <div class="row primary">
              <div class="name"> {{ $t(status.name) }}</div>
              <div v-if="['activeOrders', 'tracklineView'].includes(tab) && !status.disabled" class="value"> {{ status.total }} </div>
              <div v-if="tab === 'tracklineView'" :class="['caret-icon', activeFilterShortcutId === status.id ? 'up': 'down']" />
            </div>
            <div v-if="tab === 'orderDetails' & status.date != null" class="row secondary">
              <div class="date">{{ moment(status.date).format('ll') }}</div>
            </div>
          </div>
          <div v-if="isMobile && activeFilterShortcutId === status.id" class="list-cards">
            <OrderList :tab="tab" />
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Loader from './Loader'
import OrderList from '@/components/OrderList.vue'
export default {
  name: 'Trackline',
  components: {
    Loader,
    OrderList,
  },
  props: {
    tab: String,
    order: Object,
  },
  computed: {
    summaryReady() {
      return this.$store.state.summary.loaded
    },
    trackline() {
      if (this.tab === 'orderDetails') {
        let trackSteps = this.$store.state.summary.data.buckets.map(t => {
          return { ...t, date: null }
        })
        //AIXO LO QUE DIGUI EL CLIENT

        if (this.order.autoReceivedNotifications) {
          this.order.autoReceivedNotifications.forEach(notif => {
            let ind = trackSteps.findIndex(t => t.trackingStatus === notif.status)
            if (ind > -1) {
              trackSteps[ind].date = notif.dateTime
            }
          })
          //ANNA COMPROVAAAA
          let ind = trackSteps.findIndex(t => {
            return t.trackingStatus === this.order.autoReceivedNotifications[0].status
          })
          if (ind > 1) trackSteps[ind - 1].date = this.order.purchaseDate
        } else {
          let ind = trackSteps.findIndex(t => {
            return t.trackingStatus === this.order.trackingStatus
          })
          trackSteps[ind].date = this.order.purchaseDate
        }
        //DETECTAR ELS DISABLED
        let actualStatus = this.order.trackingStatus
        let actualStatusInd = trackSteps.findIndex(t => t.trackingStatus === actualStatus)
        trackSteps = trackSteps.map((t, pos) => {
          return { ...t, disabled: pos > actualStatusInd }
        })

        return trackSteps
      }
      return this.$store.state.summary.data.buckets
    },
    activeFilterShortcutId() {
      return (
        this.$store.state.summary.activeFilterShortcut &&
        this.$store.state.summary.activeFilterShortcut.id
      )
    },
  },
  methods: {
    setShortcut(task) {
      this.$store.dispatch('summary/activateShortcut', { task: task, filters: { active: true } })
    },
  },
}
</script>

<style scoped lang="stylus">
.trackline
  position: relative
  display: flex
  flex-direction: column
  padding-left: 30px
  min-height: 100px

  .loader-container
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)

  &.activeOrders,
  &.tracklineView
    padding-top: 10px

    .row
      &.primary
        &:before
          border: 1px solid $paarl
          background-color: $paarl

  &.orderDetails
    .status-container
      pointer-events: none

      &.disabled
        .row
          &.primary
            &:before
              background-color: #fff

    .row
      &.primary
        &:before
          border: 1px solid #000
          background-color: #000

.trackline-wrapper
  position: relative
  margin-bottom: 30px

  .mobile &
    margin-bottom: 0

  &:before
    position: absolute
    top: 10px
    left: -20px
    display: block
    width: 1px
    height: calc(100% - 20px)
    background-color: $alto
    content: ""

    .mobile &
      top: 25px
      z-index: 1
      height: calc(100% - 55px)

.status-container
  position: relative
  display: flex
  flex-direction: column
  cursor: pointer

  .mobile &
    position: relative

    &:after
      position: absolute
      bottom: 0
      left: 0
      width: calc(100% - 15px)
      height: 1px
      background-color: $alto
      content: ""

  &:not(:last-child)
    margin-bottom: 20px

    .mobile &
      margin-bottom: 0

  &.selected
    position: relative

    &:before
      position: absolute
      top: -10px
      left: -55px
      width: calc(100% + 55px + 10px)
      height: calc(100% + 20px)
      background-color: #fff
      content: ""

      .mobile &
        top: 60px
        left: -35px
        width: calc(100% + 35px + 15px)
        height: calc(100% - 60px)

  &.disabled
    color: rgba(#000, 0.35)
    cursor: default
    pointer-events: none

    .caret-icon
      display: none

    .row
      &.primary
        &:before
          border: 1px solid $alto
          background-color: $wild-sand

.status
  width: 100%

  .mobile &
    display: flex
    align-items: center
    height: 60px

.list-cards
  overflow-y: auto
  max-height: 300px

  .mobile &
    overflow: hidden
    max-height: 100%

.row
  &.primary
    display: flex
    align-items: center
    font-weight: $fw-light
    m-font-size(14, 18)
    position: relative
    width: 100%

    .mobile &
      m-font-size(16, 20)
      padding-right: 10px

    &:before
      position: absolute
      top: 1px
      left: -27px
      display: block
      width: 16px
      height: 16px
      border-radius: 50%
      content: ""

      .mobile &
        z-index: 1

    .name
      flex: 1 1 0%
      m-ellipsis(100%)

    .value
      margin-left: 20px
      font-weight: $fw-regular

      .mobile &
        font-weight: $fw-light

    .caret-icon
      // transition: transform 0.25s ease-in-out
      .mobile &
        margin-left: 10px

      &.down
        background-size: 50% 50% !important
        m-icon("caret-down", 20)

      &.up
        background-size: 50% 50% !important
        m-icon("caret-down", 20)
        // animation: rotation 0.25s linear 0s 1
        transform: rotate(180deg)

  &.secondary
    font-weight: $fw-light
    m-font-size(10, 12)
    margin-top: 2px
    width: 100%
</style>
