import { getModels, getEngines,getTrims, getTmaimgs,getColors, getPRs, getDealers, getCMs } from '@/api'

export default {
  namespaced: true,
  state: {
    data: null,
    loaded: false
  },
  actions: {
    load ({state, getters, commit, rootState, rootGetters}, params) {
      let role = rootState.user.userData.role
      console.log(role)
      state.loaded = false
      let reqs = [getModels(),getEngines(), getTrims(), getTmaimgs(),getColors(), getPRs()]
      if (['MK', 'HQ'].includes(role)) {
        reqs.push(getDealers())
      } else if (role === 'SM') {
        reqs.push(getCMs())
      }
      return Promise.all(reqs).then(responses => {
        var data = {
          models: responses[0].data,
          engines: responses[1].data,
          trims: responses[2].data,
          tmaimgs: responses[3].data,
          colors: responses[4].data,
          prs: responses[5].data,
          // dealers: responses[6].data,
          // cms: responses[7].data
        }
        if (['MK', 'HQ'].includes(role)) {
          let dealers = responses[6].data
          data.dealers = dealers // .map(t => { return {'id': t} })
        } else if (role === 'SM') {
          let cms = responses[6].data
          data.cms = cms
        }
        commit('setData', data)
      })
    }
  },
  mutations: {
    setData (state, data) {
      state.loaded = true
      state.data = data
    }
  }
}
