export default {
  html_title: 'CUPRA Priority Tracking Tool',
  welcome_title: 'Welcome to the CUPRA Priority Tracking Tool',
  welcome_body: '-',
  continue_btn: 'Continue',
  welcome_body_first_time:
    'As this is your first time loging in, we need some extra information from you. This information will be only requested once. <br><br>Please fill the following fields:',
  country_code: 'Country ISO Code',
  dealer_code: 'Dealer Code',
  hide_summary_btn: 'Hide My Tasks',
  show_summary_btn: 'Show My Tasks',
  hide_funnel_btn: 'Hide Funnel',
  show_funnel_btn: 'Show Funnel',
  my_tasks_title: 'My Tasks',
  funnel_title: 'Funnel',
  pending_welcome_emails: 'Pending welcome emails',
  pendingWelcome: 'Pending welcome emails',
  cm_pending_welcome_info_on_click: 'Send the access information to customer',
  sm_pending_welcome_info_on_click: 'Send the access information to customer',
  unengaged_clients: 'Unengaged customers',
  unengagedClients: 'Unengaged customers',
  cm_unengaged_clients_info_on_click: 'These customers have never accessed their tracking tool',
  sm_unengaged_clients_info_on_click: 'These customers have never accessed their tracking tool',
  mk_unengaged_clients_info_on_click: 'These customers have never accessed their tracking tool',
  pushable_notifications: 'Pending arrival notifications',
  pushableNotifications: 'Pending arrival notifications',
  cm_pushable_notifications_info_on_click: 'Inform your customer that the car is at the Retailer',
  sm_pushable_notifications_info_on_click: 'Inform your customer that the car is at the Retailer',
  unread_notifications: 'Unread notifications',
  unreadNotifications: 'Unread notifications',
  cm_unread_notifications_info_on_click: "You haven't read these messages yet",
  sm_unread_notifications_info_on_click: "Your CUPRA Specialists haven't read these messages yet",
  mk_unread_notifications_info_on_click:
    "These notifications haven't been read by the corresponding CUPRA Specialist",
  possible_delayed_orders: 'Possible delayed orders',
  possibleDelayedOrders: 'Possible delayed orders',
  cm_possible_delayed_orders_info_on_click:
    'These active orders were generated more than 15 days ago.',
  sm_possible_delayed_orders_info_on_click:
    'These active orders were generated more than 15 days ago.',
  possible_delayed_market_orders: 'Possible delayed orders',
  possibleDelayedMarketOrders: 'Possible delayed orders',
  mk_possible_delayed_market_orders_info_on_click:
    'These active orders were generated more than 15 days ago and are not at the Retailer.',
  delayed_cars: 'Delayed cars',
  delayedCars: 'Delayed cars',
  mk_delayed_cars_info_on_click:
    "These active orders were generated more than 21 days ago and haven't been delivered yet.",
  total_active_orders: 'Active Orders',
  totalActiveOrders: 'Active Orders',
  mk_total_active_orders_info_on_click: 'Generated orders not delivered',
  welcome_email_sent: 'Welcome emails sent',
  welcomeEmailSent: 'Welcome emails sent',
  mk_welcome_email_sent: 'Ratio between active orders with welcome email sent versus total',
  pending_deliveries: 'Pending deliveries',
  pendingDeliveries: 'Pending deliveries',
  mk_pending_deliveries_info_on_click: 'Orders at dealer waiting for AaK',
  cm_active: 'Active CUPRA Specialists in this tool',
  cmActives: 'Active CUPRA Specialists in this tool',
  mk_cm_active: 'A CUPRA Specialist is active if he/she has logged within the last week.',
  legend_title: 'Information about your possible actions',
  send_notification_legend: 'Send notification of "car is at the Retailer" to customer',
  send_welcome_legend: 'Send welcome email to the customer',
  send_reminder_legend: 'Send reminder of the welcome email to the customer',
  inform_car_arrival_legend: 'Inform the system about an order that has arrived to the Retailer',
  unread_notifications_legend: "Direct access to messages from the importer you haven't read yet",
  reregister_legend: 'Assign a cancelled order to a new customer. Re-register it.',
  send_welcome_email_tooltip: 'Send welcome email',
  send_reminder_tooltip: 'Send reminder',
  send_push_notification_tooltip: 'Send push notification',
  inform_car_arrival_tooltip: 'Inform of car arrival at the Retailer',
  reregister_this_order_tooltip: 'Register this order again',
  log_out: 'Log out',
  register_order: 'Register a new order',
  download_excel: 'Download Excel',
  send_welcome_btn: 'Send welcome email',
  send_reminder_btn: 'Send reminder email',
  send_notification_btn: 'Send notification',
  inform_car_arrival_btn: 'Inform us',
  show_results_btn: 'Show results',
  cancel_btn: 'Cancel',
  confirm_btn: 'Confirm',
  submit_registration_btn: 'Submit registration',
  print_btn: 'Print pdf',
  clear_btn: 'CLEAR',
  search_text: 'Tracking ID, commission number, VIN or email',
  trackline_tab_title: 'Trackline',
  PRE_A500_short: 'Order received',
  PRODUCTION_STARTS_short: 'Production starts',
  PRODUCTION_ENDS_short: 'Production ends',
  FACTORY_DEPART_short: 'Leaves factory',
  COMPOUND_ARRIVAL_short: 'Arrives at compound',
  COMPOUND_DEPART_short: 'Leaves compound',
  DEALER_ARRIVAL_short: 'Arrives to Retailer',
  AAK_short: 'AaK',
  PRE_A500: 'Order received',
  PRODUCTION_STARTS: 'Production starts',
  PRODUCTION_ENDS: 'Production ends',
  FACTORY_DEPART: 'Car leaves factory',
  COMPOUND_ARRIVAL: 'Car arrives at compound',
  COMPOUND_DEPART: 'Car leaves compound',
  DEALER_ARRIVAL: 'Car arrives at Retailer',
  AAK: 'AaK',
  filter_tab_title: 'Filters',
  pending_actions: 'Tasks',
  order_date_range: 'Order date range',
  delivery_date_range: 'Delivery date range',
  active_orders: 'Active orders',
  order_history: 'Order history',
  tracking_id: 'Tracking ID',
  status: 'Status',
  model: 'Model',
  last_status_change: 'Last status change',
  order_date: 'Order date',
  cm_code: 'CUPRA Retailer code',
  dealer_code_short: 'Retailer',
  delivery_date: 'Delivery date',
  delivered_on_time: 'Delivered on time',
  yes: 'Yes',
  no: 'No',
  days_until_delivery: 'Days until delivery',
  back: 'back',
  commission_nr: 'Commission number',
  tmaimg: 'TMAIMG',
  engine: 'Engine',
  trim: 'Trim',
  color: 'Color',
  prs: 'PRs',
  client_tracking_view: 'Customer tracking view',
  orderReceived: 'Order received',
  production: 'Production process',
  dealer: 'Arrival at Retailer',
  delivered: 'Survey',
  pending: 'Pending',
  sent_notifications_list: 'Sent notifications',
  market_notifications_list: 'Market notifications',
  completed_orders: 'Completed orders',
  register_title: 'Register an order',
  register_explanation:
    'After registering the purchase, you will have this order assigned to you and your Retailer. You will be able to send the corresponding welcome email to the customer.',
  welcome_mail_title: 'Send welcome email',
  client_mail_field: 'Customer email',
  client_mail_placeholder: 'example@example.ex',
  reminder_title: 'Send welcome reminder email',
  send_arrival_notification_title: 'Send "the car is at the Retailer" notification',
  inform_car_arrival_title: 'This car has arrived at your Retailer',
  inform_car_arrival_explanation:
    'Since you may be the first to know about this arrival, please inform us about it.<br> Do you wish to confirm that the car with tracking ID %trackingId% has arrived to your Retailer?',
  welcome_mail_confirmation_title: 'Welcome email sent to the customer',
  welcome_mail_confirmation_explanation:
    'If your customer agree to receive emails, she/he will receive the notification in the email. To see if the customer has already accessed her/his order tracking, check it in Order Details.',
  order_status: 'Order status',
  ok_btn: 'OK',
  subject: 'Subject',
  body: 'Body',
  purchaseDate: 'Order date',
  CMCode: 'CUPRA Retailer code',
  dealerCode: 'Retailer',
  deliveryDate: 'Delivery date',
  deliveredOnTime: 'Delivered on time',
  filters: 'Filters',
  models: 'Model',
  trackingId: 'Tracking ID',
  pr: 'PRs',
  delayed: 'Delayed cars',
  trackingDays: 'Days until delivery',
  lastStatusChange: 'Last status change',
  trackingStatus: 'Status',
  done: 'Done',
  date: 'Date',
  PR: 'PRs',
  pendingActions: 'Tasks',
  welcome_footer: '-',
  successful_generic_title: 'Done!',
  successful_generic_text: '-',
  wrong_generic_title: 'Oops something went wrong',
  wrong_generic_text: 'You may want to retry. <br><br> Server responded with error:<br>',
  activeOrders: 'Active orders',
  delayedOrders: 'Delayed cars',
  activeCM: 'Active CUPRA Specialists',
  clear: 'Clear',
  de_AT: 'Deutsch (AT)',
  de_CH: 'Deutsch (CH)',
  de_DE: 'Deutsch (DE)',
  en_GB: 'English (GB)',
  es_ES: 'Español (ES)',
  fr_CH: 'Français (CH)',
  fr_FR: 'Français (FR)',
  it_CH: 'Italiano (CH)',
  it_IT: 'Italiano (IT)',
  nl_NL: 'Nederlands (NL)',
  close_btn: 'Close',
  reregistration_available: 'Re-registration available',
  pending_reminders: 'Pending reminder emails',
  CM_to_customer_sent_notifications_list: 'Notifications sent to customer by the CUPRA Specialist',
  your_notifications_to_CM: 'Your notification to the CUPRA Specialist',
  read_notification_title: 'Read this notification',
  save_btn: 'Save',
  print_preview_title: 'Print preview',
  preview_title: 'Preview',
  turn_your_device_to_landscape_mode: 'Turn your device to lanscape mode',
  dealer_code_input_explanation: 'You should introduce your KVPS code',
  tracking_id_input_explanation:
    'Tracking ID (e.g. 2366522102021) = Commision Number (236652) + BID (210) + Vehicle Year (2021)',
  download_btn: 'Download',
  pending_reminder: 'Pending reminder emails',
  cm_pending_reminder_info_on_click: 'Forward again the welcome email to an unengaged customer',
  sm_pending_reminder_info_on_click: 'Forward again the welcome email to an unengaged customer',
  authenticate_as_sales_manager: 'Authenticate as Sales Manager',
  authenticate_as_sales_manager_title: 'Authenticate as Sales Manager',
  authenticate_as_sales_manager_explanation: '',
  assign_cupra_master_legend: 'Assign a CUPRA Specialist to an order',
  assign_cupra_master_title: 'Assign a Cupra Specialist',
  assign_cupra_master_explanation: 'Assign a CUPRA Specialist to this order',
  assign_btn: 'Assign',
  authenticate_btn: 'Authenticate',
  send_massive_notification_btn: 'Send notification',
  send_massive_notification_title: 'Send notification',
  send_massive_notification_explanation: 'To the selected orders',
  login_btn: 'Access',
  redirected_title: 'Have you logged in GRP?',
  redirected_body:
    'Please click continue. Otherwise check if your browser is blocking the GRP pop up window',
  preview_btn: 'Preview',
  reregister_explanation: 'Assign a cancelled order to a new customer. Re-register it.',
  reregister_title: 'Re-register',
  welcomeMessageSent: 'Welcome emails sent',
  pendingToDeliver: 'Orders pending to deliver',
  send_mk_notification_tooltip: 'Send notification',
  download_excel_title: 'Download list',
  download_excel_explanation: 'The list selection is based on your filters',
  selection_massive_this_page_part_1:
    'All <strong>%PAGE_LENGTH%</strong> orders on this page are selected. ',
  selection_massive_this_page_part_2: 'Select all %ORDERS_TOTAL% orders',
  selection_massive_all_pages_part_1: 'All %ORDERS_TOTAL% are selected. ,',
  selection_massive_all_pages_part_2: 'Clear selection',
  send_notification_title: 'Send notification',
  choose_cupra_master: 'Select a CUPRA Specialist from your dealer',
  pendingReminders: 'Pending reminder emails',
  send_mk_notification_legend: 'Send notifications to the CUPRA specialist of an order',
  mk_unread_notifications_legend:
    "These notifications haven't been read by the corresponding CUPRA Specialist",
  turn_your_device_to_portrait_mode: 'Turn your device to portrait mode',
  more_info: 'More info',
  apply_filters_mobile_btn: 'Apply filters',
  cmName: 'Cupra Specialist',
  logout: 'Logout',
  sort_by: 'Sort by',
  order_details: 'Order details',
  dealer_or_importer_code: 'Dealer or importer code',
  not_found: 'Not found',
  searching: 'Searching...',
  pendingManualReminder: 'Pending Reminder Emails',
  unassigned_seller: 'Unassigned Cupra Specialist',
  unassignedSeller: 'Unassigned Cupra Specialist',
  countryCode: 'Country ISO Code',
  vin: 'VIN',
  hq_my_tasks_title: 'Market Tasks',
  show_hq_summary_btn: 'Show Market Tasks',
  hide_hq_summary_btn: 'Hide Market Tasks',
  hq_possible_delayed_market_orders_info_on_click:
    "These active orders may break the country's customer promise in about 5 days.",
  hq_delayed_cars_info_on_click:
    "These active orders haven't been delivered yet and don't follow the customer promise.",
  hq_unread_notifications_info_on_click:
    "These notifications were sent by the Importer and haven't been read by the corresponding CUPRA Specialist",
  hq_pending_actions: 'Other filters',
  country: 'Country',
  email: 'Email',
  search_long_explanation: 'Search by tracking ID, commission number, VIN or email',
  mk_notifications_to_CM: 'Notifications Market sent to the Cupra Specialist',
  possibleDelayNotDelayed: '',
  tracking_tab: 'Tracking',
  search: 'Search',
  time_period: 'Period',
  time_week: 'Week',
  time_ytd: 'YTD',
  time_month: 'Month',
  time_from: 'From',
  time_until: 'Until',
  long_403_message:
    'You are not allowed to access this app. Change your context on GRP Portal. Click ok and login correctly on the pop-up tab.',
}
