import { getNotificationInfo } from '@/api'

export default {
  namespaced: true,
  state: {
    notificationConfig: null,
    loaded: false
  },
  mutations: {
    setNotificationConfig (state, data) {
      state.notificationConfig = data
      state.loaded = true
    }
  },
  actions: {
    load ({state, getters, commit, rootState, rootGetters}, params) {
      state.loaded = false
      //params = {notificationConfigId: 1, trackingId: trackingId}
      getNotificationInfo(params).then((response) => {
        var data = response.data
        commit('setNotificationConfig', data)
      })
    }
  },
  getters: {
    getByLang: (state) => (lang) => {
      return state.notificationConfig && state.notificationConfig.lanMap[lang]
    }
  }
}