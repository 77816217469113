<template>
  <div class="chart">
    <svg
      ref="svg"
      :width="size.width"
      :height="size.height"
    />
  </div>
</template>

<script>
import Size from '../../mixins/Size'
import d3_tip from 'd3-tip'
import { t } from '../../../i18n'
import { getTimeAggDate } from '../../utils/DatesUtils'
import { select as d3_select } from 'd3-selection'
import { scaleLinear as d3_scaleLinear, scaleTime as d3_scaleTime } from 'd3-scale'
import {
  axisBottom as d3_axisBottom,
  axisRight as d3_axisRight,
  axisLeft as d3_axisLeft,
} from 'd3-axis'
import { max as d3_max, extent as d3_extent } from 'd3-array'
import { line as d3_line, curveCardinal as d3_curveCardinal } from 'd3-shape'
// eslint-disable-next-line
import { transition } from 'd3-transition'
var dayjs = require('dayjs')
var weekOfYear = require('dayjs/plugin/weekOfYear')
dayjs.extend(weekOfYear)

export default {
  mixins: [Size],
  props: { data: Array, meta: Array, filters: Object },
  data() {
    return {
      tooltipData: null,
    }
  },
  computed: {
    granularity() {
      return getTimeAggDate(this.filters.time_period.periodType).timeAgg
    },
  },
  watch: {
    data() {
      this.draw()
    },
    size() {
      this.draw()
    },
  },
  methods: {
    draw() {
      let svg = d3_select(this.$refs.svg)
      let root = svg.selectAll('.root').data([null])
      let padding = { top: 10, right: 35, bottom: 20, left: 35 }
      let barWidth = 10

      let lineData = this.data[0]
      let barData = this.data[1]

      if (!barData) padding.right = 0

      let chartArea = {
        width: parseInt(this.size.width) - padding.left - padding.right,
        height: parseInt(this.size.height) - padding.top - padding.bottom,
      }

      let lineDataMaxValue = d3_max(lineData.dateList, d => d.value)
      if (lineDataMaxValue === 0) lineDataMaxValue = 1000
      let barDataMaxValue = !barData
        ? 0
        : d3_max(barData.dateList, d => d.value) === 0
        ? 1000
        : d3_max(barData.dateList, d => d.value)

      let rootEnter = root
        .enter()
        .append('g')
        .attr('class', 'root')
        .attr('transform', 'translate(' + padding.left + ',' + padding.top + ')')

      rootEnter.append('g').attr('class', 'xAxis')
      rootEnter.append('g').attr('class', 'yAxisL')
      rootEnter.append('g').attr('class', 'content')

      root = root.merge(rootEnter)

      let xScale = d3_scaleTime()
        .domain(d3_extent(lineData.dateList, d => d.date))
        .range([0, chartArea.width])
        .nice()

      let yScaleL = d3_scaleLinear()
        .domain([0, lineDataMaxValue * 1.25])
        .range([chartArea.height, 0])

      let yScaleR

      //d3_tip html creation
      let tip = d3_tip()
        .attr('class', 'd3-tip')
        .offset([-10, 0])
        .html(function() {
          const itemData = d3_select(this).datum()
          console.log('itemData', itemData)
          if (itemData.bar === null) {
            return `<div class="pop-tooltip">
                <span>${itemData.dateString}</span>
                <span>${itemData.lineString}: ${itemData.line}</span>

              </div>`
          } else {
            return `<div class="pop-tooltip">
                <span>${itemData.dateString}</span>
                <span>${itemData.lineString}: ${itemData.line}</span>
                <span>${itemData.barString}: ${itemData.bar}</span>

              </div>`
          }
          //see data -> ${JSON.stringify(itemData)}
        })
      svg.call(tip)

      //xAxis
      root
        .select('.xAxis')
        .attr('transform', 'translate(0,' + chartArea.height + ')')
        .call(d3_axisBottom(xScale).ticks(3))
        .selectAll('path')
        .attr('stroke', 'gray')
      root
        .select('.xAxis')
        .selectAll('line')
        .attr('stroke', 'gray')

      //yAxisL
      if (barData) {
        root
          .select('.yAxisL')
          .call(d3_axisLeft(yScaleL).ticks(8))
          .selectAll('path')
          .attr('stroke', 'transparent')

        root
          .select('.yAxisL')
          .selectAll('line')
          .attr('stroke', 'transparent')
      } else {
        root
          .select('.yAxisL')
          .call(
            d3_axisLeft(yScaleL)
              .ticks(8)
              .tickSize(-chartArea.width)
          )
          .selectAll('path')
          .attr('stroke', 'transparent')
        root
          .select('.yAxisL')
          .selectAll('line')
          .attr('stroke', 'lightgray')
          .attr('stroke-dasharray', '5 5')
      }

      //legendLines
      let rootLegendLines = root
        .select('.yAxisL')
        .select('.tick:last-child')
        .append('g')
        .attr('class', 'legend-lines')
      rootLegendLines
        .append('text')
        .attr('class', 'text-line-legend')
        .attr('x', '0')
        .attr('text-anchor', 'start')
        .attr('y', '0.32em')
        .attr('fill', 'black')
        .text(
          this.meta.find(element => element.indicatorId === lineData.id)
            ? t(this.meta.find(element => element.indicatorId === lineData.id).name)
            : '*Prio'
        )

      if (barData) {
        rootLegendLines
          .append('rect')
          .attr('class', 'line-legend')
          .attr('width', '10')
          .attr('height', '2')
          .attr('y', '0')
          .attr('x', this.$refs.svg.querySelector('.text-line-legend').getBBox().width + 10)

        rootEnter.append('g').attr('class', 'yAxisR')
        rootEnter
          .select('.content')
          .append('g')
          .attr('class', 'bars')

        yScaleR = d3_scaleLinear()
          .domain([0, barDataMaxValue * 1.25])
          .range([chartArea.height, 0])
        //yAxisR
        root
          .select('.yAxisR')
          .attr('transform', 'translate(' + chartArea.width + ', 0)')
          .call(d3_axisRight(yScaleR).ticks(8))
          .selectAll('path')
          .attr('stroke', 'transparent')
        root
          .select('.yAxisR')
          .selectAll('line')
          .attr('stroke', 'transparent')

        //legendBars
        let rootLegendBars = root
          .select('.yAxisR')
          .select('.tick:last-child')
          .append('g')
          .attr('class', 'legend-bars')
          .attr('text-anchor', 'end')
        rootLegendBars
          .append('text')
          .attr('class', 'text-bar-legend')
          .attr('x', '0')
          .attr('y', '0.32em')
          .attr('fill', 'black')
          .text(
            this.meta.find(element => element.indicatorId === barData.id)
              ? t(this.meta.find(element => element.indicatorId === barData.id).name)
              : '*All'
          )

        rootLegendBars
          .append('rect')
          .attr('class', 'bar-legend')
          .attr('width', '6')
          .attr('height', '6')
          .attr('y', '-3')
          .attr('x', -this.$refs.svg.querySelector('.text-bar-legend').getBBox().width - 14)
      }

      //Ticks - PrettyNumbers
      let ticksAxisL = root.select('.yAxisL').selectAll('.tick')
      if (ticksAxisL) {
        ticksAxisL._groups[0].forEach(el => {
          let textElement = el.getElementsByTagName('text')[0]
          textElement.innerHTML = this.$prettyNumber(textElement.__data__)
        })
      }
      if (barData) {
        let ticksAxisR = root.select('.yAxisR').selectAll('.tick')
        if (ticksAxisR) {
          ticksAxisR._groups[0].forEach(el => {
            let textElement = el.getElementsByTagName('text')[0]
            textElement.innerHTML = this.$prettyNumber(textElement.__data__)
          })
        }
      }

      //linechart logics
      rootEnter
        .select('.content')
        .append('g')
        .attr('class', 'lines')

      const lineGenerator = d3_line()
        .x(d => xScale(d.date))
        .y(d => yScaleL(d.value))
        .curve(d3_curveCardinal)

      let lineItems = root
        .select('.content')
        .select('.lines')
        .selectAll('.line-item')
        .data([lineData])

      let lineItemsEnter = lineItems
        .enter()
        .append('g')
        .attr('class', 'line-item')
        .append('path')
        .attr('fill', 'none')
        .attr('class', 'line-path')
        .attr('stroke-width', 2)
        .attr('d', d => lineGenerator(d.dateList))

      let lineItemsExit = lineItems.exit()
      lineItems = lineItems.merge(lineItemsEnter)
      lineItemsExit.remove()

      lineItems
        .transition()
        .select('path')
        .attr('d', d => lineGenerator(d.dateList))

      if (barData) {
        //barchart logics
        let barItems = root
          .select('.content')
          .select('.bars')
          .selectAll('.bar-item')
          .data(barData.dateList, (d, i) => i)

        let barItemsEnter = barItems
          .enter()
          .append('g')
          .attr('class', 'bar-item')
        barItemsEnter.append('rect')

        let barItemsExit = barItems.exit()
        barItems = barItems.merge(barItemsEnter)
        barItemsExit.remove()

        barItems
          .transition()
          .select('rect')
          .attr('class', 'bar-rect')
          .attr('x', d => xScale(d.date) - barWidth / 2)
          .attr('y', d => yScaleR(d.value))
          .attr('width', barWidth)
          .attr('height', d => chartArea.height - yScaleR(d.value))
      }
      //SENSOR LOGICS D3_TIP
      rootEnter
        .select('.content')
        .append('g')
        .attr('class', 'sensor')
      let formatDate =
        this.granularity === 'MONTH'
          ? 'MMMM YYYY'
          : this.granularity === 'WEEK'
          ? 'YYYY'
          : 'DD/MM/YYYY'
      console.log(formatDate)
      let sensorData = lineData.dateList.map((d, i) => {
        return {
          date: d.date,
          dateString:
            this.granularity !== 'WEEK'
              ? dayjs(d.date)
                  .format(formatDate)
                  .charAt(0)
                  .toUpperCase() +
                dayjs(d.date)
                  .format(formatDate)
                  .slice(1)
              : t('time_week') +
                ' ' +
                dayjs(d.date).week() +
                ' ' +
                dayjs(d.date).format(formatDate),
          line: this.$prettyPrint(
            d.value,
            t(
              (this.meta.find(element => element.indicatorId === lineData.id) &&
                this.meta.find(element => element.indicatorId === lineData.id).unitType) ||
                ''
            ) //super chapuzilla
          ),
          lineString: this.meta.find(element => element.indicatorId === lineData.id)
            ? t(this.meta.find(element => element.indicatorId === lineData.id).name)
            : '*Prio',
          bar: barData
            ? this.$prettyPrint(
                barData.dateList.find((el, j) => j === i).value,
                t(
                  (this.meta.find(element => element.indicatorId === barData.id) &&
                    this.meta.find(element => element.indicatorId === barData.id).unitType) ||
                    ''
                ) //super chapuzilla
              )
            : null,
          barString: barData
            ? this.meta.find(element => element.indicatorId === barData.id)
              ? t(this.meta.find(element => element.indicatorId === barData.id).name)
              : '*All'
            : null,
        }
      })
      let barSensorItems = root
        .select('.content')
        .select('.sensor')
        .selectAll('.bar-sensor')
        .data(sensorData, (d, i) => i)

      let barSensorItemsEnter = barSensorItems
        .enter()
        .append('g')
        .attr('class', 'bar-sensor')
        .on('mouseover', mouseover)
        .on('mousemove', mouseover)
        .on('mouseout', mouseout)

      barSensorItemsEnter
        .append('line')
        .attr('class', 'line-focus')
        .attr('x1', d => xScale(d.date) - (barWidth * 3) / 2)
        .attr('x2', d => xScale(d.date) - (barWidth * 3) / 2)
        .attr('y1', 0)
        .attr('y2', chartArea.height)
        .style('stroke-width', 1)
        .attr('stroke', '#000')
        .attr('opacity', 0)
        .attr('transform', 'translate(' + (barWidth * 3) / 2 + ', 0)')

      barSensorItemsEnter
        .append('rect')
        .on('mouseover', tip.show)
        .on('mousemove', tip.show)
        .on('mouseout', tip.hide)

      let barSensorItemsExit = barSensorItems.exit()
      barSensorItems = barSensorItems.merge(barSensorItemsEnter)
      barSensorItemsExit.remove()

      barSensorItems
        .transition()
        .select('rect')
        .attr('x', d => xScale(d.date) - (barWidth * 3) / 2)
        .attr('y', 0)
        .attr('width', barWidth * 3)
        .attr('height', chartArea.height)

      barSensorItems
        .transition()
        .select('line')
        .attr('x1', d => xScale(d.date) - (barWidth * 3) / 2)
        .attr('x2', d => xScale(d.date) - (barWidth * 3) / 2)
        .attr('y1', 0)
        .attr('y2', chartArea.height)
      //mouse event functions
      function mouseover(e) {
        e.target.parentElement.firstElementChild.style.opacity = 1
      }
      function mouseout(e) {
        e.target.parentElement.firstElementChild.style.opacity = 0
      }
    },
  },
}
</script>

<style lang="stylus">
.chart
  position: relative
  width: 100%
  height: 100%

.line-path
  stroke: $cyprus

.bar-rect
  fill: $paarl

.bar-sensor
  rect
    fill: transparent

.legend
  text
    font-size: 10px
    fill: $gray

.line-legend
  fill: $cyprus

.bar-legend
  fill: $paarl

.xAxis,
.yAxisR,
.yAxisL
  color: $gray

.pop-tooltip
  display: flex
  flex-direction: column
  align-items: center
  padding: 6px
  m-font-size(10, 14)

.d3-tip
  z-index: 10
  border-radius: 2px
  background: rgba(0, 0, 0, 0.8)
  color: #fff
  font-weight: bold
  line-height: 1

/* Creates a small triangle extender for the tooltip */
.d3-tip:after
  position: absolute
  bottom: -9px
  left: 0
  display: inline
  box-sizing: border-box
  width: 100%
  color: rgba(0, 0, 0, 0.8)
  content: "\25BC"
  text-align: center
  font-size: 10px
  line-height: 1

/* Style northward tooltips differently */
.d3-tip.n:after
  top: 100%
  left: 0
  margin: -1px 0 0
</style>