<template>
  <div class="promote-tracking-status">
    <div class="text" v-html="$t(data.body, {trackingId: data.trackingId})"></div>
  </div>
</template>

<script>
import modalContentMixin from '@/components/modals/modalContentMixin'
export default {
  name: 'PromoteTrackingStatus',
  props: ['data'],
  mixins: [modalContentMixin],
  computed: {
    contentValue() {
      return true
    },
  },
}
</script>

<style scoped lang="stylus">
.text
  margin-bottom: 20px
</style>