export default {
  html_title: 'Herramienta de seguimiento CUPRA Priority',
  welcome_title: 'Bienvenido a la herramienta de seguimiento de CUPRA',
  welcome_body: '-',
  continue_btn: 'Continuar',
  welcome_body_first_time:
    'Este es tu primer inicio de sesión y necesitamos información adicional. Esta información solo se solicitará una vez. <br><br>Por favor, rellena los campos siguientes:',
  country_code: 'Código ISO del país',
  dealer_code: 'Código del concesionario',
  hide_summary_btn: 'Ocultar mis tareas',
  show_summary_btn: 'Mostrar mis tareas',
  hide_funnel_btn: 'Ocultar embudo',
  show_funnel_btn: 'Mostrar embudo',
  my_tasks_title: 'Mis tareas',
  funnel_title: 'Embudo',
  pending_welcome_emails: 'Correos electrónicos de bienvenida pendientes',
  pendingWelcome: 'Correos electrónicos de bienvenida pendientes',
  cm_pending_welcome_info_on_click: 'Enviar la información de acceso al cliente',
  sm_pending_welcome_info_on_click: 'Enviar la información de acceso al cliente',
  unengaged_clients: 'Clientes no fidelizados',
  unengagedClients: 'Clientes no fidelizados',
  cm_unengaged_clients_info_on_click:
    'Estos clientes nunca han accedido a su herramienta de seguimiento',
  sm_unengaged_clients_info_on_click:
    'Estos clientes nunca han accedido a su herramienta de seguimiento',
  mk_unengaged_clients_info_on_click:
    'Estos clientes nunca han accedido a su herramienta de seguimiento',
  pushable_notifications: 'Notificaciones de llegada pendientes',
  pushableNotifications: 'Notificaciones de llegada pendientes',
  cm_pushable_notifications_info_on_click:
    'Informar al cliente que el vehículo está en el concesionario',
  sm_pushable_notifications_info_on_click:
    'Informar al cliente que el vehículo está en el concesionario',
  unread_notifications: 'Notificaciones no leídas',
  unreadNotifications: 'Notificaciones no leídas',
  cm_unread_notifications_info_on_click: 'Todavía no has leído estos mensajes',
  sm_unread_notifications_info_on_click: 'Tus CUPRA Master todavía no han leído estos mensajes',
  mk_unread_notifications_info_on_click:
    'Estas notificaciones no han sido leídas por el CUPRA Master correspondiente',
  possible_delayed_orders: 'Posibles pedidos retrasados',
  possibleDelayedOrders: 'Posibles pedidos retrasados',
  cm_possible_delayed_orders_info_on_click:
    'Estos pedidos activos se generaron hace más de 15 días.',
  sm_possible_delayed_orders_info_on_click:
    'Estos pedidos activos se generaron hace más de 15 días.',
  possible_delayed_market_orders: 'Posibles pedidos retrasados',
  possibleDelayedMarketOrders: 'Posibles pedidos retrasados',
  mk_possible_delayed_market_orders_info_on_click:
    'Estos pedidos activos se generaron hace más de 15 días y no están en el concesionario.',
  delayed_cars: 'Coches retrasados',
  delayedCars: 'Coches retrasados',
  mk_delayed_cars_info_on_click:
    'Estos pedidos activos se generaron hace más de 21 días y aún no se han entregado.',
  total_active_orders: 'Pedidos activos',
  totalActiveOrders: 'Pedidos activos',
  mk_total_active_orders_info_on_click: 'Pedidos generados no entregados',
  welcome_email_sent: 'Correo electrónico de bienvenida enviado',
  welcomeEmailSent: 'Correo electrónico de bienvenida enviado',
  mk_welcome_email_sent:
    'Proporción de pedidos activos con correo electrónico de bienvenida enviado respecto al total',
  pending_deliveries: 'Entregas pendientes',
  pendingDeliveries: 'Entregas pendientes',
  mk_pending_deliveries_info_on_click: 'Pedidos en el concesionario a la espera de AaK',
  cm_active: 'CUPRA Masters activos en esta herramienta',
  cmActives: 'CUPRA Masters activos en esta herramienta',
  mk_cm_active: 'Un CUPRA Master está activo si ha iniciado sesión durante la última semana.',
  legend_title: 'Información sobre tus posibles acciones',
  send_notification_legend: 'Enviar notificación de "el coche está en el concesionario" al cliente',
  send_welcome_legend: 'Enviar correo electrónico de bienvenida al cliente',
  send_reminder_legend:
    'Enviar un recordatorio del mensaje de correo electrónico de bienvenida al cliente',
  inform_car_arrival_legend: 'Informar al sistema sobre un pedido que ha llegado al concesionario',
  unread_notifications_legend:
    'Acceso directo a los mensajes del importador que todavía no has leído',
  reregister_legend: 'Asignar un pedido cancelado a un nuevo cliente. Volver a registrarlo.',
  send_welcome_email_tooltip: 'Enviar correo electrónico de bienvenida',
  send_reminder_tooltip: 'Enviar recordatorio',
  send_push_notification_tooltip: 'Enviar notificación urgente',
  inform_car_arrival_tooltip: 'Informar de la llegada del vehículo al concesionario',
  reregister_this_order_tooltip: 'Volver a registrar este pedido',
  log_out: 'Salir',
  register_order: 'Registrar un nuevo pedido',
  download_excel: 'Descargar Excel',
  send_welcome_btn: 'Enviar correo electrónico de bienvenida',
  send_reminder_btn: 'Enviar correo electrónico de recordatorio',
  send_notification_btn: 'Enviar notificación',
  inform_car_arrival_btn: 'Informarnos',
  show_results_btn: 'Mostrar resultados',
  cancel_btn: 'Cancelar',
  confirm_btn: 'Confirmar',
  submit_registration_btn: 'Enviar registro',
  print_btn: 'Imprimir PDF',
  clear_btn: 'BORRAR',
  search_text: 'ID de seguimiento, VIN o email',
  trackline_tab_title: 'Línea de seguimiento',
  PRE_A500_short: 'Pedido recibido',
  PRODUCTION_STARTS_short: 'Inicio de producción',
  PRODUCTION_ENDS_short: 'Fin de producción',
  FACTORY_DEPART_short: 'Sale de fábrica',
  COMPOUND_ARRIVAL_short: 'Llega a campa',
  COMPOUND_DEPART_short: 'Sale de campa',
  DEALER_ARRIVAL_short: 'Llega al concesionario',
  AAK_short: 'AaK',
  PRE_A500: 'Pedido recibido',
  PRODUCTION_STARTS: 'Inicio de producción',
  PRODUCTION_ENDS: 'Fin de producción',
  FACTORY_DEPART: 'El coche sale de fábrica',
  COMPOUND_ARRIVAL: 'El coche llega a campa',
  COMPOUND_DEPART: 'El coche sale de campa',
  DEALER_ARRIVAL: 'El coche llega al concesionario',
  AAK: 'AaK',
  filter_tab_title: 'Filtros',
  pending_actions: 'Acciones pendientes',
  order_date_range: 'Intervalo de fechas de pedido',
  delivery_date_range: 'Intervalo de fechas de entrega',
  active_orders: 'Pedidos activos',
  order_history: 'Historial de pedidos',
  tracking_id: 'ID de seguimiento',
  status: 'Estado',
  model: 'Modelo',
  last_status_change: 'Último cambio de estado',
  order_date: 'Fecha del pedido',
  cm_code: 'Código de CUPRA Master',
  dealer_code_short: 'Concesionario',
  delivery_date: 'Fecha de entrega',
  delivered_on_time: 'Entregado a tiempo',
  yes: 'Sí',
  no: 'No',
  days_until_delivery: 'Días hasta la entrega',
  back: 'atrás',
  commission_nr: 'Número de comisión',
  tmaimg: 'TMAIMG',
  engine: 'Motor',
  trim: 'Acabado',
  color: 'Color',
  prs: 'Números PR',
  client_tracking_view: 'Vista de seguimiento de clientes',
  orderReceived: 'Pedido recibido',
  production: 'Proceso de producción',
  dealer: 'Llegada al concesionario',
  delivered: 'Encuesta',
  pending: 'Pendiente',
  sent_notifications_list: 'Notificaciones enviadas',
  market_notifications_list: 'Notificaciones de mercado',
  completed_orders: 'Pedidos completados',
  register_title: 'Registrar un pedido',
  register_explanation:
    'Después de registrar la compra, este pedido estará asignado a ti y a tu concesionario. Podrás enviar el correo electrónico de bienvenida correspondiente al cliente.',
  welcome_mail_title: 'Enviar correo electrónico de bienvenida',
  client_mail_field: 'Correo electrónico del cliente',
  client_mail_placeholder: 'ejemplo@ejemplo.ex',
  reminder_title: 'Enviar correo electrónico de recordatorio de bienvenida',
  send_arrival_notification_title: 'Enviar notificación de "el coche está en el concesionario"',
  inform_car_arrival_title: '¡Este coche ha llegado al concesionario!',
  inform_car_arrival_explanation:
    'Dado que es posible que seas el primero en conocer esta llegada, por favor infórmanos sobre ella.<br> ¿Deseas confirmar que el vehículo con ID de seguimiento %trackingId% ha llegado a tu concesionario?',
  welcome_mail_confirmation_title: 'Correo electrónico de bienvenida enviado al cliente',
  welcome_mail_confirmation_explanation:
    'Si tu cliente autoriza el envío de correos electrónicos, recibirá la notificación por correo electrónico. Para ver si el cliente ya ha accedido a su herramienta de seguimiento, consulta los detalles del pedido.',
  order_status: 'Estado de la orden',
  ok_btn: 'OK',
  subject: 'Asunto del correo',
  body: 'Contenido del corrreo',
  purchaseDate: 'Fecha del pedido',
  CMCode: 'Código de CUPRA Master',
  dealerCode: 'Concesionario',
  deliveryDate: 'Fecha de entrega',
  deliveredOnTime: 'Entregado a tiempo',
  filters: 'Filtros',
  models: 'Modelo',
  trackingId: 'ID de seguimiento',
  pr: 'Números PR',
  delayed: 'Coches retrasados',
  trackingDays: 'Días hasta la entrega',
  lastStatusChange: 'Último cambio de estado',
  trackingStatus: 'Estado',
  done: 'Hecho',
  date: 'Fecha',
  PR: 'Números PR',
  pendingActions: 'Tareas',
  welcome_footer: '-',
  successful_generic_title: '¡Hecho!',
  successful_generic_text: '-',
  wrong_generic_title: 'Ups, algo ha ido mal',
  wrong_generic_text: 'Inténtalo de nuevo ',
  activeOrders: 'Pedidos activos',
  delayedOrders: 'Coches retrasados',
  activeCM: 'CUPRA Masters activos',
  clear: 'Borrar',
  de_AT: 'Deutsch (AT)',
  de_CH: 'Deutsch (CH)',
  de_DE: 'Deutsch (DE)',
  en_GB: 'English (GB)',
  es_ES: 'Español (ES)',
  fr_CH: 'Français (CH)',
  fr_FR: 'Français (FR)',
  it_CH: 'Italiano (CH)',
  it_IT: 'Italiano (IT)',
  nl_NL: 'Nederlands (NL)',
  close_btn: 'Cerrar',
  reregistration_available: 'Pre-registro disponible',
  pending_reminders: 'Emails recordatorio pendientes',
  CM_to_customer_sent_notifications_list:
    'Notifcaciones enviadas al cliente por el Especialista CUPRA',
  your_notifications_to_CM: 'Tu notificación al Especialista CUPRA',
  read_notification_title: 'Lee esta notificación',
  save_btn: 'Guardar',
  print_preview_title: 'Imprimir vista preliminar',
  preview_title: 'Preliminar',
  turn_your_device_to_landscape_mode: 'Vuelca tu dispositivo',
  dealer_code_input_explanation: 'Debes introducir tu código KPVS',
  tracking_id_input_explanation: '',
  download_btn: 'Descargar',
  pending_reminder: 'Emails recordatorio pendientes',
  cm_pending_reminder_info_on_click:
    'Reenviar de nuevo email de bienvenida a un cliente no fidelizado',
  sm_pending_reminder_info_on_click:
    'Reenviar de nuevo email de bienvenida a un cliente no fidelizado',
  authenticate_as_sales_manager: 'Identificado como Jefe de Ventas',
  authenticate_as_sales_manager_title: 'Identificado como Jefe de Ventas',
  authenticate_as_sales_manager_explanation: '',
  assign_cupra_master_legend: 'Asignar un Especislita CUPRA a este pedido',
  assign_cupra_master_title: 'Asignar un Especislita CUPRA',
  assign_cupra_master_explanation: 'Asignar un Especislita CUPRA a este pedido',
  assign_btn: 'Asignar',
  authenticate_btn: 'Identifícate',
  send_massive_notification_btn: 'Enviar notificación',
  send_massive_notification_title: 'Enviar notificación',
  send_massive_notification_explanation: 'A los pedidos seleccionados',
  login_btn: 'Acceso',
  redirected_title: '¿Te has identificado en GRP?',
  redirected_body:
    'Porfavor, haz click para continuar o revisa si tu navegador está bloqueando la ventana emergente de GRP',
  preview_btn: 'Vista preliminar',
  reregister_explanation: 'Asignar peiddo cancelado a un nuevo cliente. Regístralo de nuevo.',
  reregister_title: 'Registrar de nuevo',
  welcomeMessageSent: 'Emails de Bienvenida enviados',
  pendingToDeliver: 'Pedidos pendientes de entregar',
  send_mk_notification_tooltip: 'Enviar notificación',
  download_excel_title: 'Descargar lista',
  download_excel_explanation: 'La selección de la lista está basada en tus filtros',
  selection_massive_this_page_part_1:
    'Todos los <strong>%PAGE_LENGTH%</strong> pedidos de la página están seleccionados',
  selection_massive_this_page_part_2: 'Selecciona todos los %ORDERS_TOTAL% pedidos',
  selection_massive_all_pages_part_1: 'Todos los %ORDERS_TOTAL% pedidos están seleccionados',
  selection_massive_all_pages_part_2: 'Eliminar selección',
  send_notification_title: 'Enviar notificación',
  choose_cupra_master: 'Elegir un CUPRA Master de tu concesionario',
  pendingReminders: 'Emails recordatorio pendientes',
  send_mk_notification_legend: 'Enviar notificaciones al CUPRA Master del pedido',
  mk_unread_notifications_legend:
    'Estas notificaciones no han sido leídas por el CUPRA Master correspondiente',
  turn_your_device_to_portrait_mode: 'Cambia tu dispositivo al modo retrato',
  more_info: 'Más información',
  apply_filters_mobile_btn: 'Aplicar filtros',
  cmName: 'CUPRA Master',
  logout: 'Salir',
  sort_by: 'Ordenar por',
  order_details: 'Detalles del pedido',
  dealer_or_importer_code: 'Código de concesionario o de importador',
  not_found: 'No encontrado',
  searching: 'Buscando...',
  pendingManualReminder: 'Emails recordatorio pendientes',
  unassigned_seller: 'CUPRA Master sin asignar',
  unassignedSeller: 'CUPRA Master sin asignar',
  countryCode: 'Código ISO del país',
  vin: 'VIN',
  hq_my_tasks_title: '-',
  show_hq_summary_btn: '-',
  hide_hq_summary_btn: '-',
  hq_possible_delayed_market_orders_info_on_click: '-',
  hq_delayed_cars_info_on_click: '-',
  hq_unread_notifications_info_on_click: '-',
  hq_pending_actions: '-',
  country: '-',
  email: 'Email',
  search_long_explanation: 'Buscar por ID de seguimiento, VIN o email',
  mk_notifications_to_CM: '-',
  possibleDelayNotDelayed: '',
  tracking_tab: '',
  search: '',
  time_period: '',
  time_week: '',
  time_ytd: '',
  time_month: '',
  time_from: '',
  time_until: '',
}
