<template>
  <div class="tracking-wrapper">
    <Header />
    <router-view :key="$route.name" class="subroute" />
  </div>
</template>

<script>
import Header from '../components/Header.vue'

export default {
  name: 'TrackingWrapper',
  components: {
    Header,
  },
}
</script>

<style scoped lang="stylus">
.tracking-wrapper
  display: flex
  flex-direction: column
</style>